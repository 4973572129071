/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

// == Import : npm
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import fr from 'date-fns/locale/fr'

// == Import : local
import '../../style/style.scss'
import Header from '../../containers/Header'
import { boEntries } from '../../utils/headerEntries.js'
import ImageSelector from '../../containers/ImageSelector'

registerLocale('fr', fr)

// == Composant
const ChallengesAjout = ({ location, match, settings, waitingRequest, lastUploadedImage, post, connected, getOne, getAll, rewards }) => {
  const [visualUrl, setVisualUrl] = useState('')

  const [title, setTitle] = useState(null)
  const [code, setCode] = useState('')
  const [date, setDate] = useState(null)
  const [dateFin, setDateFin] = useState(null)
  const [dateInscription, setDateInscription] = useState(null)
  // const [dateShowStart, setDateShowStart] = useState(null)
	const dateShowStart = null;
  // const [dateShowEnd, setDateShowEnd] = useState(null)
	const dateShowEnd = null;
	const [description, setDescription] = useState(null)
  const [placeMax, setPlaceMax] = useState(null)
  const [parcours, setParcours] = useState([''])
  const [isShow, setIsShow] = useState(true)
  const [partenaires, setPartenaires] = useState([
    { name: null, url: null, image: null },
    { name: null, url: null, image: null },
    { name: null, url: null, image: null }
  ])
  const [dotations, setDotations] = useState([{ title: null, min: '', max: '' }])

  const [tarifsParcours, setTarifsParcours] = useState('')
  const [tarifsAgain, setTarifsAgain] = useState('')
  const [tarifsDuel, setTarifsDuel] = useState('')
  const [tarifsGT, setTarifsGT] = useState('')
  const [tourismCost, setTourismCost] = useState(0)

  // pickImage is used in ImageSelector
  // eslint-disable-next-line no-unused-vars
  const [pickImage, setPickImage] = useState(false)

  // state translate languege
  const [isLanguageFr, setIsLanguageFr] = useState(true)
  const [titleEn, setTitleEn] = useState('')
  const [descriptionEn, setDescriptionEn] = useState('')
  const [visualUrlEn, setVisualUrlEn] = useState('')

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/challenge/nextautoincrementid`, { withCredentials: true })
      .then(response => {
        // Code pour ajouter les 000 devant
        // if (response.data.id > 1000)
        //   setCode(`CH-${response.data.id+1}`)
        // if (response.data.id > 100)
        //   setCode(`CH-0${response.data.id+1}`)
        // if (response.data.id > 10)
        //   setCode(`CH-00${response.data.id+1}`)
        // if (response.data.id > 0)
        //   setCode(`CH-000${response.data.id+1}`)
        setCode(`CH-${response.data[0]?.AUTO_INCREMENT}`)
      })
  }, [])

  useEffect(() => {
    // getAll rewards
    getAll(1, 'pagination=false')
    // Get global settings
    getOne(1)
		// eslint-disable-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (settings) {
      setTarifsParcours(settings.challengeDefaultCost)
      setTarifsAgain(settings.tryAgainDefaultCost)
      setTarifsDuel(settings.duelDefaultCost)
      setTarifsGT(settings.gtDefaultCost)
    }
  }, [settings])

  const defineDotations = (iri, min, max, index) => {
    const temp = dotations.slice()
    temp[index] = { iri, min, max }
    setDotations(temp)
  }

  const reverseDotations = (current, previous, index) => {
    const temp = dotations.slice()
    temp[index] = { iri: previous.iri, min: current.min, max: current.max }
    temp[index - 1] = { iri: current.iri, min: previous.min, max: previous.max }
    setDotations(temp)
  }
  const reverseDotationsDown = (current, next, index) => {
    const temp = dotations.slice()
    temp[index] = { iri: next.iri, min: current.min, max: current.max }
    temp[index + 1] = { iri: current.iri, min: next.min, max: next.max }
    setDotations(temp)
  }

  const updatePartner = (event, index, type) => {
    const temp = partenaires.slice()
    switch (type) {
      case 'name':
        temp[index].name = event.target.value; setPartenaires(temp)
        break
      case 'url':
        temp[index].url = event.target.value; setPartenaires(temp)
        break
      case 'image':
        temp[index].image = event; setPartenaires(temp)
        break
      default:
        break
    }
  }

  return (
		<>
			<Header currentMenuItem={3} entries={boEntries} location={location} match={match} />
			<div className='page-wrapper' style={{ paddingLeft: '20%', paddingRight: '20%' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', margin: '100px 0% 0 0' }}>
					<h1 className='page-title'>Challenges</h1>
				</div>
				<div className="form-wrapper">
					<div style={{ display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end' }}>
						<input type='button' className='button button-shadow' value='Francais' style={{ backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white' }} onClick={() => setIsLanguageFr(true)}/>
						<input type='button' className='button button-shadow' value='Anglais' style={{ backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white' }} onClick={() => setIsLanguageFr(false)}/>
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						{isLanguageFr && <input value={title} onChange={e => setTitle(e.target.value)} type='text' className="input-text" placeholder='Titre du challenge(FR) *' style={{ width: '45%' }}/>}
						{!isLanguageFr && <input value={titleEn} onChange={e => setTitleEn(e.target.value)} type='text' className="input-text" placeholder='Title for challenge(EN) *' style={{ width: '45%' }}/>}
						<input
							disabled
							value={code}
							// onChange={e => setCode(e.target.value)}
							type='text'
							className="input-text"
							placeholder='Code du challenge *'
							style={{ width: '45%', color: 'darkGray' }}
						/>
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<span style={{ marginRight: 15, fontSize: 15 }}>Période du * : </span>
						<DatePicker
							className='button date-picker'
							selected={date}
							onChange={(e) => setDate(e)}
							locale="fr"
							showTimeSelect
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
						<span style={{ marginRight: 15, marginLeft: 15, fontSize: 15, width: 'fit-content' }}> au * : </span>
						<DatePicker
							className='button date-picker'
							selected={dateFin}
							onChange={(e) => setDateFin(e)}
							locale="fr"
							showTimeSelect
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<span style={{ marginRight: 10, fontSize: 15 }}> Date limite d'inscription * </span>
						<DatePicker
							className='button date-picker'
							selected={dateInscription}
							onChange={(e) => setDateInscription(e)}
							locale="fr"
							showTimeSelect
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<span style={{ fontSize: 12, color: 'gray' }}>Date de début d'affichage (ne fonctionne pas dans l'app)</span>
						<DatePicker
							className='button date-picker bg-gray'
							selected={dateShowStart}
							// onChange={(e) => setDateShowStart(e)}
							onChange={() => alert("Ne fonctionne pas dans l'app, le seul contrôle étant actuellement sur la date de fin du challenge.")}
							locale="fr"
							showTimeSelect
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
						<span style={{ marginLeft: 15, fontSize: 12, color: 'gray' }}>Date de fin d'affichage (ne fonctionne pas dans l'app)</span>
						<DatePicker
							className='button date-picker bg-gray'
							selected={dateShowEnd}
							// onChange={(e) => setDateShowEnd(e)}
							onChange={() => alert("Ne fonctionne pas dans l'app, le seul contrôle étant actuellement sur la date de fin du challenge.")}
							locale="fr"
							showTimeSelect
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
					</div>
					<div>
						{isLanguageFr && (
							<>
								<label style={{ marginBottom: 10 }}>Description(FR) *</label>
								<CKEditor
									editor={ ClassicEditor }
									data={description}
									config={{ removePlugins: ['ImageUpload', 'MediaEmbed', 'Table'] }}
									onInit={ editor => {
									  // You can store the "editor" and use when it is needed.
									  console.log('Editor is ready to use!', editor)
									} }
									onChange={ (event, editor) => {
									  const data = editor.getData()
									  setDescription(data)
									  console.log('event - editor - data')
									  console.log({ event, editor, data })
									} }
									onBlur={ (event, editor) => {
									  console.log('Blur.', editor)
									} }
									onFocus={ (event, editor) => {
									  console.log('Focus.', editor)
									} }
								/>
							</>
						)}
						{!isLanguageFr && (
							<>
								<label style={{ marginBottom: 10 }}>Description(EN) *</label>
								<CKEditor
									editor={ ClassicEditor }
									data={descriptionEn}
									config={{ removePlugins: ['ImageUpload', 'MediaEmbed', 'Table'] }}
									onInit={ editor => {
									  // You can store the "editor" and use when it is needed.
									  console.log('Editor is ready to use!', editor)
									} }
									onChange={ (event, editor) => {
									  const data = editor.getData()
									  setDescriptionEn(data)
									  console.log({ event, editor, data })
									} }
									onBlur={ (event, editor) => {
									  console.log('Blur.', editor)
									} }
									onFocus={ (event, editor) => {
									  console.log('Focus.', editor)
									} }
								/>
							</>
						)}
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
						<label className='half-row'>Places maximum
							<input onChange={(e) => { setPlaceMax(e.target.value) }} value={placeMax} type='number' min="50" step='50' className="input-text" placeholder='Places max' />
						</label>
						<label className='half-row'>Codes de Parcours
							<button className='button' style={{ marginLeft: 20, marginBottom: 5 }} onClick={() => {
							  axios({
							    method: 'GET',
							    url: `${process.env.REACT_APP_API_URL}/api/courses?pagination=false`
							  }).then(response => {
							    setParcours(response.data['hydra:member'].map(element => {
							      return (element['@id'].split('/')[3])
							    }))
							  })
							}}>Tous</button>
							{/* {parcours.map((element, index) => (
                    <input value={element} onChange={e => {let temp = parcours.slice(); temp[index] = e.target.value; setParcours(temp)}} type='text' className="input-text" placeholder='Parcours' style={{marginBottom:parcours.length>1 ? 10 : 0}}></input>
                  ))} */}
							<textarea className="input-text" style={{ flex: 1 }} value={parcours.join(',')} onChange={e => setParcours(e.target.value.replace(' ', '').split(','))} />
						</label>
						{/* <FontAwesomeIcon  onClick={() => {setParcours([...parcours, null])}} size='2x' icon={faPlusCircle}  className='fa-button plus' style={{marginTop: 30}}/> */}
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
						{isLanguageFr && (
							<>
								<p>Visuel Challenge(Fr) * : </p>
								<ImageSelector imageUrl={visualUrl} setImageUrl={setVisualUrl} deactivateWaitingRequest={setPickImage} />
							</>
						)}
						{!isLanguageFr && (
							<>
								<p>Visuel Challenge(En) * : </p>
								<ImageSelector imageUrl={visualUrlEn} setImageUrl={setVisualUrlEn} deactivateWaitingRequest={setPickImage} />
							</>
						)}
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
						<input value={partenaires[0].name} onChange={e => updatePartner(e, 0, 'name')} type='text' className="input-text" placeholder='Partenaire' style={{ width: '30%' }}/>
						<input value={partenaires[0].url} onChange={e => updatePartner(e, 0, 'url')} type='text' className="input-text" placeholder='Lien web' style={{ width: '30%' }}/>
						<div className='partner-image-container'>
							<p>Visuel partenaire : </p>
							<ImageSelector imageUrl={partenaires[0].image} setImageUrl={ (e) => { updatePartner(e, 0, 'image') }} deactivateWaitingRequest={setPickImage} />
						</div>
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
						<input value={partenaires[1].name} onChange={e => updatePartner(e, 1, 'name')} type='text' className="input-text" placeholder='Partenaire' style={{ width: '30%' }}/>
						<input value={partenaires[1].url} onChange={e => updatePartner(e, 1, 'url')} type='text' className="input-text" placeholder='Lien web' style={{ width: '30%' }}/>
						<div className='partner-image-container'>
							<p>Visuel partenaire : </p>
							<ImageSelector imageUrl={partenaires[1].image} setImageUrl={ (e) => { updatePartner(e, 1, 'image') }} deactivateWaitingRequest={setPickImage} />
						</div>
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
						<input value={partenaires[2].name} onChange={e => updatePartner(e, 2, 'name')} type='text' className="input-text" placeholder='Partenaire' style={{ width: '30%' }}/>
						<input value={partenaires[2].url} onChange={e => updatePartner(e, 2, 'url')} type='text' className="input-text" placeholder='Lien web' style={{ width: '30%' }}/>
						<div className='partner-image-container'>
							<p>Visuel partenaire : </p>
							<ImageSelector imageUrl={partenaires[2].image} setImageUrl={ (e) => { updatePartner(e, 2, 'image') }} deactivateWaitingRequest={setPickImage} />
						</div>
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<label className='third-row'>Dotation 1er :
							<select type='text' className="input-text" value={dotations[0]?.iri || ''} onChange={(e) => { defineDotations(e.target.value, 1, 1, 0) }}>
								<option value=''>Dotation 1er</option>
								{rewards && rewards['hydra:member'].map(element => {
								  return (
										<option value={element['@id']}>{element.title}</option>
								  )
								})}
							</select>
						</label>

					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'column' }}>
						{dotations.map((element, index) => {
						  console.log('dotations.map', element, index)
						  if (index >= 1) {
						    return (
									<div id="ici" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
										<FontAwesomeIcon onClick={() => {
										  if (index > 1) {
										    const previous = dotations[index - 1]
										    const current = dotations[index]
										    console.log(previous, current)
										    reverseDotations(current, previous, index)
										  }
										}} size='2x' icon={faArrowUp} className='fa-button plus'/>
										<FontAwesomeIcon
											onClick={() => {
											  if (index < dotations.length - 1) {
											    const next = dotations[index + 1]
											    const current = dotations[index]
											    reverseDotationsDown(current, next, index)
											  }
											}}
											size='2x'
											icon={faArrowDown}
											className='fa-button plus'
										/>
										<label className='text-center third-row'>{index + 1}e Dotation </label>
										<select className="input-text mr-1" style={{ width: '30%' }} value={dotations[index].iri || ''} onChange={(e) => { defineDotations(e.target.value, element.min, element.max, index) }}>
											<option value=''>Dotation</option>
											{rewards && rewards['hydra:member'].map(element => {
											  return (
													<option value={element['@id']}>{element.title}</option>
											  )
											})}
										</select>
										<input onChange={(e) => {
										  console.log(!isNaN(e.target.value))
										  if (!isNaN(e.target.value)) {
										    const temp = dotations.slice()
										    temp.splice(index, 1, { iri: temp[index].iri, min: parseInt(e.target.value), max: temp[index].max < e.target.value ? e.target.value : temp[index].max })
										    setDotations(temp)
										  }
										}} value={element.min} type='number' className="input-text mr-1" placeholder='Place min' style={{ width: '30%' }}/>
										<input onChange={(e) => {
										  if (!isNaN(e.target.value)) {
										    const temp = dotations.slice()
										    temp.splice(index, 1, { iri: temp[index].iri, min: temp[index].min, max: parseInt(e.target.value) })
										    setDotations(temp)
										  }
										}} value={element.max} type='number' className="input-text mr-1" placeholder='Place max' style={{ width: '30%' }}/>
										<FontAwesomeIcon onClick={() => { setDotations(dotations.filter((e, i) => i !== index)) }} size='2x' icon={faMinusCircle} className='fa-button plus'/>
									</div>
						    )
						  }
						  return null
						})}
						<FontAwesomeIcon onClick={() => { setDotations([...dotations, { iri: null, min: null, max: null }]) }} size='2x' icon={faPlusCircle} className='fa-button plus'/>
					</div>

					<div className='fieldset-wrapper' style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<label className='quarter-row setting-number'>Challenge normal *
							<input onChange={e => { if (!isNaN(e.target.value)) { setTarifsParcours(e.target.value) } }} value={tarifsParcours} type='number' step='100' className="input-text" placeholder='Tarif Parcours'/>
						</label>
						<label className='quarter-row setting-number'>Coût Try again *
							<input onChange={e => { if (!isNaN(e.target.value)) { setTarifsAgain(e.target.value) } }} value={tarifsAgain} type='number' step='100' className="input-text" placeholder='Tarif Try Again'/>
						</label>
						<label className='quarter-row setting-number'>Coût duel *
							<input onChange={e => { if (!isNaN(e.target.value)) { setTarifsDuel(e.target.value) } }} value={tarifsDuel} type='number' step='100' className="input-text" placeholder='Tarif Duel'/>
						</label>
						<label className='quarter-row setting-number'>Coût Gran Trofeo *
							<input onChange={e => { if (!isNaN(e.target.value)) { setTarifsGT(e.target.value) } }} value={tarifsGT} type='number' step='100' className="input-text" placeholder='Tarif GranTrofeo'/>
						</label>
						<label className='quarter-row setting-number'>Coût Tourisme *
							<input onChange={e => { if (!isNaN(e.target.value)) { setTourismCost(e.target.value) } }} value={tourismCost} type='number' step='100' className="input-text" placeholder='Tarif GranTrofeo'/>
						</label>
					</div>
					<div style={{ display: 'inline' }}>
						<label>
							<input className="mr-2" onChange={e => { setIsShow(!(e.target.checked)); console.log(isShow) }} type='checkbox' checked={!isShow} />
							Ne pas afficher le challenge dans l'appli
						</label>
					</div>
					<div className='fieldset-wrapper' style={{ display: 'flex', flexDirection: 'row' }}>
						<button className="button button-shadow" style={{ height: 'fit-content' }}>Remettre le classement à 0</button>
						{/** <button className='button button-shadow' onClick={() => {post(title, code, description, date, dateFin, dateInscription, parseInt(placeMax), visualUrl, parseInt(tarifsParcours), parseInt(tarifsAgain), parseInt(tarifsGT), parseInt(tarifsDuel), '', parcours.filter(element => element!=null && element!='' && element!=undefined).map(element => `/api/courses/${element}`), dotations.filter(element => element.iri!=null && element.iri!='' && element.iri!=undefined),
                partenaires.filter(item => item.name !== null && item.name !== "")
                )}}>Valider</button>
						 */}
						{ title && date && dateFin && dateInscription && description && visualUrl && tarifsAgain && tarifsDuel && tarifsGT && tarifsParcours
						  ? <button className='button button-shadow ml-3' onClick={() => {
							  post(
							    parcours.filter(element => element != null && element !== '' && element !== undefined).map(element => `/api/courses/${element}`),
							    dotations.filter(element => element.iri != null && element.iri !== '' && element.iri !== undefined),
							    partenaires.filter(item => item.name !== null && item.name !== ''),
							    isShow,
							    code,
							    [
							      { title, description, locale: 'fr', image: visualUrl },
							      { title: titleEn, description: descriptionEn, locale: 'en', image: visualUrlEn }
							    ],
							    date,
							    dateFin,
							    dateInscription,
							    dateShowStart,
							    dateShowEnd,
							    parseInt(placeMax),
							    null,
							    parseInt(tarifsParcours),
							    parseInt(tarifsAgain),
							    parseInt(tarifsGT),
							    parseInt(tarifsDuel),
							    +tourismCost
						    )
						  }}>Valider</button>
						  :							<button className='button button-shadow ml-3' style={{ height: 'fit-content' }}>Certaines conditions ne sont pas remplies pour pouvoir enregistrer ce challenge</button>
						}

					</div>
				</div>
			</div>
		</>
  )
}

// == Export
export default ChallengesAjout
