// == Import : npm
import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

// == Import : local
import '../../style/style.scss';

// == Composant
const PlaceSearch = ({ location, match, datas, waitingRequest, getTools, deleteEntity, connected, addCoordinates }) => {

    const [address, setAddress] = useState('')

    return (
    <>
    <PlacesAutocomplete
        value={address}
        onChange={(e) => setAddress(e)}
        onSelect={() => {
            setAddress(address)
            geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {console.log('Success', latLng); addCoordinates(latLng); setAddress('')})
            .catch(error => console.error('Error', error));
        }}
      >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Saisissez une nouvelle étape',
                className: 'location-search-input input-text',
                style: {width:300}
              })}
            />
            <div className="autocomplete-dropdown-container" style={{padding:15, width:285, borderRadius:10, backgroundColor:'white', display: suggestions.length>0 ? '' : "none"}}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
    )
};

// == Export
export default PlaceSearch;
