// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import UtilisateursEdit from '../../screen/UtilisateursEdit';

// Action Creators
import { patch, getOne } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  data: state.oneusers,
  waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patch: (
    email = '',
    firstname = '',
    lastname = '',
    birthdate = '',
    country = '',
    city = '',
    zipCode = '',
    address = '',
    address2 = '',
    password,
    roles = '',
    balance = 0,
    status = '',    
    civilTitle = '',
    nationality = '',
    phone = '',
    informationAgree=0,
    newsletter='false',
    willDo
) => {
    dispatch(patch('users', ownProps.match.params.id, {
        email,
        firstname,
        lastname,
        birthdate,
        address:{
            country,
            city,
            zipCode,
            address,
            address2,
        },
        password,
        roles:[roles],
        balance : parseInt(balance),
        status,
        civilTitle,
        nationality,
        phone,
        informationAgree,
        newsletter,
    } ,
    willDo, ownProps.history));
  },
  getOne: (id) => {
    dispatch(getOne('users', id));

  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UtilisateursEdit);