// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import NewsEdit from '../../screen/NewsEdit';
import moment from 'moment';

// Action Creators
import { patch, upload, getOne } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  data: state.onenews,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patch: (title='', publishedAt = '', content='', images='', date, translationEn) => {
    dispatch(patch('news', ownProps.match.params.id, {
      translations: [
        {
          locale:'fr',
          title,
          content,
          images,
        },
        translationEn[0]
      ],
      // title,
      // content,
      // images,
      publishedAt:  moment(publishedAt).format('yyy/MM/DD HH:mm:ss'),
      date: moment(date).format('yyy/MM/DD HH:mm:ss'),
    }, ()=>{}))
  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
  getOne: (id, ) => {
    dispatch(getOne('news', id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsEdit);