// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ProduitsEdit from '../../screen/ProduitsEdit';

// Action Creators
import { patch, upload, getOne } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  data: state.oneproducts,
  settings: state.onesettings,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});


const mapDispatchToProps = (dispatch, ownProps) => ({
  patch: (title='', description, cost=0, wheels=0) => {
    dispatch(patch('products', ownProps.match.params.id, {title, description, cost : parseInt(cost), wheels : parseInt(wheels)}, ownProps.history));
  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
  getOne: (id) => {
    dispatch(getOne('products', id));
    dispatch(getOne('settings', 1));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProduitsEdit);