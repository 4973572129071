import { connect } from 'react-redux';
import Traduction from '../../screen/Traduction';
import { upload, getFeatLang } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  featLang: state.featLang
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  upload: (data) => {
    dispatch(upload(data, 'geodata_objects'));
  },
  getFeatLang: (code) => {
    dispatch(getFeatLang(code));
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Traduction);