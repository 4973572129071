// == Import : npm
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt, faFolderPlus } from '@fortawesome/free-solid-svg-icons'

// == Import : local
import '../../style/style.scss';
import FileUpload from '../../components/FileUpload';
import ImagePicker from '../../containers/ImagePicker';

// == Composant
const Listing = ({ location, match, waitingRequest, deactivateWaitingRequest, onImageChoose, upload, imageUrl, setImageUrl }) => {

	const [pickImage, setPickImage] = useState(false)
	const [sizeError, setSizeError] = useState(false)

	const onImageChange = (event) => {
		if(event.target.files.length !== 0) {
			const reader = new FileReader();

			reader.onload = (e) => {
				setImageUrl(e.target.result);
				console.log(e.target.result)
			}

			reader.readAsDataURL(event.target.files[0]);
		}
	}

	return (
		<>
			{sizeError && <p>La taille du fichier ne peut pas dépasser 1Mo.</p>}
			<div className='image-container'>
				{typeof(imageUrl) == 'string' && imageUrl !== '' ? <div className='image-frame'><img alt="" src={`${process.env.REACT_APP_API_URL}/${imageUrl.replace(process.env.REACT_APP_API_URL, '')}`} /></div> : <></>}
				<FileUpload
					accept={"image/png, image/jpeg"}
					inputText={
						<FontAwesomeIcon
							size='2x'
							icon={faCloudUploadAlt}
							style={{cursor: 'pointer'}}
						/>
					}
					OnClickDo={(e) => {
						onImageChange(e);
						const fileURI = e.target.files[0];
						console.log(e.target.files)
						if(e.target.files[0].size > 1048576) {
							setSizeError(true);
							return false;
						} else {
							setSizeError(false);
						}
						const data = new FormData();
						data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
						upload(data, (url)=>{setImageUrl(url)});
						console.log('onClickdo', data.get('file'))
					}}
					buttonClass={"image-button upload-button"}
				/>
				<div className='image-button picker-button'>
					<FontAwesomeIcon size='2x' icon={faFolderPlus} style={{cursor: 'pointer'}} onClick={() => {setPickImage(true); deactivateWaitingRequest(true)}} />
					{pickImage && <ImagePicker setPickImage={setPickImage} onClick={(e) => {console.log({e});setImageUrl(e); setPickImage(false);deactivateWaitingRequest(false)}} />}
				</div>
			</div>
		</>
	)
};

// == Export
export default Listing;
