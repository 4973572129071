// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';
import ImageSelector from '../../containers/ImageSelector';

// == Composant
const NewsEdit = ({ location, match, data, waitingRequest, connected, patch, upload, lastUploadedImage, getOne }) => {

	const [imageUrl, setImageUrl] = useState([null]);
	const [imageUrlEn, setImageUrlEn] = useState([null]);

	const [titre, setTitre] = useState(null)
	const [date, setDate] = useState(null)
	const [date2, setDate2] = useState(null)
	const [content, setContent] = useState(null)
	const [pickImage, setPickImage] = useState(false)

	// state translate language
	const [isLanguageFr, setIsLanguageFr] = useState(true);
	const [titleEn, setTitleEn] = useState('');
	const [contentEn, setContentEn] = useState('');

	useEffect(() => {
		getOne(`${match.params.id}?groups[]=translations`)
	}, []) //eslint-disable-line

	useEffect(() => {
		if(data) {
			setTitre(data.title)
			setDate(new Date(data.publishedAt))
			setDate2(new Date(data.date))
			setImageUrl(data.images)
			setContent(data.content)

			if(data.translations.en) {
				setTitleEn(data.translations.en.title)
				setContentEn(data.translations.en.content)
				setImageUrlEn(data.translations.en.images)
			}
		}
	}, [data])

	if(waitingRequest > 0 && !pickImage ) {
		return (
			<div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
				<ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
			</div>
		)
	}

	return (
		<>
			<Header currentMenuItem={15} entries={boEntries} location={location} match={match} />
			<div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
				<div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
					<h1 className='page-title'>News</h1>
				</div>
				<div className="form-wrapper">
					<div style={{display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end'}}>
						<input type='button' className='button button-shadow' value='Francais' style={{backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white'}} onClick={() => {
							setIsLanguageFr(true);

						}}/>
						<input type='button' className='button button-shadow' value='Anglais' style={{backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white'}} onClick={() => {
							setIsLanguageFr(false);
						}}/>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
						{isLanguageFr && <input value={titre} onChange={(e) => {setTitre(e.target.value)}} type='text' className="input-text" placeholder='Titre(FR) *' style={{width:'45%'}}/>}
						{!isLanguageFr && <input value={titleEn} onChange={(e) => {setTitleEn(e.target.value)}} type='text' className="input-text" placeholder='Titre(EN) *' style={{width:'45%'}}/>}            <div>
						<span style={{marginRight:15, fontSize:15}}>Date de publication : </span>
						<DatePicker
							className='button date-picker'
							selected={date}
							onChange={(e) => setDate(e)}
							locale="fr"
							dateFormat='dd/MM/yyyy'
						/>
					</div>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
						<span style={{marginRight:15, fontSize:15}}>Date de programmation : </span>
						<DatePicker
							className='button date-picker'
							selected={date2}
							onChange={(e) => setDate2(new Date(e.setMinutes(e.getMinutes() - 1)))}
							locale="fr"
							dateFormat='dd/MM/yyyy'
						/>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'column', justifyContent:"space-between"}}>
						<p>Visuel de la news : </p>
						{isLanguageFr ?
							<div id='fr'>
								{imageUrl.map((element, index) => {
									return (
										<div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
											<div style={{width:'70%'}}>
												<ImageSelector imageUrl={element} setImageUrl={(e) => {const temp = imageUrl.slice(); temp[index] = e; setImageUrl(temp)}} deactivateWaitingRequest={setPickImage} />
											</div>
											<FontAwesomeIcon  onClick={() => {setImageUrl(imageUrl.filter((e, i) => i!==index))}} size='2x' icon={faTimesCircle}  className='fa-button plus' style={{}}/>
										</div>
									)
								})}
								<FontAwesomeIcon  onClick={() => {setImageUrl([...imageUrl, null])}} size='2x' icon={faPlusCircle}  className='fa-button plus' style={{marginTop: 30}}/>
							</div>
							:
							<div id='en'>
								{imageUrlEn.map((element, index) => {
									return (
										<div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
											<div style={{width:'70%'}}>
												<ImageSelector imageUrl={element} setImageUrl={(e) => {const temp = imageUrlEn.slice(); temp[index] = e; setImageUrlEn(temp)}} deactivateWaitingRequest={setPickImage} />
											</div>
											<FontAwesomeIcon  onClick={() => {setImageUrlEn(imageUrlEn.filter((e, i) => i!==index))}} size='2x' icon={faTimesCircle}  className='fa-button plus' style={{}}/>
										</div>
									)
								})}
								<FontAwesomeIcon  onClick={() => {setImageUrlEn([...imageUrlEn, null])}} size='2x' icon={faPlusCircle}  className='fa-button plus' style={{marginTop: 30}}/>
							</div>
						}
					</div>
					<div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px'}}>
						{isLanguageFr && (
							<>
								<span style={{marginBottom: "5px"}}>Description(FR) : </span>
								<CKEditor
									editor={ ClassicEditor }
									data={content}
									onInit={ editor => {
										// You can store the "editor" and use when it is needed.
										console.log( 'Editor is ready to use!', editor );
									} }
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										setContent(data);
										console.log( { event, editor } );
									} }
									onBlur={ ( event, editor ) => {
										console.log( 'Blur.', editor );
									} }
									onFocus={ ( event, editor ) => {
										console.log( 'Focus.', editor );
									} }
								/>
							</>
						)}
						{!isLanguageFr && (
							<>
								<span style={{marginBottom: "5px"}}>Description(EN) : </span>
								<CKEditor
									editor={ ClassicEditor }
									data={contentEn}
									onInit={ editor => {
										// You can store the "editor" and use when it is needed.
										console.log( 'Editor is ready to use!', editor );
									} }
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										setContentEn(data);
										console.log( { event, editor } );
									} }
									onBlur={ ( event, editor ) => {
										console.log( 'Blur.', editor );
									} }
									onFocus={ ( event, editor ) => {
										console.log( 'Focus.', editor );
									} }
								/>
							</>
						)}
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', margin: '0 50px'}}>
						<button className='button button-shadow' onClick={() => {
							// console.log('en data',[{title : titleEn, content: contentEn, locale: 'en',image: imageUrl.filter(element => element!=null) }]);
							// console.log('titre',titre, 'date',date, 'content',content, 'date2',date2, 'titleEn',titleEn,  'contentEn',contentEn);
							patch(
								titre, date, content, imageUrl.filter(element => element!=null), date2, [{title : titleEn, content: contentEn, locale: 'en',images: (imageUrlEn[0] == null ? imageUrl.filter(element => element!=null) : imageUrlEn.filter(element => element!=null) ) }])}}>Valider</button>
					</div>
				</div>
			</div>
		</>
	)
};

// == Export
export default NewsEdit;
