import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { withCookies } from 'react-cookie'
import 'antd/dist/antd.css'
import './reset.css'
import './App.scss'

import { getUserRoles } from './utils/userRoles'

import Login from './containers/Login'
import Deconnexion from './containers/Deconnexion'
import Page404 from './screen/Page404'
import Reconnect from './screen/Reconnect'
import Home from './containers/Home'
import Utilisateurs from './containers/Utilisateurs'
import Parcours from './containers/Parcours'
import Challenges from './containers/Challenges'
import Numeros from './containers/Numeros'
import Dotations from './containers/Dotations'
import Classements from './containers/Classements'
import Produits from './containers/Produits'
import Commandes from './containers/Commandes'
import Codes from './containers/Codes'
import Configuration from './containers/Configuration'
import Traduction from './containers/Traduction'
import Media from './containers/Media'
import Tooltips from './containers/Tooltips'
import ParcourAjout from './containers/ParcoursAjout'
import ChallengesAjout from './containers/ChallengesAjout'
import DotationsAjout from './containers/DotationsAjout'
import News from './containers/News'
import Pages from './containers/Pages'
import NewsAjout from './containers/NewsAjout'
import CodesAjout from './containers/CodesAjout'
import ProduitsAjout from './containers/ProduitsAjout'
import UtilisateursAjout from './containers/UtilisateursAjout'
import DotationsEdit from './containers/DotationsEdit'
import NewsEdit from './containers/NewsEdit'
import ParcourEdit from './containers/ParcourEdit'
import Faq from './containers/Faq'
import FaqAjout from './containers/FaqAjout'
import ChallengesEdit from './containers/ChallengesEdit'
import TooltipsEdit from './containers/TooltipsEdit'
import FaqEdit from './containers/FaqEdit'
import CodesEdit from './containers/CodesEdit'
import ProduitsEdit from './containers/ProduitsEdit'
import UtilisateursEdit from './containers/UtilisateursEdit'
import PagesEdit from './containers/PagesEdit'
import Slides from './containers/Slides'
import SlidesAjout from './containers/SlidesAjout'
import SlidesEdit from './containers/SlidesEdit'
import ParcoursMap from './containers/ParcoursMap'
import Participations from './containers/Participations'
import TeamNumbers from './containers/Numeros/TeamNumbers'
import axios from 'axios'

function App (props) {
  const { connected } = props

  const [userSessionExpired, setUserSessionExpired] = useState(false)

  const ProtectedRoutes = () => {
    const userRoles = getUserRoles(connected)

    switch (userRoles) {
      case 'ROLE_ADMIN':
        return (
          <Switch>
            <Route exact path="/" render={(props) => <Login {...props} />}>
            </Route>
            <Route exact path="/tableau-de-bord" render={(props) => <Home {...props} />}>
            </Route>
            <Route exact path="/utilisateurs/ajout" render={(props) => <UtilisateursAjout {...props} />}>
            </Route>
            <Route exact path="/utilisateurs/:page(\d+)" render={(props) => <Utilisateurs {...props} />}>
            </Route>
            <Route exact path="/utilisateurs/edit/:id(\d+)" render={(props) => <UtilisateursEdit {...props} />}>
            </Route>
            <Route exact path="/parcours/:page(\d+)" render={(props) => <Parcours {...props} />}>
            </Route>
            <Route exact path="/parcours/carte" render={(props) => <ParcoursMap {...props} />}>
            </Route>
            <Route exact path="/parcours/ajout" render={(props) => <ParcourAjout {...props} />}>
            </Route>
            <Route exact path="/parcours/edit/:id(\d+)" render={(props) => <ParcourEdit {...props} />}>
            </Route>
            <Route exact path="/challenges/:page(\d+)" render={(props) => <Challenges {...props} />}>
            </Route>
            <Route exact path="/challenges/ajout" render={(props) => <ChallengesAjout {...props} />}>
            </Route>
            <Route exact path="/challenges/edit/:id(\d+)" render={(props) => <ChallengesEdit {...props} />}>
            </Route>
            <Route exact path="/numeros" render={(props) => <Numeros {...props} />}>
            </Route>
            <Route exact path="/numeros/team-numbers" render={(props) => <TeamNumbers {...props} />}>
            </Route>
            <Route exact path="/dotations/:page(\d+)" render={(props) => <Dotations {...props} />}>
            </Route>
            <Route exact path="/faq/edit/:id(\d+)" render={(props) => <FaqEdit {...props} />}>
            </Route>
            <Route exact path="/dotations/ajout" render={(props) => <DotationsAjout {...props} />}>
            </Route>
            <Route exact path="/dotations/edit/:id(\d+)" render={(props) => <DotationsEdit {...props} />}>
            </Route>
            <Route exact path="/classements/:page(\d+)/:id(\d+)?" render={(props) => <Classements {...props} />}>
            </Route>
            <Route exact path="/classements/user/:page(\d+)/:user(\d+)" render={(props) => <Classements {...props} />}>
            </Route>
            <Route exact path="/produits/:page(\d+)" render={(props) => <Produits {...props} />}>
            </Route>
            <Route exact path="/produits/ajout" render={(props) => <ProduitsAjout {...props} />}>
            </Route>
            <Route exact path="/produits/edit/:id(\d+)" render={(props) => <ProduitsEdit {...props} />}>
            </Route>
            <Route exact path="/commandes/:page(\d+)" render={(props) => <Commandes {...props} />}>
            </Route>
            <Route exact path="/commandes/:page(\d+)" render={(props) => <Commandes {...props} />}>
            </Route>
            <Route exact path="/commandes/user/:page(\d+)/:user(\d+)" render={(props) => <Commandes {...props} />}>
            </Route>
            <Route exact path="/codes/:page(\d+)" render={(props) => <Codes {...props} />}>
            </Route>
            <Route exact path="/codes/ajout" render={(props) => <CodesAjout {...props} />}>
            </Route>
            <Route exact path="/codes/edit/:id(\d+)" render={(props) => <CodesEdit {...props} />}>
            </Route>
            <Route exact path="/configuration" render={(props) => <Configuration {...props} />}>
            </Route>
            <Route exact path="/traduction" render={(props) => <Traduction {...props} />}>
            </Route>
            <Route exact path="/media" render={(props) => <Media {...props} />}>
            </Route>
            <Route exact path="/tooltips/:page(\d+)" render={(props) => <Tooltips {...props} />}>
            </Route>
            <Route exact path="/tooltips/edit/:id" render={(props) => <TooltipsEdit {...props} />}>
            </Route>
            <Route exact path="/faq/:page(\d+)" render={(props) => <Faq {...props} />}>
            </Route>
            <Route exact path="/faq/ajout" render={(props) => <FaqAjout {...props} />}>
            </Route>
            <Route exact path="/faqs/edit/:id(\d+)" render={(props) => <FaqEdit {...props} />}>
            </Route>
            <Route exact path="/news/:page(\d+)" render={(props) => <News {...props} />}>
            </Route>
            <Route exact path="/news/ajout" render={(props) => <NewsAjout {...props} />}>
            </Route>
            <Route exact path="/news/edit/:id(\d+)" render={(props) => <NewsEdit {...props} />}>
            </Route>
            <Route exact path="/participations/:page(\d+)" render={(props) => <Participations {...props}/>}>
            </Route>
            <Route exact path="/participations/:page(\d+)/:user(\d+)" render={(props) => <Participations {...props}/>}>
            </Route>
            <Route exact path="/pages/:page(\d+)" render={(props) => <Pages {...props} />}>
            </Route>
            {/*
            ### Uncomment to unable
            <Route exact path="/pages/ajout" render={(props) => <PagesAjout {...props} />}>
            </Route> */}
            <Route exact path="/pages/edit/:id(\d+)" render={(props) => <PagesEdit {...props} />}>
            </Route>
            <Route exact path="/slides/:page(\d+)" render={(props) => <Slides {...props} />}>
            </Route>
            <Route exact path="/slides/ajout" render={(props) => <SlidesAjout {...props} />}>
            </Route>
            <Route exact path="/slides/edit/:id(\d+)" render={(props) => <SlidesEdit {...props} />}>
            </Route>
            <Route exact path="/reconnect" render={() => <Reconnect msg="Veuillez vous reconnecter pour mettre à jour votre rôle." btn="Se deconnecter"/>}>
            </Route>
            <Route exact path="/deconnexion" component={Deconnexion}>
            </Route>
            <Route component={Page404}>
            </Route>
          </Switch>
        )
      case 'ROLE_PARCOURS':
        return (
          <Switch>
            <Route exact path="/" render={(props) => <Login {...props} />}>
            </Route>
            <Route exact path="/tableau-de-bord" render={(props) => <Home {...props} />}>
            </Route>
            <Route exact path="/parcours/:page(\d+)" render={(props) => <Parcours {...props} />}>
            </Route>
            <Route exact path="/parcours/carte" render={(props) => <ParcoursMap {...props} />}>
            </Route>
            <Route exact path="/parcours/ajout" render={(props) => <ParcourAjout {...props} />}>
            </Route>
            <Route exact path="/parcours/edit/:id(\d+)" render={(props) => <ParcourEdit {...props} />}>
            </Route>
            <Route exact path="/deconnexion" component={Deconnexion}>
            </Route>
            <Route component={Page404}>
            </Route>
          </Switch>
        )
      case 'ROLE_COMMUNICATION':
        return (
          <Switch>
            <Route exact path="/" render={(props) => <Login {...props} />}>
            </Route>
            <Route exact path="/tableau-de-bord" render={(props) => <Home {...props} />}>
            </Route>
            <Route exact path="/dotations/:page(\d+)" render={(props) => <Dotations {...props} />}>
            </Route>
            <Route exact path="/dotations/ajout" render={(props) => <DotationsAjout {...props} />}>
            </Route>
            <Route exact path="/dotations/edit/:id(\d+)" render={(props) => <DotationsEdit {...props} />}>
            </Route>
            <Route exact path="/tooltips/:page(\d+)" render={(props) => <Tooltips {...props} />}>
            </Route>
            <Route exact path="/tooltips/edit/:id" render={(props) => <TooltipsEdit {...props} />}>
            </Route>
            <Route exact path="/news/:page(\d+)" render={(props) => <News {...props} />}>
            </Route>
            <Route exact path="/news/ajout" render={(props) => <NewsAjout {...props} />}>
            </Route>
            <Route exact path="/news/edit/:id(\d+)" render={(props) => <NewsEdit {...props} />}>
            </Route>
            <Route exact path="/slides/:page(\d+)" render={(props) => <Slides {...props} />}>
            </Route>
            <Route exact path="/slides/ajout" render={(props) => <SlidesAjout {...props} />}>
            </Route>
            <Route exact path="/slides/edit/:id(\d+)" render={(props) => <SlidesEdit {...props} />}>
            </Route>
            <Route exact path="/challenges/:page(\d+)" render={(props) => <Challenges {...props} />}>
            </Route>
            <Route exact path="/challenges/ajout" render={(props) => <ChallengesAjout {...props} />}>
            </Route>
            <Route exact path="/challenges/edit/:id(\d+)" render={(props) => <ChallengesEdit {...props} />}>
            </Route>
            <Route exact path="/deconnexion" component={Deconnexion}>
            </Route>
            <Route component={Page404}>
            </Route>
          </Switch>
        )
      default:
        return (
          <Switch>
            <Route exact path="/" render={(props) => <Login {...props} />}>
            </Route>
            <Route exact path="/deconnexion" component={Deconnexion}>
            </Route>
            <Route component={Reconnect}>
            </Route>
          </Switch>
        )
    }
  }

  // console.log(process.env.REACT_APP_API_URL)

  axios.get(
    `${process.env.REACT_APP_API_URL}/me`,
    { withCredentials: true }
  ).then((response) => {
    if (connected !== null && response.data === null) {
      setUserSessionExpired(true)
    } else {
      setUserSessionExpired(false)
    }
  })

  return (
    <Router>
      <div className="App">
        {userSessionExpired && (
          <>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              padding: 30,
              color: 'rgb(169, 46, 12)',
              fontSize: 20,
              fontWeight: 'bold',
              backgroundColor: 'lightGray'
            }}>
              Votre session a expiré et vous êtes déconnecté...
            </div>
            <Deconnexion />
          </>
        )}
        {!userSessionExpired && ProtectedRoutes()}
      </div>
    </Router>
  )
}

export default withCookies(App)
