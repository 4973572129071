// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ChallengesAjout from '../../screen/ChallengesAjout';

// Action Creators
import { post, upload, getOne, getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  settings: state.onesettings,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
  rewards: state.rewards
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  post: (
    courses,
    dotations,
    partenaires,
    isShow,
    code='',
    translations=[],
    startAt='',
    endAt='',
    registryLimitDate='',
    dateShowStart=null,
    dateShowEnd=null,
    maxSlots=0,
    image='',
    normalCost=0,
    tryAgainCost=0,
    gtCost=0,
    duelCost=0,
    tourismCost = 0,
    ) => {

    dispatch(post('challenges', {
      code,
      startAt,
      endAt,
      registryLimitDate,
      dateShowStart,
      dateShowEnd,
      maxSlots,
      isShow,
      normalCost,
      tryAgainCost,
      gtCost,
      duelCost,
      tourismCost,
      status: '',
      courses,
      challengeDotations:dotations.map(element=>({reward: element.iri, minRank: element.min, maxRank: parseInt(element.max ? element.max : element.min ), isWon : true}) ),
      partners: partenaires,
      translations: translations
      },
      (datas) => {ownProps.history.push(`/challenges/edit/${datas.id}`)}, ownProps.history));
  },
  upload: (data, willDo) => {
    dispatch(upload(data, 'media_objects', willDo));
  },

  getAll: ( page, params) => {
    dispatch(getAll('rewards', page, params));
  },

  getOne: () => {
    dispatch(getOne('settings', 1));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChallengesAjout);
