// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Classements from '../../screen/Classements';

// Action Creators
import { getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.scores,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAll: ( page, param, willDo) => {
    dispatch(getAll('scores', page, param, willDo));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Classements);
