// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Commandes from '../../screen/Commandes';

// Action Creators
import { getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.receipts,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  /*Commandes: (mail, password) => {
    dispatch(Commandes(mail, password, ownProps.history));
  },*/
  getAll: (type, params) => {
    dispatch(getAll(type, 1, params));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Commandes);