// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import Axios from 'axios';
import MultiSelect from '../../components/MultiSelect';

// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import { boEntries } from '../../utils/headerEntries.js';
registerLocale('fr', fr)

// == Composant
const CodesEdit = ({ location, match, datas, waitingRequest, patch, getOne, connected, getAll, challenges }) => {
    var randomstring = require("randomstring");

    const [titre, setTitre] = useState(null)
    const [code, setCode] = useState(null)
    const [max, setMax] = useState(null)
    const [value, setValue] = useState(0)
    const [type, setType] =  useState('direct')
    //eslint-disable-next-line
    const [status, setStatus] = useState()
    const [challengesSelected, setChallengesSelected] = useState([]);
    const [date, setDate] = useState(null)
    const [uniqueUse, setUniqueUse] = useState(false)
    const [listChallenges, setListChallenges] = useState('')
    //eslint-disable-next-line
    const [isSearching, setIsSearching] = useState(false)


    useEffect(() => {
        getOne(match.params.id)
        getListChallenges();
    }, []) //eslint-disable-line

    useEffect(() => {
        if(datas) {
            setTitre(datas.title)
            setCode(datas.code)
            setMax(datas.maxUses)
            setValue(datas.value)
            setType(datas.type)
            setUniqueUse(datas.hasUniqueUse)

            if(datas.limitDate){
                setDate(new Date(datas.limitDate));
            }

            if(datas.challenges){
                setChallengesSelected(datas.challenges);
            }
        }
    }, [datas])

    const getListChallenges = () => {
        Axios.get(`${process.env.REACT_APP_API_URL}/api/challenges?pagination=false`, {withCredentials:true}).then(response => setListChallenges(response.data['hydra:member']));
    }

    if(waitingRequest > 0 && !isSearching /*|| connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={9} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Codes</h1>
        </div>
        <div className="form-wrapper">
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row'}}>
                <input value={titre} onChange={e => { setTitre(e.target.value)}} type='text' className="input-text" placeholder="Titre *" style={{width:'45%', marginRight:20}}/>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <label style={{marginRight:10}}>Réduction</label>
                <input value={value} onChange={e => {  setValue(e.target.value)}} type='text' className="input-text" placeholder="Valeur de la réduction" style={{width:'45%', marginRight:20}}/>
                <select type='text' className="input-text" style={{width:'30%'}} value={type} onChange={e => { console.log(e.target.value);setType(e.target.value)} }>
                  <option value='direct'>Valeur brute</option>
                  <option value='%'>Pourcentage</option>
                </select>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <div>
                    <span style={{marginRight:15, fontSize:15}}>Valable jusqu'au : </span>
                    <DatePicker
                        className='button date-picker'
                        selected={date}
                        onChange={(e) => setDate(e)}
                        locale="fr"
                        dateFormat='dd/MM/yyyy'
                    />
                </div>
                <div>
                    <label style={{marginBottom: 10}}>Utilisable 1 fois par compte</label>
                    <input value={uniqueUse} onChange={e => {setUniqueUse(!uniqueUse)}} type="checkbox" defaultChecked={uniqueUse}/>
                </div>
                <div>

                    <label style={{marginRight:10, marginBottom: 10}}>Nb max d'utilisation</label>

                    <input value={max} onChange={e => setMax(e.target.value)} type='number' className="input-text" placeholder="nombre max d'utilisation *" style={{width:'45%'}}/>
                </div>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row'}}>
                <input value={code} onChange={e => setCode(e.target.value)} type='text' className="input-text" placeholder="code *" style={{width:'45%', marginRight:20}}/>
                <button onClick={() => {setCode(randomstring.generate(10))}} className='button button-shadow' style={{marginLeft:0}}>Random</button>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row'}}>
                <label className='col-sm-3 mt-2' style={{width: '100px'}}>Challenges : </label>
               {listChallenges && datas && <MultiSelect selected={challengesSelected} setSelected={setChallengesSelected} datas={listChallenges.map(element => ({id:element['@id'], title: element.title}))} />}
            </div>
            <button className='button button-shadow' onClick={() => {patch(titre, code, parseInt(max), parseInt(value), type, status, challengesSelected, date, uniqueUse)}}>Valider</button>
        </div>
    </div>
    </>
    )
};


// == Export
export default CodesEdit;
