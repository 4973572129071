// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Numeros from '../../screen/Numeros';

// Action Creators
import { getAll, deleteEntity, post, patch } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.series,
  data: state.oneseries,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAll: (page) => {
    dispatch(getAll('series', page, ownProps.history));
  },
  deleteEntity: (id) => {
    dispatch(deleteEntity('series', id, ownProps.history));
  },
  post: (name='', reservedRange, color1='', color2='', maxSlots, rank) => {
    dispatch(post('series', {name, color1, color2, reservedRange, maxSlots: parseInt(reservedRange.split(',')[1]) - parseInt(reservedRange.split(',')[0])+1, rank, font:''}, ownProps.history));
  },
  patch: (id, name='', reservedRange, color1='', color2='', willDo) => {
    dispatch(patch('series', id, {name, color1, color2, reservedRange, maxSlots: parseInt(reservedRange.split(',')[1]) - parseInt(reservedRange.split(',')[0])+1}, willDo));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Numeros);