/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

// == Import : npm
import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import update from 'immutability-helper'
import ReactLoading from 'react-loading'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Geocode from 'react-geocode'

// == Import : local
import '../../style/style.scss'
import Header from '../../containers/Header'
import PlaceSearch from '../../components/PlaceSearch'
import { Card } from '../../components/Card'
import MultiSelect from '../../components/MultiSelect'
import { MapWithDestination } from '../../components/MapWithDestinations'
import { boEntries } from '../../utils/headerEntries.js'
import { timeManipulation } from '../../utils/timeManipulation'
import lookup from 'country-code-lookup'
import { uploadFormData } from '../../utils/uploadFormData'
import moment from 'moment'

// == Composant
const ParcourAjout = ({ location, match, waitingRequest, post, connected, upload, getAll, getSettings, challenges, settings, setMessageNotif }) => {
  const [title, setTitle] = useState('')
  // refAller is used in child component
  // eslint-disable-next-line no-unused-vars
  const [refAller, setRefAller] = useState(null)
  const [waypoints, setWaypoint] = useState([])
  const [retourWaypoints, setRetourWaypoint] = useState([])

  // const [cards, setCards] = useState([])
  const [typesEtapes, setTypesEtapes] = useState([])
  const [typesEtapesRetour, setTypesEtapesRetour] = useState([])
  const [typesEtapesError, setTypesEtapesError] = useState([false, false, false])
  const [directions, setDirections] = useState()
  const [retour, setRetour] = useState(null)
  const [newPoint, setNewPoint] = useState('null')
  const [displayRetour, setDisplayRetour] = useState(false)
  const [allowReturn, setAllowReturn] = useState(true)
  const [titleDepartArrive] = useState('false')
  const [allowGT, setAllowGT] = useState(false)
  const [distance, setDistance] = useState('0')
  const [averageTime, setAverageTime] = useState('0')
  const [distanceRetour, setDistanceRetour] = useState('0')
  const [averageTimeRetour, setAverageTimeRetour] = useState('0')
  const [minTime, setMinTime] = useState(null)
  const [maxTime, setMaxTime] = useState(null)
  const [minTimeRetour, setMinTimeRetour] = useState(null)
  const [maxTimeRetour, setMaxTimeRetour] = useState(null)
  const [status] = useState('')
  const [challengesSelected, setChallengesSelected] = useState([])
  const [activeChallenges, setActiveChallenges] = useState([])
  const [isShow, setIsShow] = useState(true)
  const [description, setDescription] = useState('')

  // state translate language
  const [isLanguageFr, setIsLanguageFr] = useState(true)
  const [descriptionEn, setDescriptionEn] = useState('')
  const [codePays, setCodePays] = useState('')
  const [code, setCode] = useState('')
  console.log({ code })

  // gestion dnd
  const [pointModif, setPointModif] = useState(null)
  const [last, setLast] = useState(null)
  console.log({ last })

  useEffect(() => {
    axios.get(
			`${process.env.REACT_APP_API_URL}/api/course/nextautoincrementid`,
			{ withCredentials: true }
    ).then((response) => {
      // console.log({response})
      setLast(parseInt(response.data[0]?.AUTO_INCREMENT))
    })
    getSettings()
    getAll('challenges', 1, 'pagination=false')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const tempActiveChallenges = []
    challenges && challenges['hydra:member'].map((element) => {
      const endDate = moment(element.endAt).format('yyy/MM/DD HH:mm:ss')
      const nowDate = moment().format('yyy/MM/DD HH:mm:ss')
      if (endDate > nowDate) {
        tempActiveChallenges.push({ id: element['@id'], title: element.title })
      }
    })
    setActiveChallenges(tempActiveChallenges)
  }, [challenges])

  useEffect(() => {
    if (directions && directions.routes.length > 0 && directions.routes[0].legs.length > 0) {
      const { calculatedMinTime, calculatedMaxTime, calculatedAverageTime, totalDistance } = timeManipulation(directions.routes[0], settings)

      setAverageTime(calculatedAverageTime)
      setMinTime(calculatedMinTime)
      setMaxTime(calculatedMaxTime)
      setDistance(totalDistance)
    }
  }, [directions])

  useEffect(() => {
    if (retour && retour.routes.length > 0 && retour.routes[0].legs.length > 0) {
      const { calculatedMinTime, calculatedMaxTime, calculatedAverageTime, totalDistance } = timeManipulation(retour.routes[0], settings)

      setAverageTimeRetour(calculatedAverageTime)
      setMinTimeRetour(calculatedMinTime)
      setMaxTimeRetour(calculatedMaxTime)
      setDistanceRetour(totalDistance)
    }
  }, [retour])

  useEffect(() => {
    if (waypoints.length > 0) {
      Geocode.fromLatLng(typeof waypoints[0].location.lat === 'function'
        ? waypoints[0].location.lat()
        : waypoints[0].location.lat, typeof waypoints[0].location.lng === 'function'
        ? waypoints[0].location.lng()
        : waypoints[0].location.lng)
        .then(
          (response) => {
            // console.log('Geocode', response)
            // console.log('Geocode', response.results[0].address_components[response.results[0].address_components.length-2].short_name)
            // const address = response.results[response.results.length-1].address_components[0].short_name;
            // console.log('ADDRESS', response.results[0].address_components)
            const address = response.results[0].address_components.find(element => (element.short_name && element.short_name.length === 2 && lookup.byIso(element.short_name) != null))?.short_name
            // const address = response.results[0].address_components[response.results[0].address_components.length-2].short_name;
            axios.get(
							`${process.env.REACT_APP_API_URL}/api/course/nextautoincrementid`,
							{ withCredentials: true }
            ).then((response) => {
              console.log({ response })
              setLast(parseInt(response.data[0]?.AUTO_INCREMENT))
              setCode(`P-${codePays || address}-${response.data[0]?.AUTO_INCREMENT}`)
            })
          },
          error => {
            console.error('geocode', error)
          }
        )
    }
  }, [waypoints])

  useEffect(() => {
    // console.log(retourWaypoints.length)
    if (retourWaypoints.filter(element => element.stopover === true).length > typesEtapesRetour.length + 1) {
      const temp = typesEtapesRetour.slice()
      if (retourWaypoints.length === 2) {
        temp.push('chrono')
      } else if (retourWaypoints.length === 3) {
        temp.push('tampon')
      } else {
        temp.push(null)
      }
      setTypesEtapesRetour(temp)
    }
  }, [retourWaypoints])

  useEffect(() => {
    if (typesEtapesRetour.length === typesEtapes.length) { setTypesEtapesRetour(typesEtapes) }
  }, [typesEtapes])

  useEffect(() => {
    const tempError = typesEtapesError.slice()
    // console.log(typesEtapes)
    // console.log(typesEtapesRetour)
    if (typesEtapes.filter(element => element === 'chrono').length !== 3 || typesEtapesRetour.filter(element => element === 'chrono').length !== 3) {
      tempError[0] = false
    } else {
      tempError[0] = true
    }
    if (typesEtapes[typesEtapes.length - 1] === 'tampon' && typesEtapesRetour[typesEtapesRetour.length - 1] === 'tampon') {
      tempError[1] = true
    } else {
      tempError[1] = false
    }

    let value = true
    // console.log(typesEtapes)
    typesEtapes.forEach((element, index) => {
      if (element === 'tampon' && (typesEtapes[index + 1] === 'tampon' || typesEtapes[index + 1] === '' || typesEtapes[index + 1] === null)) {
        value = false
      }
    })
    typesEtapesRetour.forEach((element, index) => {
      if (element === 'tampon' && (typesEtapesRetour[index + 1] === 'tampon' || typesEtapesRetour[index + 1] === '' || typesEtapes[index + 1] === null)) {
        value = false
      }
    })
    tempError[2] = value
    // console.log(tempError)
    setTypesEtapesError(tempError)
  }, [typesEtapes, typesEtapesRetour])

  useEffect(() => {
    if (allowGT) {
      if (!challengesSelected.find(e => e === '/api/challenges/1')) { setChallengesSelected([...challengesSelected, '/api/challenges/1']) }
    } else {
      setChallengesSelected(challengesSelected.filter(element => {
        return element !== '/api/challenges/1'
      }))
    }
  }, [allowGT])

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = waypoints[dragIndex]
      setWaypoint(
        update(waypoints, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      )
    },
    [waypoints]
  )
  const moveRetour = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = retourWaypoints[dragIndex]
      setRetourWaypoint(
        update(retourWaypoints, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      )
    },
    [retourWaypoints]
  )

  const renderCard = (card, index, type, move, supprimmer, legInfos) => {
    const numberToBase26 = (val, tail = '') => {
      if (val <= 26) {
        return `${String.fromCharCode(val + 64)}${tail}`
      }

      const remainder = val % 26 || 26
      const division = Math.trunc(val / 26) - (remainder === 26 ? 1 : 0)

      return numberToBase26(division, `${String.fromCharCode(remainder + 64)}${tail}`)
    }
    // console.log(card)
    return (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0.5rem' }}>
				<div style={{ marginRight: 15 }}>{numberToBase26(index + 1)}</div>
				<Card
					// key={card.id}
					type={type}
					index={index}
					// id={card.id}
					text={`${card.address} ${legInfos}`}
					moveCard={move}
					onClick={setPointModif}
					pointModif={pointModif}
					changeCoordinates={changeCoordinates}
				/>
				<FontAwesomeIcon size='2x' icon={faTimesCircle} className='fa-button close' onClick={() => {
				  supprimmer(index)
				  // console.log(index-1)
				  if (type === 'ALLER') {
				    const tempType = typesEtapes.filter((element, i) => i !== index - 1)
				    tempType.pop()
				    // console.log('temptType', tempType)
				    setTypesEtapes(tempType)
				  } else {
				    // let tempTypeRetour = typesEtapesRetour.filter((element, i) => i!=index-1);
				    const tempTypeRetour = typesEtapesRetour.filter((element, i) => i !== index - 1)
				    // tempTypeRetour.pop()
				    // console.log('temptType', tempTypeRetour)
				    setTypesEtapesRetour(tempTypeRetour)
				    /* setTypesEtapesRetour(typesEtapesRetour.map((element, i) => {
								//console.log(i!=index-1)
								if (i!=index-1) {
										//console.log('element', element)
										return element
								}
						})) */
				  }
				}}/>
			</div>
    )
  }

  // fin gestion dnd
  async function addCoordinates (location) {
    // console.log(location)
    if (
      typesEtapes.filter(element => element === 'chrono').length === 3 &&
			typesEtapes[typesEtapes.length - 1] === 'tampon') {
      return
    }

    await Geocode.fromLatLng(location.lat, location.lng).then(
      response => {
        const address = response.results[0].formatted_address
        // console.log('results', address)
        setWaypoint([...waypoints, {
          location,
          address,
          stopover: true
        }])
        let retourPoints = waypoints
        retourPoints = retourPoints.reverse()
        setRetourWaypoint([{
          location,
          address,
          stopover: true
        }, ...retourPoints])
      },
      error => {
        console.error(error)
      }
    )

    setNewPoint({ location })

    if (waypoints.length > 0) {
      if (typesEtapes.length === 0) {
        setTypesEtapes([...typesEtapes, 'chrono'])
        setTypesEtapesRetour([...typesEtapes, 'chrono'])
      } else if (typesEtapes.length === 1) {
        setTypesEtapes([...typesEtapes, 'tampon'])
        setTypesEtapesRetour([...typesEtapes, 'tampon'])
      } else {
        setTypesEtapes([...typesEtapes, ''])
        setTypesEtapesRetour([...typesEtapes, ''])
      }
    }
  }
  async function changeCoordinates (location, index, type) {
    // console.log(location)
    if (type === 'ALLER') {
      await Geocode.fromLatLng(location.lat, location.lng).then(
        response => {
          const address = response.results[0].formatted_address
          // console.log('results', address)
          const temp = waypoints.slice()
          temp[index] = {
            location,
            address,
            stopover: true
          }
          setWaypoint(temp)
          let retourPoints = waypoints
          retourPoints = retourPoints.reverse()
          setRetourWaypoint([{
            location,
            address,
            stopover: true
          }, ...retourPoints])
        },
        error => {
          console.error(error)
        }
      )

      setPointModif(null)
      setNewPoint({ location })
    } else {
      await Geocode.fromLatLng(location.lat, location.lng).then(
        response => {
          const address = response.results[0].formatted_address
          // console.log('results', address)
          const temp = retourWaypoints.slice()
          temp[index] = {
            location,
            address,
            stopover: true
          }
          setRetourWaypoint(temp)
        },
        error => {
          console.error(error)
        }
      )
      setPointModif(null)
      setNewPoint({ location })
    }
  }

  const handleTypeEtapeChange = (e, index, sens) => {
    if (sens === 'aller') {
      const temp = typesEtapes.slice()
      temp[index] = e.target.value
      setTypesEtapes(temp)
    } else {
      const temp = typesEtapesRetour.slice()
      temp[index] = e.target.value
      setTypesEtapesRetour(temp)
    }
  }

  if (waitingRequest > 0/* || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN') */) {
    return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
				<ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
			</div>
    )
  }

  const checkFields = () => {
    let result = true
    if (typesEtapesError.find(element => element === false) === false) { result = false }

    if (!minTime) {
      result = false
    }

    if (!maxTime) {
      result = false
    }

    return result
  }

  return (
		<>
			<Header currentMenuItem={2} entries={boEntries} location={location} match={match} />
			<div className='page-wrapper' style={{ paddingLeft: '2%', paddingRight: '2%' }}>
				<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '50px 0 0 0', paddingLeft: 100 }}>
					<h1 className='page-title'>Ajout</h1>
				</div>
				<div className="form-wrapper" style={{ padding: 50 }}>
					<input type='text' className="input-text" placeholder='Titre back-office' style={{ width: '45%', marginRight: 40, marginBottom: 20 }} value={title} onChange={e => setTitle(e.target.value)} />
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
						{/* <input type='text' className="input-text" placeholder='Titre du parcours' style={{width:'45%', marginRight:40}} value={title} onChange={(e) => {setTitle(e.target.value)}}></input> */}
						<input
							disabled
							type='text'
							className="input-text"
							placeholder='Code du parcours (auto)'
							style={{ width: '45%', marginRight: 40, color: 'gray' }}
							value={code}
						/>
						<input
							type='text'
							className="input-text"
							placeholder='Code pays'
							style={{ width: '45%', marginRight: 40 }}
							value={codePays}
							onChange={e => {
							  setCodePays(e.target.value)
							  let temp = code
							  temp = temp.split('-')
							  temp[1] = e.target.value
							  temp = temp.join('-')
							  setCode(temp)
							}}
						/>
						<input type="checkbox" id="Aller-retour" name="Aller-retour" checked={allowReturn} onClick={() => { setAllowReturn(!allowReturn) }} />
						<label className="mt-1 ml-2" htmlFor="Aller-retour" onClick={() => { setAllowReturn(!allowReturn) }} style={{ width: 115 }}>Aller-retour</label>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
						{challenges && (
								<div>
									<div style={{ marginBottom: 4, marginLeft: 4, color: 'gray', fontStyle: 'italic' }}>
										Seuls les challenges actifs s'affichent ci-dessous :
									</div>
									<MultiSelect
										selected={challengesSelected}
										setSelected={setChallengesSelected}
										// datas={challenges['hydra:member'].map(element => ({id:element['@id'], title: element.title}))}
										datas={activeChallenges}
									/>
								</div>
						)
						}
						<input
							style={{ marginLeft: 40 }}
							type="checkbox"
							id="allowGT"
							name="allowGT"
							checked={allowGT}
							onClick={e => { setAllowGT(e.target.checked) }}
							disabled
						/>
						<label
							className="ml-2"
							style={{ color: 'gray' }}
							htmlFor="allowGT"
						>
							Parcours GranTrofeo (obsolète)
						</label>
					</div>

					{allowReturn && <div style={{ marginTop: 20 }}>
						<span>Affichage de la carte</span>
						<label htmlFor="aller">
							<input
								style={{ marginRight: 4 }}
								type="radio"
								id="aller"
								name="display"
								value="aller"
								checked={!displayRetour}
								onClick={() => { setDisplayRetour(false) }}
							/>
							Aller
						</label>
						<label htmlFor="retour">
							<input
								style={{ marginRight: 4 }}
								type="radio"
								id="retour"
								name="display"
								value="retour"
								checked={displayRetour}
								onClick={() => { setDisplayRetour(true) }}
							/>
							Retour
						</label>
					</div>}
					<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 20 }}>
						<div style={{ height: 500, width: '100%', marginRight: 20, marginBottom: 20, position: 'relative' }}>
							<MapWithDestination
								googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD5uips6tDA-6IlaUjNGSq2hyv0DMl19eQ"
								loadingElement={<div style={{ height: '100%' }} />}
								containerElement={<div style={{ height: '500px', width: '100%' }} />}
								mapElement={<div style={{ height: '100%' }} />}
								setDirections={(direction) => { setDirections(direction) }}
								directions={directions}
								newPoint={newPoint}
								setNewPoint={setNewPoint}
								waypoints={waypoints}
								setWaypoint={setWaypoint}
								retourWaypoints={retourWaypoints}
								setRetourWaypoint={setRetourWaypoint}
								retour={retour}
								setRetour={setRetour}
								setRefAller={setRefAller}
								displayRetour={displayRetour}
								pointModif={pointModif}
								setPointModif={setPointModif}
								typesEtapes={typesEtapes}
								setTypesEtapes={setTypesEtapes}
								setTypesEtapesRetour={setTypesEtapesRetour} />
						</div>
						<div style={{ marginBottom: 20 }}>
							<p>Pour être valide votre parcours doit :</p>
							<p style={{ color: typesEtapesError[0] ? 'green' : 'red' }}>- être composé de trois sections étapes</p>
							<p style={{ color: typesEtapesError[1] ? 'green' : 'red' }}>- se terminer par une zone tampon</p>
							<p style={{ color: typesEtapesError[2] ? 'green' : 'red' }}>- Les zones tampons sont obligatoirement suivies d'étapes</p>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							<div style={{ width: '48%' }}>
								<p style={{ fontSize: 20, marginBottom: 15 }}>Aller</p>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<DndProvider backend={HTML5Backend}>
										<div style={{ width: 400 }}>
											{waypoints
											  .filter(element => element.stopover)
											  .map((waypoint, i) => {
											    if (waypoint.stopover) {
											      return renderCard(
											        waypoint,
											        i,
											        'ALLER',
											        moveCard,
											        (i) => {
											          const temp = waypoints.filter((element, index) => index !== i)
											          setWaypoint(temp)
											        },
											        i !== 0 && directions && directions.routes[0].legs[i - 1]
											          ? `${directions.routes[0].legs[i - 1].distance.text}, ${directions.routes[0].legs[i - 1].duration.text}`
											          : ''
											      )
											    }
											    return null
											  })}
										</div>
									</DndProvider>
									<div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, marginBottom: 40, justifyContent: 'space-between' }}>
										{typesEtapes.map((element, index) => {
										  // console.log(index, directions.routes[0].legs[index] ? directions.routes[0].legs[index].distance.text : '')
										  if (index > 1) {
										    return (
													<select
														className='button button-shadow etape'
														style={{ height: 'fit-content', width: 100, marginLeft: 8 }}
														value={element}
														onChange={e => { handleTypeEtapeChange(e, index, 'aller') }}
														name="type"
														id="type-select"
													>
														<option value="">Choisir un type de zone</option>
														<option value="chrono">chrono</option>
														<option value="tampon">tampon</option>
													</select>
										    )
										  } else {
										    return (
													<p
														className='button button-shadow'
														style={{ height: 'fit-content', width: 100, marginLeft: 8 }}
													>
														{element}
													</p>
										    )
										  }
										})}
									</div>
								</div>
							</div>
							{allowReturn && (
								<div style={{ width: '48%' }}>
									<p style={{ fontSize: 20, marginBottom: 15 }}>Retour</p>
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										<DndProvider backend={HTML5Backend}>
											<div style={{ width: 400 }}>
												{retourWaypoints
												  .filter(element => element.stopover)
												  .map((retourWaypoint, i) => {
												    if (retourWaypoint.stopover) {
												      return renderCard(
												        retourWaypoint,
												        i,
												        'RETOUR',
												        moveRetour,
												        (i) => {
												          const temp = retourWaypoints.filter((element, index) => index !== i)
												          setRetourWaypoint(temp)
												        },
												        i !== 0 && retour && retour.routes[0].legs[i - 1]
												          ? `${retour.routes[0].legs[i - 1].distance.text}, ${retour.routes[0].legs[i - 1].duration.text}`
												          : ''
												      )
												    }
												    return null
												  })}
											</div>
										</DndProvider>
										<div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, marginBottom: 40, justifyContent: 'space-between' }}>
											{typesEtapesRetour.map((element, index) => {
											  if (index > 1) {
											    return (
														<select
															className='button button-shadow'
															style={{ height: 'fit-content', width: 100, marginLeft: 8 }}
															value={element}
															onChange={e => { handleTypeEtapeChange(e, index, 'retour') }}
															name="type"
															id="type-select"
														>
															<option value="">Choisir un type de zone</option>
															<option value="chrono">chrono</option>
															<option value="tampon">tampon</option>
														</select>
											    )
											  } else {
											    return (
														<p
															className='button button-shadow'
															style={{ height: 'fit-content', width: 100, marginLeft: 8 }}
														>
															{element}
														</p>
											    )
											  }
											})}
										</div>
									</div>
								</div>
							)}
						</div>
						{/** Champ de recherche gmaps */}
						{waypoints.length < 7 && (
							<div style={{
							  padding: 10,
							  alignSelf: 'left',
							  width: '50%',
							  border: 'solid',
							  borderWidth: 2,
							  borderColor: 'gray',
							  borderRadius: 8,
							  boxShadow: '1px 2px 9px #00000'
							}}>
									<div>
										<div style={{ marginLeft: 4, marginBottom: 6, fontWeight: 'bold' }}>
											Définissez une nouvelle étape :
										</div>
										<PlaceSearch addCoordinates={addCoordinates} />
									</div>
							</div>
						)}
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<div className='container-column-half'>

							<label>Distance :
								<input value={`${distance} Km`} onChange={e => { setDistance(e.target.value) }} type='text' className="input-text" placeholder='Distance *' />
							</label>
							<label>Temps moyen :
								<input value={averageTime} type='text' className="input-text" placeholder='Temps *'/>
							</label>
							<label>Temps moyen min :
								<input value={minTime} onChange={e => { setMinTime(e.target.value) }} type='text' className="input-text" placeholder='Temps moyen min *'/>
							</label>
							<label>Temps moyen max :
								<input value={maxTime} onChange={e => { setMaxTime(e.target.value) }} type='text' className="input-text" placeholder='Temps moyen max *'/>
							</label>
						</div>
						{allowReturn && <div className='container-column-half'>
							{/* <div style={{display:'flex', flexDirection:'row'}}>
                        <input type='text' className="input-text" placeholder='Titre départ' style={{width:'45%', marginRight:20, marginTop:20}}></input>
                </div> */}
							<label>Distance *
								<input value={`${distanceRetour} Km`} type='text' className="input-text" placeholder='Distance *'/>
							</label>
							<label>Temps moyen *
								<input value={averageTimeRetour} type='text' className="input-text" placeholder='Temps *'/>
							</label>
							<label>Temps moyen min :
								<input value={minTimeRetour} onChange={e => setMinTimeRetour(e.target.value)} type='text' className="input-text" placeholder='Temps moyen min *'/>
							</label>
							<label>Temps moyen max :
								<input value={maxTimeRetour} onChange={e => setMaxTimeRetour(e.target.value)} type='text' className="input-text" placeholder='Temps moyen max *'/>
							</label>
						</div>}
					</div>

					<div style={{ display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end' }}>
						<input type='button' className='button button-shadow' value='Francais' style={{ backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white' }} onClick={() => setIsLanguageFr(true)}/>
						<input type='button' className='button button-shadow' value='Anglais' style={{ backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white' }} onClick={() => setIsLanguageFr(false)}/>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flexWrap: 'wrap', margin: '0 0%', padding: '20px 0px' }}>
						{isLanguageFr && (
							<>
								<div style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 10 }}>Description du parcours(FR)</div>
								<CKEditor
									editor={ ClassicEditor }
									data={description}
									onInit={ editor => {
									  // You can store the "editor" and use when it is needed.
									  console.log('Editor is ready to use!', editor)
									} }
									onChange={ (event, editor) => {
									  const data = editor.getData()
									  // setBody(data);
									  setDescription(data)
									  console.log({ event, editor, data })
									} }
									onBlur={ (event, editor) => {
									  console.log('Blur.', editor)
									} }
									onFocus={ (event, editor) => {
									  console.log('Focus.', editor)
									} }
								/>
							</>
						)}
						{!isLanguageFr && (
							<>
								<div style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 10 }}>Description for parcours(EN)</div>
								<CKEditor
									editor={ ClassicEditor }
									data={descriptionEn}
									onInit={ editor => {
									  // You can store the "editor" and use when it is needed.
									  console.log('Editor is ready to use!', editor)
									} }
									onChange={ (event, editor) => {
									  const data = editor.getData()
									  // setBody(data);
									  setDescriptionEn(data)
									  console.log({ event, editor, data })
									} }
									onBlur={ (event, editor) => {
									  console.log('Blur.', editor)
									} }
									onFocus={ (event, editor) => {
									  console.log('Focus.', editor)
									} }
								/>
							</>
						)}

					</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<div style={{ display: 'inline' }}>

							<label>
								<input className="mr-2" type='checkbox' onChange={(e) => { setIsShow(!e.target.checked) }} checked={isShow !== true}/>
								Ne pas afficher le parcours dans l'appli
							</label>
						</div>
						<div style={{ display: 'inline' }}>
							{/* <input type='button' className='button button-shadow' value='Annuler' style={{marginRight:20}} /> */}
							<input
								type='button'
								className='button button-shadow'
								value={checkFields() ? 'Enregistrer' : 'Certaines conditions ne sont pas remplies pour enregistrer le parcours'}
								onClick={() => {
								  if (checkFields()) {
								    // axios.get(`${process.env.REACT_APP_API_URL}/api/course/last`, {withCredentials:true})
								    // .then(response => {
								    const lastCode = code
								    console.log({ lastCode })
								    post(
								      title,
								      lastCode,
								      allowReturn.toString(),
								      titleDepartArrive,
								      allowGT.toString(),
								      distance,
								      averageTime,
								      minTime,
								      maxTime,
								      directions.routes[0].legs[0].start_address,
								      directions.routes[0].legs[directions.routes[0].legs.length - 1].end_address,
								      status,
								      challengesSelected,
								      description,
								      descriptionEn,
													`${lastCode}.json`,
													distanceRetour,
													averageTimeRetour,
													minTimeRetour,
													maxTimeRetour,
													typesEtapes.join(),
													typesEtapesRetour.join(),
													typeof (waypoints[0].location.lng) === 'function' ? waypoints[0].location.lng().toString() : waypoints[0].location.lng.toString(),
													typeof (waypoints[0].location.lat) === 'function' ? waypoints[0].location.lat().toString() : waypoints[0].location.lat.toString(),
													isShow
								    )
								    uploadFormData(upload, 'file', directions, `${lastCode}.json`)
								    uploadFormData(upload, 'file', retour, `${lastCode}-retour.json`)
								    // })
								  }
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
  )
}

// == Export
export default ParcourAjout
