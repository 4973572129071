// == Import : npm
import React, { useState } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';


  // == Import : local
  import '../../style/style.scss';

  import Header from '../../containers/Header';
  import ImageSelector from '../../containers/ImageSelector';

  import { boEntries } from '../../utils/headerEntries.js';


  registerLocale('fr', fr)


// == Composant
const SlidesAjout = ({ location, match, data, waitingRequest, post, upload, lastUploadedImage, connected }) => {

    const [titre, setTitre] = useState(null)
    const [content, setContent] = useState(null)
    const [pickImage, setPickImage] = useState(false) // eslint-disable-line no-unused-vars

    const [imageUrl, setImageUrl] = useState([null]);

    // state translate languege
    const [isLanguageFr, setIsLanguageFr] = useState(true);
    const [titleEn, setTitleEn] = useState('');
    const [contentEn, setContentEn] = useState('');

    return (
    <>
    <Header currentMenuItem={18} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Slides</h1>
        </div>
        <div className="form-wrapper">
            <div style={{display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end'}}>
               <input type='button' className='button button-shadow' value='Francais' style={{backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white'}} onClick={() => setIsLanguageFr(true)}/>
               <input type='button' className='button button-shadow' value='Anglais' style={{backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white'}} onClick={() => setIsLanguageFr(false)}/>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                {isLanguageFr && <input value={titre} onChange={(e) => {setTitre(e.target.value)}} type='text' className="input-text" placeholder='Titre(Fr) *' style={{width:'45%'}}/>}
                {!isLanguageFr && <input value={titleEn} onChange={(e) => {setTitleEn(e.target.value)}} type='text' className="input-text" placeholder='Title(En) *' style={{width:'45%'}}/>}

            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'column', justifyContent:"space-between"}}>
                <p>Visuel de la slide : </p>
                <ImageSelector imageUrl={imageUrl} setImageUrl={setImageUrl} deactivateWaitingRequest={setPickImage} />
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px'}}>
                {isLanguageFr && (
                    <>
                        <span style={{ marginBottom: '5px' }}>Description(Fr) : </span>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={content}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setContent(data);
                                console.log( { event, editor } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </>
                )}
                {!isLanguageFr && (
                    <>
                        <span style={{ marginBottom: '5px' }}>Description(En) : </span>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={contentEn}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setContentEn(data);
                                console.log( { event, editor } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </>
                )}

            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', margin: '0 50px'}}>
                <button className='button button-shadow' onClick={() => {post(titre, imageUrl, content, titleEn, contentEn)}}>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default SlidesAjout;
