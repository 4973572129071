// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Faq from '../../screen/Faq';

// Action Creators
import { getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.faqs,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAll: ( page) => {
    dispatch(getAll('faqs', page, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Faq);