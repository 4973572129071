// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ProduitsAjout from '../../screen/ProduitsAjout';

// Action Creators
import { post, getOne, upload } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  settings: state.onesettings,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  post: (title='', description='null', cost=0, wheels=0) => {
    dispatch(post('products', {title, description, cost : parseInt(cost), wheels : parseInt(wheels)}, (data) => {ownProps.history.push(`/produits/edit/${data.id}`)}));
  },
  getOne: () => {
    dispatch(getOne('settings', 1));
  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProduitsAjout);