export function timeManipulation(way, settings){
  if (settings.length === 0 || way.length === 0)
    return null

  if (way.legs === undefined)
    return null

  let total = 0
  let time = 0

  way.legs.forEach(leg => {
    total += leg.distance.value
    time += leg.duration.value
  })

  let calculatedMinTime = (total/1000) / settings.maxAvgSpeed
  let calculatedMaxTime = (total/1000) / settings.minAvgSpeed
  const calculatedAverageTime = `${Math.floor(time/3600)} H ${Math.floor(((time/3600)-Math.floor(time/3600))*60)} min`
  const totalDistance = Number.parseFloat(total/1000).toFixed(2)

  calculatedMinTime = `${Math.floor(calculatedMinTime)} H ${Math.floor((calculatedMinTime - Math.floor(calculatedMinTime))*60)} min`
  calculatedMaxTime = `${Math.floor(calculatedMaxTime)} H ${Math.floor((calculatedMaxTime - Math.floor(calculatedMaxTime))*60)} min`


  return {
    calculatedMinTime: calculatedMinTime,
    calculatedMaxTime: calculatedMaxTime,
    calculatedAverageTime: calculatedAverageTime,
    totalDistance: totalDistance
  }
}
