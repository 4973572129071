// == Import : npm
import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';


  registerLocale('fr', fr)


// == Composant
const ProduitsAjout = ({ location, match, data, settings, waitingRequest, post, getOne, upload, lastUploadedImage, connected }) => {

    const [titre, setTitre] = useState(null)
    const [cost, setCost] = useState(null)
    const [wheels, setWheels] = useState(null)
    const [volantEuro, setVolantEuro] = useState(null)

    // Gets the global settings
    useEffect(() => {
        getOne(1)
    }, []) //eslint-disable-line

    useEffect(() => {
        if(settings) {
            setVolantEuro(settings.wheelsPerEuro)
        }
    }, [settings])


    return (
    <>
    <Header currentMenuItem={7} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Produits</h1>
        </div>
        <div className="form-wrapper">
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <input value={titre} onChange={(e) => {setTitre(e.target.value)}} type='text' className="input-text" placeholder='Titre *' style={{width:'100%'}}/>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <input value={cost} onChange={(e) => {setCost(e.target.value); setWheels(Math.floor(e.target.value * volantEuro));  setTitre(`Pack de ${e.target.value * volantEuro} Volants`)}} type='number' min='0' className="input-text" placeholder='Coût *' style={{width:'35%'}}/><span className='devise'>€</span>
                <input value={wheels} onChange={(e) => {setWheels(e.target.value); setTitre(`Pack de ${e.target.value} Volants`)}} type='number' step='100' min='0' className="input-text" placeholder='Volants *' style={{width:'45%'}}/>
            </div>

           <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', margin: '0 15%'}}>
                <button className='button button-shadow' onClick={() => {post(titre, 'null_description', cost*100, wheels)}}>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default ProduitsAjout;
