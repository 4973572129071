// == Import : npm
import React, {useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';


// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import ImageSelector from '../../containers/ImageSelector';

import { boEntries } from '../../utils/headerEntries.js';


registerLocale('fr', fr)


// == Composant
const NewsAjout = ({ location, match, data, waitingRequest, post, upload, lastUploadedImage, connected }) => {
	const initDate = new Date();
	const initDateLessOneMinute = new Date(Date.now() - 60000);
	const [date1, setDate1] = useState(initDate);
	const [date2, setDate2] = useState(initDateLessOneMinute);

	const [titre, setTitre] = useState('')
	const [content, setContent] = useState('')
	const [contentEn, setContentEn] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [pickImage, setPickImage] = useState(false)

	const [imageUrl, setImageUrl] = useState([null]);
	const [imageUrlEn, setImageUrlEn] = useState([null]);

	// state translate language
	const [isLanguageFr, setIsLanguageFr] = useState(true);
	const [titleEn, setTitleEn] = useState('');

	return (
		<>
			<Header currentMenuItem={15} entries={boEntries} location={location} match={match} />
			<div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
				<div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
					<h1 className='page-title'>News</h1>
				</div>
				<div className="form-wrapper">
					<div style={{display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end'}}>
						<input type='button' className='button button-shadow' value='Francais' style={{backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white'}} onClick={() => setIsLanguageFr(true)}/>
						<input type='button' className='button button-shadow' value='Anglais' style={{backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white'}} onClick={() => setIsLanguageFr(false)}/>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
						{isLanguageFr && <input value={titre} onChange={(e) => {setTitre(e.target.value)}} type='text' className="input-text" placeholder='Titre(FR) *' style={{width:'45%'}}/>}
						{!isLanguageFr && <input value={titleEn} onChange={(e) => {setTitleEn(e.target.value)}} type='text' className="input-text" placeholder='Titre(EN) *' style={{width:'45%'}}/>}
						<div>
							<span style={{marginRight:15, fontSize:15}}>Date de publication : </span>
							<DatePicker
								className='button date-picker'
								selected={date1}
								onChange={(e) => setDate1(e)}
								locale="fr"
								dateFormat='dd/MM/yyyy'
							/>
						</div>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
						<span style={{marginRight:15, fontSize:15}}>Date de programmation : </span>
						<DatePicker
							className='button date-picker'
							selected={date2}
							onChange={(e) => setDate2(new Date(e.setMinutes(e.getMinutes() - 1)))}
							locale="fr"
							dateFormat='dd/MM/yyyy'
						/>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'column', justifyContent:"space-between"}}>
						<p>Visuel de la news : </p>
						{isLanguageFr ?
							<div id='fr'>
								{imageUrl.map((element, index) => {
									return (
										<div key={index} style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
											<div style={{width:'70%'}}>(FR)
												<ImageSelector
													imageUrl={element}
													setImageUrl={(e) => {
														const temp = imageUrl.slice();
														temp[index] = e;
														setImageUrl(temp)
													}}
													deactivateWaitingRequest={setPickImage}
												/>
											</div>
											<FontAwesomeIcon
												onClick={() => {setImageUrl(imageUrl.filter((e, i) => i!==index))}}
												size='2x'
												icon={faTimesCircle}
												className='fa-button plus'
												style={{}}
											/>
										</div>
									)
								})}
								<FontAwesomeIcon
									onClick={() => {setImageUrl([...imageUrl, null])}}
									size='2x' icon={faPlusCircle}
									className='fa-button plus' style={{marginTop: 30}}
								/>
							</div>
							:
							<div id='en'>
								{imageUrlEn.map((element, index) => {
									return (
										<div key={index} style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
											<div style={{width:'70%'}}>(EN)
												<ImageSelector
													imageUrl={element}
													setImageUrl={(e) => {
														const temp = imageUrlEn.slice();
														temp[index] = e;
														setImageUrlEn(temp)}
													}
													deactivateWaitingRequest={setPickImage}
												/>
											</div>
											<FontAwesomeIcon
												onClick={() => {setImageUrlEn(imageUrlEn.filter((e, i) => i!==index))}}
												size='2x'
												icon={faTimesCircle}
												className='fa-button plus'
												style={{}}
											/>
										</div>
									)
								})}
								<FontAwesomeIcon
									onClick={() => {setImageUrlEn([...imageUrlEn, null])}}
									size='2x'
									icon={faPlusCircle}
									className='fa-button plus'
									style={{marginTop: 30}}/>
							</div>
						}
					</div>
					<div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px'}}>
						{isLanguageFr && (
							<>
								<span style={{marginBottom: "5px"}}>Description(FR) : </span>
								<CKEditor
									editor={ ClassicEditor }
									data={content}
									onInit={ editor => {
										// You can store the "editor" and use when it is needed.
										console.log( 'Editor is ready to use!', editor );
									} }
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										setContent(data);
										console.log( { event, editor } );
									} }
									onBlur={ ( event, editor ) => {
										console.log( 'Blur.', editor );
									} }
									onFocus={ ( event, editor ) => {
										console.log( 'Focus.', editor );
									} }
								/>
							</>
						)}
						{!isLanguageFr && (
							<>
								<span style={{marginBottom: "5px"}}>Description(EN) : </span>
								<CKEditor
									editor={ ClassicEditor }
									data={contentEn}
									onInit={ editor => {
										// You can store the "editor" and use when it is needed.
										console.log( 'Editor is ready to use!', editor );
									} }
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										setContentEn(data);
										console.log( { event, editor } );
									} }
									onBlur={ ( event, editor ) => {
										console.log( 'Blur.', editor );
									} }
									onFocus={ ( event, editor ) => {
										console.log( 'Focus.', editor );
									} }
								/>
							</>
						)}
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', margin: '0 50px'}}>
						<button
							className='button button-shadow'
							onClick={() => {
								post(titre, date1 ,content, imageUrl.filter(element => element!=null), date2, [{title : titleEn, content: contentEn, locale: 'en',images: (imageUrlEn[0] == null ? imageUrl.filter(element => element!=null) : imageUrlEn.filter(element => element!=null) ) }])
							}}>
							Valider
						</button>
					</div>
				</div>
			</div>
		</>
	)
};

// == Export
export default NewsAjout;
