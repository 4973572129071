// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Configuration from '../../screen/Configuration';

// Action Creators
import { patch, getOne } from '../../store/reducer';

import axios from 'axios';


const mapStateToProps = (state, ownProps) => ({
  data: state.onesettings,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  /*setMessageNotif:(message, isGood) => {
    dispatch(setMessageNotif(message, isGood))
  },*/
  patch: (duelDefaultCost='', tryAgainDefaultCost = '', gtDefaultCost= '', wheelsPerEuro='', teamNumberCost ='', defaultCost = '', tourismCost = '', trainingCost =  '', minAvgSpeed = '', maxAvgSpeed = '') => {
    dispatch(patch('settings', 1, {
      duelDefaultCost: parseInt(duelDefaultCost),
      tryAgainDefaultCost : parseInt(tryAgainDefaultCost),
      gtDefaultCost : parseInt(gtDefaultCost),
      wheelsPerEuro : parseInt(wheelsPerEuro),
      teamNumberCost : parseInt(teamNumberCost),
      challengeDefaultCost : parseInt(defaultCost),
      tourismCost: parseInt(tourismCost),
      trainingCost: parseInt(trainingCost),
      minAvgSpeed : typeof minAvgSpeed == "string" ? parseFloat(minAvgSpeed.replace(',','.')) : minAvgSpeed,
      maxAvgSpeed : typeof maxAvgSpeed == "string" ? parseFloat(maxAvgSpeed.replace(',','.')) : maxAvgSpeed }, () => {
      axios(
        {
          method: 'PUT',
          url:`${process.env.REACT_APP_API_URL}/api/settings/general`,
          headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
            'Content-Type' : 'application/json',
          },
          withCredentials: true,
          data: {
            "maxAvgSpeed" : typeof maxAvgSpeed == "string" ? parseFloat(maxAvgSpeed.replace(',','.')) : maxAvgSpeed,
            "minAvgSpeed" : typeof minAvgSpeed == "string" ? parseFloat(minAvgSpeed.replace(',','.')) : minAvgSpeed,
            "duelDefaultCost": parseInt(duelDefaultCost),
            "tryAgainDefaultCost" : parseInt(tryAgainDefaultCost),
            "gtDefaultCost" : parseInt(gtDefaultCost),
            "challengeDefaultCost" : parseInt(defaultCost),
            "tourismCost": parseInt(tourismCost),
            "trainingCost": parseInt(trainingCost),
          }
        },
      )
      .then(res => {
        console.log(res);
      })
      .catch((error) => {
        if(error.response) {
          // store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response}`, false))
          console.log(error.response);
        } else {
          console.log(error);
        }
      })
      }
      ));
  },
  getOne: (id) => {
    dispatch(getOne('settings', id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Configuration);
