// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Dotations from '../../screen/Dotations';

// Action Creators
import {getList} from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.rewards,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getList: (page) => {
    dispatch(getList('rewards', page));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dotations);
