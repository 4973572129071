// == Import : npm
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
// == Import : local
import '../../style/style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMedal, faCar, faSearch, faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import Header from '../../containers/Header';
import Listing from '../../containers/Listing';

import { boEntries } from '../../utils/headerEntries.js';
import moment from 'moment';
import { useState } from 'react';
import Axios from 'axios';
import {getCountryList} from "../../utils/countryList";
// == Composant
const Utilisateurs = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected }) => {
  const [statusParticipated, setStatusParticipated] = useState('');
  const [statusUser, setStatusUser] = useState('');
  const [statusBoughtWheels, setStatusBoughtWheels] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [nationality, setNationality] = useState('');
  const [elements, setElements] = useState('');
  const [allCostTotal, setAllCostTotal] = useState([{}]);
  const [informationAgree, setInformationAgree] = useState("");
  const [newsletter,setNewsletter] = useState("");
  const [search, setSearch] = useState(null);
  const [urlFilterOrder, setUrlFilterOrder] = useState('');
  const [roles, setRoles] = useState(null);

  const history = useHistory();
  const [orders, setOrders] = useState(
    { teamNumber: '', balance: '', firstname: '', lastname: '', email: '', url:'' }
    );

  const getBaseCostTotal = (type) => {
    Axios.get(`${process.env.REACT_APP_API_URL}/api/receipt/baseCost/${type}`, { withCredentials: true })
      .then((response) => {
        setAllCostTotal(response.data.result);
      })
    .catch(function (error) {
        console.log(error);
     });
    //
  }

  const getUsers = () => {
    history.replace({ pathname: `/utilisateurs/1`});
    let url = urlFilter();
    getAll(1,url);
  }

  const urlFilter = () => {
    let url ='';
    if(statusParticipated !== '' && statusParticipated !== undefined)
      url += `&statusParticipated=${statusParticipated}`;

    if(statusBoughtWheels !== '')
      url += `&statusBoughtWheels=${statusBoughtWheels}`;

    if(nationality !== '')
      url += `&nationality=${nationality}`;

    if(statusUser !== '')
      url += `&status=${statusUser}`;
    if(informationAgree !== '')
      url += `&informationAgree=${informationAgree === "checked" ? 1 : 0}`;

    if(newsletter !== "" )
      url += `&newsletter=${newsletter === "checked" ? "true" : "false"}`;

    if(search != null){
      url += `&firstnameLastnameEmail=${search}`
    }

    if(roles != null){
      url += `&roles=${roles}`
    }

    if(urlFilterOrder !== '') {
      url += `${urlFilterOrder}`
    }

    return url;
  }

  const searchUser = () => {
    let urlSearch ='';
    if(search !== "") {
      urlSearch = `${urlFilter()}`;
    }
    history.replace({ pathname: `/utilisateurs/1`});
    getAll(1,urlSearch);
  }

  const reOrder = (type) => {
    let ord = { ...orders };
    switch (type) {
      case 'Stock de volants':
        if (ord.balance === 'asc') {
            ord.balance = 'desc';
            ord.teamNumber = '';
            ord.firstname = '';
            ord.lastname = '';
            ord.email = '';
            ord.url = "&order[balance]=desc";
        } else {
          ord.balance = 'asc';
          ord.teamNumber = '';
          ord.firstname = '';
          ord.lastname = '';
          ord.email = '';
          ord.url = "&order[balance]=asc";
        }
        break;
      case 'prénom':
        if (ord.firstname === 'asc') {
          ord.firstname = 'desc';
          ord.teamNumber = '';
          ord.balance = '';
          ord.lastname = '';
          ord.email = '';
          ord.url = "&order[firstname]=desc";
        } else {
          ord.firstname = 'asc';
          ord.teamNumber = '';
          ord.balance = '';
          ord.lastname = '';
          ord.email = '';
          ord.url = "&order[firstname]=asc";
        }
        break;
      case 'email':
        if (ord.email === 'asc') {
          ord.email = 'desc';
          ord.teamNumber = '';
          ord.balance = '';
          ord.lastname = '';
          ord.firstname = '';
          ord.url = "&order[email]=desc";
        } else {
          ord.email = 'asc';
          ord.teamNumber = '';
          ord.balance = '';
          ord.lastname = '';
          ord.firstname = '';
          ord.url = "&order[email]=asc";
        }
        break;
      case 'nom':
          if (ord.lastname === 'asc') {
            ord.lastname = 'desc';
            ord.teamNumber = '';
            ord.balance = '';
            ord.email = '';
            ord.firstname = '';
            ord.url = "&order[lastname]=desc";
          } else {
            ord.lastname = 'asc';
            ord.teamNumber = '';
            ord.balance = '';
            ord.email = '';
            ord.firstname = '';
            ord.url = "&order[lastname]=asc";
          }
        break;
      case 'team':
        if (ord.teamNumber === 'asc') {
          ord.teamNumber = 'desc';
          ord.lastname = '';
          ord.balance = '';
          ord.email = '';
          ord.firstname = '';
          ord.url = "&order[team.number]=desc";
        } else {
          ord.teamNumber = 'asc';
          ord.lastname = '';
          ord.balance = '';
          ord.email = '';
          ord.firstname = '';
          ord.url = "&order[team.number]=asc";
        }
        break;
      default:
        break;
    }
    setUrlFilterOrder(ord.url);
    setOrders(ord);
  };

  const statutUser = (statut) => {
      switch(statut) {
        case 'valid':
          return <span style={{fontSize:13}} className="badge badge-success">Valide</span>;
        case 'disabled':
          return <span style={{fontSize:13}} className="badge badge-warning">Désactivé</span>;
        case 'deleted':
          return <span style={{fontSize:13}} className="badge badge-danger">Supprimé</span>;
        case 'activationPending':
          return <span style={{fontSize:13}} className="badge badge-secondary">En attente d'activation</span>;
        default:
          return ''
      }
  }

  useEffect(() => {
    const url = urlFilter();
    getAll(match.params.page,url);
  }, [match.params.page, urlFilterOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(datas){
      setElements(datas['hydra:totalItems']);
    }
  }, [match.params.page, datas]);

  useEffect(() => {
    setCountryList(getCountryList());
    getBaseCostTotal("wheels")
  }, []);



  if (waitingRequest > 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
        <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
      </div>
    );
  }

  const reset = () => {
    window.location = '/utilisateurs/1';
  }

  return (
    <>
      <Header currentMenuItem={1} entries={boEntries} location={location} match={match} />
      <div className="page-wrapper" style={{ paddingLeft: '5%', paddingRight: '5%', width:'100vw'}}>
        <div style={{
          width:'100%', display: 'flex', justifyContent: 'space-between',
          margin: '50px 0% 0 0', paddingLeft: 20
        }}>
          <h1 className="page-title">Utilisateurs</h1>
          <div className='row-md-3' style={{marginTop: -6}}>
            <button
              className=' button button-shadow'
              style={{backgroundColor:'#AB3217', color:'white', width:150, marginLeft: "5rem", height: 'fit-content'}}
              onClick={() => {getUsers()}}
            >Filtrer</button>
            <button
              className=' button button-shadow'
              style={{
                backgroundColor:'#AB3217', color:'white', width:150,
                marginLeft: "3rem", marginRight: '5rem', height: 'fit-content'
              }}
              onClick={() => {reset()}}
            >Réinitialiser</button>
          </div>
        </div>
        <div className="row" style={{height: 'fit-content', alignItems:'center', marginBottom: 6}}>
          <div className="col-md-3">
            <select
              type="text"
              className="input-text"
              value={statusParticipated}
              onChange={(e) => setStatusParticipated(e.target.value)}
            >
              <option value="">Participation à un parcours</option>
              <option value="yes">Oui</option>
              <option value="no">Non</option>
            </select>
          </div>
          <div className="col-md-3">
            <select
              type="text"
              className="input-text"
              value={statusUser}
              onChange={(e) => setStatusUser(e.target.value)}
            >
              <option value="">Statut utilisateur</option>
              <option value="valid">Valide</option>
              <option value="disabled">Désactivé</option>
              <option value="deleted">Supprimé</option>
              <option value="activationPending">En attente d'activation</option>
            </select>
          </div>
          <div className="col-md-3">
            <select
              type="text"
              className="input-text"
              value={statusBoughtWheels}
              onChange={(e) => setStatusBoughtWheels(e.target.value)}
            >
              <option value="">Statut d’achat de volants</option>
              <option value="yes">Oui</option>
              <option value="no">Non</option>
            </select>
          </div>
          <div>
            <Link
              to="/utilisateurs/ajout"
              className="ml-4 button button-shadow"
              style={{ backgroundColor: '#AB3217', color: 'white', padding: '10px 30px'}}
            >Créer</Link>
            <a
              href={`${process.env.REACT_APP_API_URL}/api/user/export/csv`}
              className="ml-4 button button-shadow "
            >Export CSV</a>
          </div>

        </div>
        <div className='row' style={{height: 'fit-content', alignItems:'center', marginBottom: 6}}>
          <div className='col-md-3 ml-2'>
              <input
                className='input-text'
                value={search}
                type='text'
                onChange={(e) => {setSearch(e.target.value);}}
                placeholder='nom / prénom / email'
              />
          </div>
          <div style={{marginTop: "1.02rem"}}>
            <FontAwesomeIcon
              onClick={() => {searchUser()}} size='xs' icon={faSearch} className='fa-button plus'
            />
          </div>
          <div className='col-md-3 mt-2 ml-5 text-left'>
              J'accepte que mes coordonnées soient transmises&nbsp;
              <select
                type="text"
                value={informationAgree}
                className="input-text"
                onChange={(e) => {setInformationAgree(e.target.value);}}
              >
                <option value=""/>
                <option value="checked">Coché</option>
                <option value="unChecked">Décoché</option>
              </select>
              Toystories
              <select type="text" className="input-text" value={newsletter} onChange={(e) => {setNewsletter(e.target.value);}}>
                <option value=""/>
                <option value="checked">Coché</option>
                <option value="unChecked">Décoché</option>
              </select>
          </div>
          <div className='col-md-3 mt-3 ml-3 text-left'>
              Sélection de rôles
              <select
                type="text"
                value={roles}
                className="input-text"
                onChange={(e) => {setRoles(e.target.value);}}
              >
                <option value=""/>
                <option value="ROLE_ADMIN">Admin</option>
                <option value="ROLE_USER">User</option>
                <option value="ROLE_PARCOURS">Parcours</option>
                <option value="ROLE_COMMUNICATION">Communication</option>
              </select>
              <div className="mt-2">
                Sélection de pays
                <select
                  type="text"
                  className="input-text"
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                >
                  <option value="" disabled>
                    Pays
                  </option>
                  {countryList.map((item, idx)=> <option key={idx} value={item.key}>{item.label}</option>)}
                </select>
            </div>
          </div>
        </div>
        <div className='mb-2 mt-2 ml-2 row'>
          Résultats : {elements} utilisateurs
        </div>
        <br />
        {datas && (
          <Listing
            actionUpdate={"Editer"}
            dataType={'users'}
            type={'utilisateurs'}
            match={match}
            datas={datas}
            orderFunction={reOrder}
            order={orders}
            titles={['email', 'prénom', 'nom', 'team','bday','statut', 'Achat de volants', 'Stock de volants', 'Classement/Participations','Commandes']}
            datasToDisplay={datas['hydra:member'].map((element) => ({
              id: element['@id'].split('/')[3],
              infos: [element.email, element.firstname, element.lastname,
              element.hasOwnProperty('team') ?
                <div>
                  <div className='pays' style={{color:  element.team.serie.color1, backgroundColor: element.team.serie.color2}}>{element.team.number}</div>
                </div>
                : 'Aucune équipe',
              moment(element.birthdate).format('DD/MM/YYYY'), statutUser(element.status), element.hasOwnProperty('statusBoughtWheels') ? allCostTotal.find(x => x.id === element['@id'].split('/')[3]) !== undefined ? (allCostTotal.find(x => x.id === element['@id'].split('/')[3]).baseCost / 100 ) + '€' : 'Aucun achat' : '-', element.hasOwnProperty('balance') ? element.balance : '-',
              <div>
                <FontAwesomeIcon size='lg' icon={faMedal} style={{cursor: 'pointer'}} onClick={() => {history.push(`/classements/user/1/${element['@id'].split('/')[3]}`)}}/>&nbsp;&nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon size='lg' icon={faCar} style={{cursor: 'pointer'}} onClick={() => {history.push(`/participations/1/${element['@id'].split('/')[3]}`)}}/>
              </div>,
              <FontAwesomeIcon size='lg' icon={faShoppingCart} style={{cursor: 'pointer'}} onClick={() => {history.push(`/commandes/user/1/${element['@id'].split('/')[3]}`)}}/>
            ],
            }
            ))}
          />
        )}
      </div>
    </>
  );
};

// == Export
export default Utilisateurs;
