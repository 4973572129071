// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ImageSelector from '../../components/ImageSelector';

// Action Creators
import { upload } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state['media_objects'],
  waitingRequest: state.waitingRequest,
  //connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  upload: (data, willDo) => {
    dispatch(upload(data, 'media_objects', willDo));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImageSelector);