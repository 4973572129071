// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Configuration = ({ location, match, data, patch, waitingRequest, getOne, deleteEntity, connected }) => {
    const [duelCost, setDuelCost] = useState('')
    const [gtCost, setGtCost] = useState('')
    const [againCost, setAgainCost] = useState('')
    const [volantEuro, setVolantEuro] = useState('')
    const [teamChangeCost, setTeamChangeCost] = useState('')
    const [defaultCost, setDefaultCost] = useState('')
    const [tourismCost, setTourismCost] = useState('')
    const [trainingCost, setTrainingCost] = useState('')
    const [minAvgSpeed, setMinAvgSpeed] = useState('')
    const [maxAvgSpeed, setMaxAvgSpeed] = useState('')

    useEffect(() => {
        getOne(1)
    }, [getOne])

    useEffect(() => {
        if(data) {
            setDuelCost(data.duelDefaultCost)
            setGtCost(data.gtDefaultCost)
            setAgainCost(data.tryAgainDefaultCost)
            setVolantEuro(data.wheelsPerEuro)
            setTeamChangeCost(data.teamNumberCost)
            setDefaultCost(data.challengeDefaultCost)
            setTourismCost(data.tourismCost)
            setTrainingCost(data.trainingCost)
            setMinAvgSpeed(data.minAvgSpeed)
            setMaxAvgSpeed(data.maxAvgSpeed)
        }
    }, [data])

    /*useEffect(() => {
        console.log(connected)
        if(connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN')
        {
            console.log('getTools')
            getTools(match.params.page);
        }
    }, []);
    console.log('datas id', datas['id'])*/

    if(waitingRequest > 0 /*|| connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={10} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Configuration</h1>
        </div>
        <div className="form-wrapper">

            <div style={{margin:'0 15%'}}>
                <h4>Coûts en volants :</h4>
            </div>
            <div className='fieldset-wrapper' style={{marginTop: 10, display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <label className='quarter-row setting-number'>Coût Challenge
                    <input value={defaultCost} onChange={(e) => {setDefaultCost(e.target.value)}} type='number' className="input-text" placeholder='Coût try again'/>
                </label>
                <label className='quarter-row setting-number'>Coût Try again
                    <input value={againCost} onChange={(e) => {setAgainCost(e.target.value)}} type='number' className="input-text" placeholder='Coût try again'/>
                </label>
                <label className='quarter-row setting-number'>Coût Tourisme
                    <input value={tourismCost} onChange={(e) => {setTourismCost(e.target.value)}} type='number' className="input-text" placeholder='Coût tourisme'/>
                </label>
                <label className='quarter-row setting-number'>Coût Training
                    <input value={trainingCost} onChange={(e) => {setTrainingCost(e.target.value)}} type='number' className="input-text" placeholder='Coût training'/>
                </label>
                {/*<label className='quarter-row setting-number'>Coût duel*/}
                {/*    <input value={duelCost} onChange={(e) => {setDuelCost(e.target.value)}} type='number' className="input-text" placeholder='Coût duel' disabled />*/}
                {/*</label>*/}
                {/*<label className='quarter-row setting-number'>Coût Gran Trofeo*/}
                {/*    <input value={gtCost} onChange={(e) => {setGtCost(e.target.value)}} type='number' className="input-text" placeholder='Coût Gran Trofeo' disabled />*/}
                {/*</label>*/}
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <label className='half-row'>Volants par Euro
                    <input value={volantEuro} onChange={(e) => {setVolantEuro(e.target.value)}} type='number' className="input-text" placeholder='Volants par Euro'/>
                </label>
                <label className='half-row'>Prix changement d'équipe
                    <input value={teamChangeCost} onChange={(e) => {setTeamChangeCost(e.target.value)}} type='number' className="input-text" placeholder="Prix changement d'équipe"/>
                </label>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <label className='half-row'>Vitesse moyenne minimale (Km/H)
                    <input value={minAvgSpeed} onChange={(e) => {setMinAvgSpeed(e.target.value)}} type='number' className="input-text" placeholder='Vitesse moyenne minimale'/>
                </label>
                <label className='half-row'>Vitesse moyenne maximale (Km/H)
                    <input value={maxAvgSpeed} onChange={(e) => {setMaxAvgSpeed(e.target.value)}} type='number' className="input-text" placeholder="Vitesse moyenne maximale"/>
                </label>
            </div>
            <div className='fieldset-wrapper'>
                <button className='button button-shadow' onClick={() => {patch(duelCost, againCost, gtCost, volantEuro, teamChangeCost, defaultCost, tourismCost, trainingCost, minAvgSpeed, maxAvgSpeed)}}>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default Configuration;
