// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import NewsAjout from '../../screen/NewsAjout';

// Action Creators
import { post, upload } from '../../store/reducer';
import moment from 'moment';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  post: (title='', publishedAt='', content='', images='', date, translationsEn=[]) => {
    dispatch(post('news', {
      translations: [
        {
          locale:'fr',
          title,
          content,
          images,
        },
        translationsEn[0]
      ],
      // title,
      // content,
      // images,
      publishedAt: moment(publishedAt).format('yyy-MM-DD hh:mm'),
      date: moment(date).format('yyy-MM-DD hh:mm'),
    }, (datas) => {ownProps.history.push(`/news/edit/${datas.id}`)} ));
  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsAjout);