// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import FaqEdit from '../../screen/FaqEdit';

// Action Creators
import { patch, getOne } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.onefaqs,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
 
  patch: (question='', answer='', titleEn='', descriptionEn='') => {
    dispatch(patch('faqs', ownProps.match.params.id, {
      translations: [
        {
          locale: 'fr',
          question,
          answer
        },
        {
          locale: 'en',
          question: titleEn,
          answer: descriptionEn
        }
      ]
    }, (datas) => {ownProps.history.push(`/faq/edit/${datas['@id'].split('/').reverse()[0]}`)}, ownProps.history));
 
  },
  getOne: (id) => {
    dispatch(getOne('faqs', id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FaqEdit);