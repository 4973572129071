// == Import : npm
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import ReactLoading from 'react-loading';
import Axios from 'axios';
// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Listing from '../../containers/Listing'
import {getCountryList} from "../../utils/countryList";

import { boEntries } from '../../utils/headerEntries.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBug } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {Modal} from 'antd';

// == Component
const Participations = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected, challenges}) => {
	console.log({datas})
	const [visible, setVisible] = useState(false)
	const [saveStateModalDatas, setSaveStateModalDatas] = useState({})
	const [listChallenges, setListChallenges] = useState();
	const [listCourses, setListCourses] = useState(); //eslint-disable-line
	const [countryList, setCountryList] = useState([]);

	const [challenge, setChallenge] = useState('');
	console.log({challenge})
	const [courses, setCourses] = useState('');
	const [status, setStatus] = useState('');
	const [country, setCountry] = useState('');
	const [duel, setDuel] = useState('');
	const [tryAgain, setTryAgain] = useState('');
	const [urlFilterOrder, setUrlFilterOrder] = useState(null);
	const [orders, setOrders] = useState(
			{ firstname: '', date: '', dateDepart:'', parcoursNumber: '', url: '' }
			);
	//browser environment
	const search = () => {
			let url = getUrlFilter();
			history.replace({ pathname: `/participations/1${match.params.user? '/'+match.params.user : ''}`});
			getAll(1, url);
	}

	const history = useHistory();

    useEffect(() => {
			console.log('onfiltre ?')
        let url = getUrlFilter();
        getAll(match.params.page, url);
    }, [match.params.user, match.params.page, urlFilterOrder]); //eslint-disable-line

    useEffect(() => {
        getListCourses();
        getListChallenges();
        setCountryList(getCountryList());
    },[])

	const getUrlFilter = () => {
		let url = 'groups[]=part:admin:read';

		if(challenge !== '')
				url += `&challenge.id=${challenge}`;

		if(courses !== '')
				url += `&course.id=${courses}`;

		if(status !== '')
				url += `&status=${status}`;

		if(tryAgain !== '')
				url += `&tryAgain=${tryAgain}`;

		if(country !== '')
				url += `&course.code=${country}`;

		if(duel !== '')
				url += `&exists[duel]=${duel}`;

		if(duel !== '')
				url += `&exists[duel]=${duel}`;

		if(match.params.user !== undefined)
				url += `&user=${match.params.user}`;

		if(urlFilterOrder != null) {
			url += `${urlFilterOrder}`;
		} else {
			url += '&order[createdAt]=desc';
		}

		return url;
	}

	const getParticipationNumber = (modeTryAgain, id) => {
		return `${modeTryAgain.includes('tryAgain') ? 'Tr' : 'Pa'}-${id}`
	}

	const reOrder = (type) => {
		let ord = { ...orders };
		setOrders({ firstname: '', date: '', dateDepart:'', parcoursNumber: '', url: '' });
		console.log('type',type);
		switch (type) {
			case "Date d'inscription":
				if (ord.date === 'asc') {
					ord.date = 'desc';
					ord.firstname = '';
					ord.parcoursNumber = '';
					ord.dateDepart = '';
					ord.url = "&order[createdAt]=desc";
				} else {
					ord.date = 'asc';
					ord.firstname = '';
					ord.parcoursNumber = '';
					ord.dateDepart = '';
					ord.url = "&order[createdAt]=asc";
				}
				break;
			case 'Prénom & Nom':
				if (ord.firstname === 'asc') {
					ord.firstname = 'desc';
					ord.date = '';
					ord.parcoursNumber = '';
					ord.dateDepart = '';
					ord.url = "&order[user.firstname]=desc";
				} else {
					ord.firstname = 'asc';
					ord.date = '';
					ord.parcoursNumber = '';
					ord.dateDepart = '';
					ord.url = "&order[user.firstname]=asc";
				}
				break;
			case 'N° Parcours':
				if (ord.parcoursNumber === 'asc') {
					ord.parcoursNumber  = 'desc';
					ord.firstname = '';
					ord.date = '';
					ord.dateDepart = '';
					ord.url = "&order[course.id]=desc";
				} else {
					ord.parcoursNumber  = 'asc';
					ord.firstname = '';
					ord.date = '';
					ord.dateDepart = '';
					ord.url = "&order[course.id]=asc";
				}
				break;
				case 'Date de départ':
					if (ord.dateDepart === 'asc') {
						ord.dateDepart = 'desc';
						ord.firstname = '';
						ord.parcoursNumber = '';
						ord.url = "&order[startedAt]=desc";
					} else {
						ord.dateDepart = 'asc';
						ord.firstname = '';
						ord.parcoursNumber = '';
						ord.url = "&order[startedAt]=asc";
					}
					break;
				default:
					break;
			}
			setUrlFilterOrder(ord.url);
			setOrders(ord);
		};

	const getParticipation = () => {
		const urlHistory = `/participations/1${match.params.user ? '/'+ match.params.user : ''}`;
		history.replace({ pathname: urlHistory});
		let url = getUrlFilter();
		getAll(1,url);
	}

	const validColor = 'green'
	const startedColor = 'orange'
	const scoredColor = '#A92E0C'
	const forfeitColor = '#9933ff'
	const disqualifiedColor = 'grey'
	const getStyleLine = (status) => {
		let color;
		switch (status) {
			case 'valid' :
				color = validColor;
				break;
			case 'started' :
				color = startedColor;
				break;
			// red
			case 'scored':
				color = scoredColor;
				break;
			// purple
			case 'forfeit':
				color = forfeitColor;
				break;
			case 'disqualified':
				color = disqualifiedColor;
				break;
			// default
			default:
				color = '#a84d34';
				break;
		}
		return color;
	}

	const getListCourses = () => {
		Axios.get(`${process.env.REACT_APP_API_URL}/api/courses?pagination=false`, {withCredentials:true}).then(response => setListCourses(response.data['hydra:member']));
	}
	const getListChallenges = () => {
		Axios.get(
			`${process.env.REACT_APP_API_URL}/api/challenges/list`,
			{withCredentials:true}
		)
			.then(response => {
				console.log('response.data', response.data)
				setListChallenges(response.data)
			});
	}
	if(waitingRequest > 0) {
		return (
			<div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
				<ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
			</div>
		)
	}

	function saveStateModalContent () {
		return(
			<div style={{flex:1, justifyContent:'center', alignItems:'center'}}>
				<h2>Données sauvegardées :</h2>
				<ul>
					{Object.keys(saveStateModalDatas).map((objectKey, index) =>
						<li
							key={index}
							id={objectKey}
						>
						{
							typeof saveStateModalDatas[objectKey] === "object" && !Array.isArray(saveStateModalDatas[objectKey]) && saveStateModalDatas[objectKey] !== null ?
								<p>
									<span style={{fontWeight:'bold'}}>{objectKey} :</span>
									<ul>
										<li id={'speed'} style={{marginLeft:10}}>Heading : { saveStateModalDatas[objectKey]?.heading}</li>
										<li id={'coords'} style={{marginLeft:10}}>Coords : ( lat , lng ) = <a href={`https://www.google.com/maps/search/${saveStateModalDatas[objectKey]?.coords?.lat}+,+${saveStateModalDatas[objectKey]?.coords?.lng}`} target="_blank" rel="noopener noreferrer" style={{color:'dodgerBlue', textDecoration:'underline'}}>( { saveStateModalDatas[objectKey]?.coords?.lat} , { saveStateModalDatas[objectKey]?.coords?.lng} )</a></li>
										<li id={'speed'} style={{marginLeft:10}}>Speed : { saveStateModalDatas[objectKey]?.speed}</li>
									</ul>
								</p>
							: Array.isArray(saveStateModalDatas[objectKey]) ?
									<p>
										<span style={{fontWeight:'bold'}}>{objectKey} :</span>
										<ul>{saveStateModalDatas[objectKey].map((element, index) =>
											<li id={index} style={{marginLeft:10}}>{index%2 === 0 ? `Chrono ${index === 0 ? '1' : index === 2 ? '2' : '3'} : ${element}` : `Tampon ${index === 1 ? '1' : '2'} : ${element}`}</li>)}
										</ul>
									</p>
								:	typeof saveStateModalDatas[objectKey] === "string" ?
										<p>
											<span style={{fontWeight:'bold'}}>{objectKey} :</span> {saveStateModalDatas[objectKey]}
										</p>
									:	typeof saveStateModalDatas[objectKey] === "number" ?
											<p>
												<span style={{fontWeight:'bold'}}>{objectKey} :</span> {saveStateModalDatas[objectKey] > 1600000000 ? Date(saveStateModalDatas[objectKey]*1000) : saveStateModalDatas[objectKey]}
											</p>
										:	typeof saveStateModalDatas[objectKey] === "boolean" ?
												<p>
													<span style={{fontWeight:'bold'}}>{objectKey} :</span> {saveStateModalDatas[objectKey] ? "true" : "false"}
												</p>
											:	saveStateModalDatas[objectKey] === null ?
													<p>
														<span style={{fontWeight:'bold'}}>{objectKey} :</span> null
													</p>
												: {objectKey} + " = ???"
						}
						</li>
					)}
				</ul>
			</div>
		)
	}

	return (
		<>
			<Header currentMenuItem={16} entries={boEntries} location={location} match={match} />
			<div className='participations-page-wrapper'>
				<div style={{ display:'flex', justifyContent: 'space-between', margin:'30px 0% 0 0' }}>
					<h1 className='page-title'>Participations</h1>
				</div>
				<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:"space-around", alignItems:'center', marginBottom:25}}>
					<label style={{width: '100px'}}>Statut</label>
					<select type='text' className="input-text" style={{width:'30%'}} value={status} onChange={(e) => setStatus(e.target.value)}>
						<option value=''>Sélectionnez un statut de participation</option>
						<option value='started'>Commencé</option>
						<option value='valid'>Inscrit</option>
						<option value='scored'>Scoré</option>
						<option value='forfeit'>Forfait</option>
						<option value='disqualified'>Disqualifié</option>
					</select>
					<label style={{width: '100px'}}>Code Pays</label>
					<select type='text' className="input-text" style={{width:'30%'}} value={country} onChange={(e) => setCountry(e.target.value)}>
						<option value=''>Sélectionnez un code pays</option>
						{countryList.map((item, idx)=> <option key={idx} value={item.key}>{item.label}</option>)}
					</select>
				</div>
				<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:"space-around", alignItems:'center', marginBottom:25}}>

					<label style={{width: '100px'}}>Duel</label>
					<select type='text' className="input-text" style={{width:'30%'}} value={duel} onChange={(e) => setDuel(e.target.value)}>
						<option value=''>Sélectionnez le statut duel</option>
						<option value='true'>Oui</option>
						<option value='false'>Non</option>
					</select>
					<label style={{width: '100px'}}>Try again</label>
					<select type='text' className="input-text" style={{width:'30%'}} value={tryAgain} onChange={(e) => { console.log(e.target.value); setTryAgain(e.target.value)}}>
						<option value=''>Sélectionnez un statut Try again</option>
						<option value='true'>Oui</option>
						<option value='false'>Non</option>
					</select>
				</div>
				<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:"space-around", alignItems:'center', marginBottom:25}}>
					<label className='col' style={{width: '100px'}}>Challenges</label>
					<select
						type='text'
						className="col-md-4 input-text"
						style={{width:'10%'}}
						value={challenge}
						onChange={(e) => setChallenge(e.target.value)}
					>
						<option value=''>Sélectionnez un challenge</option>
						{listChallenges && listChallenges.map((item, index) =>(
							<option key={index} value={item.id}>{item.title}</option>
						))}
					</select>
					{/*<select type='text' className="input-text" style={{width:'30%'}} value={courses} >
                    <option value=''>Sélectionnez un parcours</option>
                    {listCourses && listCourses.map((item, index) =>(
                        <option key={index} value={item.code}>{item.title}</option>
                    ))}
                </select>*/}

					<label className='col' style={{width: '40%'}}>Saisissez un ou plusieurs numéro de parcours</label>
					<input style={{width: '30%'}} value={courses} onChange={(e) => {setCourses(e.target.value)}} className='input-text codeCourseFilter' type='text' placeholder='(séparés par des points virgules)'/>
					<div className="m-3">
						<FontAwesomeIcon onClick={() => {search()}} size='xs' icon={faSearch}  className='fa-button plus'/>
					</div>
				</div>
				<div className='row mb-3' style={{flex:1, justifyContent:'center'}}>
					<button className=' button button-shadow ml-1' style={{backgroundColor:'#AB3217', color:'white', width:190, marginRight:50}} onClick={() => {getParticipation()}}>Filtrer</button>
					<button className=' button button-shadow ml-1' style={{backgroundColor:'#AB3217', color:'white', width:190}} onClick={() => {window.location = '/participations/1'}}>Réinitialiser</button>
				</div>
				<div className='row mb-3' style={{flex:1, justifyContent:'center'}}>
					<div style={{fontWeight:'bold', paddingBottom:3, paddingTop:3}}>
						Légende >
					</div>
					<div style={{color:'white', backgroundColor:validColor, paddingTop:3}}>
						Inscrit
					</div>
					<div style={{color:'white', backgroundColor:startedColor, paddingTop:3}}>
						Commencé
					</div>
					<div style={{color:'white', backgroundColor:scoredColor, paddingTop:3}}>
						Scoré
					</div>
					<div style={{color:'white', backgroundColor:forfeitColor, paddingTop:3}}>
						Forfait
					</div>
					<div style={{color:'white', backgroundColor:disqualifiedColor, paddingTop:3}}>
						Disqualifié
					</div>
				</div>
				{datas && <Listing
					isAction={false}
					type={'participations'}
					dataType={'participations'}
					match={match}
					datas={datas}
					orderFunction={reOrder}
					order={orders}
					titles={['N° de participation', "Date d'inscription",'Date de départ', 'Heure de départ', 'Prénom & Nom', 'Copilote', 'N° Parcours',
						'N° Challenge', 'Challenge GranTrofeo', 'Duel', 'Try again','Score Etape 1', 'Score Etape 2',
						'Score Etape 3', 'Score global', 'saveState']}
					datasToDisplay={
						datas['hydra:member'].map((element,index) => ( {
								id: element['@id'].split('/')[3],
								colorLine: getStyleLine(element.status),
								infos :[
									getParticipationNumber(element.type, element['@id'].split('/')[3]),
									"  " + moment(element.createdAt).format('DD/MM/YYYY') ,
									element.startedAt ? "  " + moment(element.startedAt).format('DD/MM/YYYY') : "-",
									moment(element.startedAt).format('HH:mm'),
									element.user ? <a style={{color:'white'}} href={'/utilisateurs/edit/'+element.user['@id'].split('/')[3]}>{element?.user?.firstname} {element?.user?.lastname}</a> : '-',
									element.hasOwnProperty('copilot') ? ` ${element.copilot?.title} ${element.copilot.firstname} ${element.copilot.lastname} - ${element.copilot.email}` : "-",
									element.course?.code,
									element.challenge?.code,
									element.type.includes('GT') ? 'Oui': 'Non',
									element.type.includes('duel') ? 'Oui': 'Non',
									element.type.includes('tryAgain') ? 'Oui': 'Non',
									element.scoresData.length > 0 ? element.scoresData && (element.scoresData[0].score1 > 0 ? element.scoresData[0].score.toFixed(6) : element.scoresData[0].score) : "-",
									element.scoresData.length > 0 ? element.scoresData && (element.scoresData[0].score2 > 0 ? element.scoresData[0].score2.toFixed(6) : element.scoresData[0].score2) : "-",
									element.scoresData.length > 0 ? element.scoresData && (element.scoresData[0].score3 > 0 ? element.scoresData[0].score3.toFixed(6) : element.scoresData[0].score3) : "-",
									element.scoresData.length > 0 ? element.scoresData && (element.scoresData[0].finalScore > 0 ? element.scoresData[0].finalScore.toFixed(6) : element.scoresData[0].finalScore) : "-",
									element.saveState.length > 0 ?
										element.saveState[0].lastCoordinates !== undefined ?
											<FontAwesomeIcon
												key={index}
												style={{cursor: 'pointer'}}
												icon={faBug}
												onClick={() => {
													setVisible(!visible)
													setSaveStateModalDatas(element.saveState[0])
												}}
											/>
										: 'no data'
									: 'no data',
								]
							})
						)}
				/>}
				<Modal
					visible={visible}
					footer={null}
					onOk={e => {setVisible(false)}}
					onCancel={e => {setVisible(false)}}
				>
					{saveStateModalContent()}
				</Modal>
			</div>
		</>
	)

};

// == Export
export default Participations;
