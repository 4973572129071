// == Import : npm
import React, {useState, useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

// == Composant
const ImagePickerMedia = ({streamLoading, datas, onClick, waitingRequest, setPickImage }) => {

    const [page, setPage] = useState(1)

    const [search, setSearch] = useState('')
    const [filePath, setFilePath] = useState('')

    const [time, setTime] = useState()

    const [hasMore, setHasMore] = useState(false)


    useEffect(() => {
        if(datas && datas['hydra:view'])
            setPage(parseInt(datas['hydra:view']['@id'].split('=')[datas['hydra:view']['@id'].split('=').length-1]))
        if(datas && datas['hydra:totalItems'] > datas['hydra:member'].length)
            setHasMore(true)
    }, [datas])

    useEffect(() => {

        console.log('search request')
        clearTimeout(time);

        setFilePath(search !== '' ? `filePath=${search}` : '')
        setTime(setTimeout(() => {setPage(1); streamLoading(1, filePath)}, 1000))

    }, [search]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='image-picker-media'>
            <input type='text' className="input-text" placeholder='Chercher' style={{width:'45%'}} value={search} onChange={e => { setSearch(e.target.value); }}/>
            {waitingRequest > 0 && <ReactLoading type={'cylon'} color={'#A92E0C'} height={100} width={200} />}
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', height:'90%', overflow:'scroll', marginTop:30}}>
            {datas && <InfiniteScroll
                    style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}
                    pageStart={0}
                    loadMore={() => {streamLoading(page+1, filePath); setHasMore(false)}}
                    hasMore={waitingRequest === 0 && hasMore}
                    loader={<ReactLoading type={'cylon'} color={'#A92E0C'} height={100} width={200} />}
                    useWindow={false}
                >
                    {datas && datas['hydra:member'].map((element) => <div onClick={() => window.open(`${process.env.REACT_APP_API_URL}/${element.contentUrl}`, "_blank")} className='button-shadow' style={{backgroundColor:'white', borderRadius:10, height:200, width:200, margin:20}}>
                        <img alt="" src={`${process.env.REACT_APP_API_URL}/${element.contentUrl}`} style={{height:200, width:200}} />
                    </div>)}
                </InfiniteScroll>}
            </div>
        </div>
    );
};

// == Export
export default ImagePickerMedia;
