// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ParcourEdit from '../../screen/ParcourEdit';

// Action Creators
import { patch, upload, getOne, getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.onecourses,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  challenges: state.challenges,
  settings: state.onesettings,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patch: (title='', code='', allowReturn='', titleDepartArrive='', allowGT='', distance='', averageTime='', minTime='', maxTime='0', startName='', endName='', status='', challenges=[], description='', descriptionEn='', directions, returnDistance='', returnAverageTime='', returnMinTime='', returnMaxTime='', stagesOrder='', returnStagesOrder='', longitude, latitude, isShow=true) => {
    dispatch(patch('courses', ownProps.match.params.id ,{
      title,
      code,
      allowReturn,
      titleDepartArrive,
      allowGT,
      distance: parseFloat(distance),
      averageTime: parseInt(averageTime.split('H')[0].replace(/\D/g,''))*60 + parseInt(averageTime.split('H')[1].replace(/\D/g,'')),
      minTime: parseInt(minTime.split('H')[0].replace(/\D/g,''))*60 + parseInt(minTime.split('H')[1].replace(/\D/g,'')),
      maxTime: parseInt(maxTime.split('H')[0].replace(/\D/g,''))*60 + parseInt(maxTime.split('H')[1].replace(/\D/g,'')),
      startName,
      endName,
      status,
      challenges,
      // description,
      directions,
      returnDistance: parseFloat(returnDistance),
      returnAverageTime: parseInt(returnAverageTime.split('H')[0].replace(/\D/g,''))*60 + parseInt(returnAverageTime.split('H')[1].replace(/\D/g,'')),
      returnMinTime: parseInt(returnMinTime.split('H')[0].replace(/\D/g,''))*60 + parseInt(returnMinTime.split('H')[1].replace(/\D/g,'')),
      returnMaxTime: parseInt(returnMaxTime.split('H')[0].replace(/\D/g,''))*60 + parseInt(returnMaxTime.split('H')[1].replace(/\D/g,'')),
      stagesOrder,
      returnStagesOrder,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      isShow:isShow,
      translations: [
        {
          description,
          locale: "fr"
        },
        {
          description: descriptionEn,
          locale: "en"
        }
      ]
    },
      ownProps.history));
  },

  upload: (data) => {
    dispatch(upload(data, 'geodata_objects'));
  },

  getOne: (id) => {
    dispatch(getOne('courses', id));
  },
  getAll: ( type, page, params ) => {
    dispatch(getAll(type, page, params, ownProps.history));
  },
  getSettings: () => {
    dispatch(getOne('settings', 1));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParcourEdit);