// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ChallengesEdit from '../../screen/ChallengesEdit';

// Action Creators
import { patch, upload, getOne, getAll, setMessageNotif } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.onechallenges,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
  rewards: state.rewards,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patch: (
    id,
    courses,
    dotations,
    partenaires,
    isShow,
    code='',
    translations=[0],
    startAt='',
    endAt='',
    registryLimitDate='',
    dateShowStart=null ,
    dateShowEnd=null ,
    maxSlots=0,
    normalCost=0,
    tryAgainCost=0,
    gtCost=0,
    duelCost=0,
    tourismCost = 0,
  ) => {
    dispatch(patch('challenges', id, {
      code,
      startAt,
      endAt,
      registryLimitDate,
      dateShowStart,
      dateShowEnd,
      maxSlots,
      isShow,
      normalCost,
      tryAgainCost,
      gtCost,
      duelCost,
      tourismCost,
      status: '', courses,
      challengeDotations:dotations.map(element=>({reward: element.iri, minRank: element.min, maxRank: parseInt(element.max ? element.max : element.min,), isWon : true})),
      partners: partenaires,
      translations: translations
    },
      (datas) => {ownProps.history.push(`/challenges/edit/${datas.id}`)}, ownProps.history));
  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
  getOne: (id) => {
    dispatch(getOne('challenges', id));
  },
  getAll: ( page, params) => {
    dispatch(getAll('rewards', page, params));
  },
  setMessageNotif: (msg) => {
    dispatch(setMessageNotif(msg))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengesEdit);
