import React from 'react';

const TranslateComp = ({data, name, stateValue, handleChange}) => {
  return Object.keys(data).map((item, index) => (
        <div key={name + index} className='table_container-groups'>
          {item === "ATTENTION(start)" &&
          <span>(start)</span>}
          {item === "ATTENTION(finish)" &&
          <span>(finish)</span>}
          <div>{data[item]}</div>
          <div id='translation'>
            <textarea
                value={stateValue[item] || ''}
                style={{width: (item === 'ATTENTION(start)' ? '540px !important': '') }}
                onChange={(evt) => handleChange(evt, name, item)}
                rows={3}>

            </textarea>
          </div>
        </div>
  ))
}

export default TranslateComp;
