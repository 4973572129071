// == Import : npm
import React from 'react';

// == Import : local
import '../../style/style.scss';

// == Composant
const MultiSelect = ({ datas, setSelected, selected }) => {
	// console.log('datas, selected',datas, selected);

	return (
		<div style={{ minWidth:300, border:'2px solid #AB3217', borderRadius:10, overflow:'hidden'}}>
			<div style={{backgroundColor: '#AB3217', padding:5, display:'flex', flexDirection:'row', alignItems:'center'}}>
				<label style={{color:'white', marginRight:30}}>Selection Challenge : </label>
				<button className='button button-shadow' style={{marginRight:20}} onClick={() => {
					let temp = selected.slice();
					temp = temp.filter(e => e === '/api/challenge/1')
					console.log(datas.filter(e => e.id!=='/api/challenge/1'))
					datas.filter(e => e.id !== '/api/challenge/1').forEach(e => {temp.push(e.id)})
					setSelected(temp)
				}}>Tout sélectionner</button>
				<button className='button button-shadow' onClick={() => {setSelected(selected.filter(e => e === '/api/challenges/1'))}}>Tout désélectionner</button>
			</div>
			<div style={{maxHeight:200, overflow:'auto'}}>
				{datas.map((element, index) => {
					if(element.id!=='/api/challenges/1') {
						return (
							<div key={index} style={{cursor:'pointer', padding:5, backgroundColor:selected.find(selectedElement => selectedElement === element.id) ? '#AB3217' : 'white', color:selected.find(selectedElement => selectedElement === element.id) ? 'white' : '#AB3217'}} onClick={() => {
								if(selected.find(selectedElement => selectedElement === element.id)) {
									setSelected(selected.filter(e => e !== element.id))
								} else {
									setSelected([...selected, element.id])
								}
							}}>
								<p>{element.title}</p>
							</div>
						)
					}
					return null
				})}
			</div>
		</div>
	);
}

// == Export
export default MultiSelect;
