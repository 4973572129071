// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import TooltipsEdit from '../../screen/TooltipsEdit';

// Action Creators
import { getOne, patch } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.onetooltips,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getOne: (id) => {
    dispatch(getOne('tooltips', id));
  },
  patch: (title='', content='', titleEn='', contentEn='') => {
    dispatch(patch('tooltips', ownProps.match.params.id, {translations: [
      {
        locale: 'fr',
        title,
        content
      },
      {
        locale: 'en',
        title: titleEn,
        content: contentEn
      }
    ]}, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TooltipsEdit);