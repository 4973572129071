// == Import : npm
import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import ReactLoading from 'react-loading'

// == Import : local
import '../../style/style.scss'
import './home.scss'

// == Composant
const Login = ({ location, match, waitingRequest, login, me, connected, cookies, messageNotif }) => {
  const [mail, setMail] = useState(null)
  const [password, setPassword] = useState(null)

  if (connected) {
    if (connected.hasOwnProperty('user')) {
      switch (connected.user.roles[0]) {
        case 'ROLE_ADMIN':
          return <Redirect to="/tableau-de-bord"/>
        case 'ROLE_PARCOURS':
          return <Redirect to="/parcours/1"/>
        case 'ROLE_COMMUNICATION':
          return <Redirect to="/challenges/1"/>
        default:
          break
      }
    } else if (connected.hasOwnProperty('roles')) {
      switch (connected.roles[0]) {
        case 'ROLE_ADMIN':
          return <Redirect to="/tableau-de-bord"/>
        case 'ROLE_PARCOURS':
          return <Redirect to="/parcours/1"/>
        case 'ROLE_COMMUNICATION':
          return <Redirect to="/challenges/1"/>
        default:
          break
      }
    }
  }

  if (waitingRequest > 0) {
    return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
    )
  }

  return (
    <div className="home-wrapper">
        <div className="connection-wrapper">
            <h1>Connectez-vous</h1>
            <p style={{ marginBottom: 10, color: 'white' }}>{messageNotif ? messageNotif.message : ''}</p>
            <div className="input-wrapper">
                <input type="text" className="input-text" placeholder="Adresse e-mail" value={mail} onChange={(e) => { setMail(e.target.value) }}/>
            </div>
            <div className="input-wrapper">
                <input type="password" className="input-text" placeholder="Mot de passe" value={password} onChange={(e) => { setPassword(e.target.value) }}/>
                <Link to="/forget-password" className="forget-link">Mot de passe oublié</Link>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <input onClick={() => { login(mail, password) }} value="CONNEXION" type="submit" className="button input-submit button-shadow"/>
            </div>
        </div>
    </div>
  )
}

// == Export
export default Login
