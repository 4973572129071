// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import App from '../../App';

export const mapStateToProps = (state, ownProps) => ({
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
