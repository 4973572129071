// == Import : npm
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import ReactLoading from 'react-loading';

// == Import : local
import "../../style/style.scss";

import Header from '../../containers/Header';
import Listing from '../../containers/Listing';

import { boEntries } from "../../utils/headerEntries.js";
import moment from "moment";

// == Composant
const Commandes = ({ location, match, datas, getAll, waitingRequest }) => {
  const [filter, setFilter] = useState({
    fromDate: '',
    toDate: '',
    country: '',
  });

  const [order, setOrder] = useState({
    numero:'',date:'',firstname:'', email:'', pays:'', prixTTC:''
  });

  useEffect(() => {
    getAll('receipts', 'type=wheels&groups[]=wheels-receipt:admin:listing');
  }, [getAll]);

  useEffect(() => {
    getAll(
      "receipts",
      `type=wheels&groups[]=wheels-receipt:admin:listing${match.params.user !== undefined ? `&user.id=${match.params.user}` : ''}${filter.country ? `&user.address.country=${filter.country}` : ''}${filter.fromDate ? `&date[strictly_before]=${moment(filter.fromDate).format("YYYY-MM-DD")}` : ''}${filter.toDate ? `&date[strictly_before]=${moment(filter.toDate).format("YYYY-MM-DD")}` : ''}`
    );
    setOrder({
      numero:'',date:'',firstname:'', email:'', pays:'', prixTTC:''
    })
  }, [getAll, filter, match.params.user]);

  const reOrder = (type) => {
    let urlFilterOrder = `type=wheels&groups[]=wheels-receipt:admin:listing${match.params.user !== undefined ? `&user.id=${match.params.user}` : ''}`;
    let ord = {...order};
    switch (type) {
      case 'email':
        if (ord.email === 'asc') {
          ord.email = 'desc';
          ord.numero = '';
          ord.date = '';
          ord.firstname = '';
          ord.prixTTC = '';
          ord.pays = '';
          urlFilterOrder += "&order[user.email]=desc";
        } else {
          ord.email = 'asc';
          ord.numero = '';
          ord.date = '';
          ord.firstname = '';
          ord.prixTTC = '';
          ord.pays = '';
          urlFilterOrder += "&order[user.email]=asc";
        }
        break;
      case 'pays':
        if (ord.pays === 'asc') {
            ord.pays = 'desc';
            ord.numero = '';
            ord.date = '';
            ord.firstname = '';
            ord.prixTTC = '';
            ord.email = '';
            urlFilterOrder += "&order[user.address.country]=desc";
        } else {
            ord.pays = 'asc';
            ord.numero = '';
            ord.date = '';
            ord.firstname = '';
            ord.prixTTC = '';
            ord.email = '';
            urlFilterOrder += "&order[user.address.country]=asc";
        }
        break;
      case 'Prix TTC':
        if (ord.prixTTC === 'asc') {
            ord.prixTTC = 'desc';
            ord.numero = '';
            ord.date = '';
            ord.firstname = '';
            ord.pays = '';
            ord.email = '';
           urlFilterOrder += "&order[baseCost]=desc";
        } else {
            ord.prixTTC = 'asc';
            ord.numero = '';
            ord.date = '';
            ord.firstname = '';
            ord.pays = '';
            ord.email = '';
            urlFilterOrder += "&order[baseCost]=asc";
        }
        break;
      case 'commande N°':
        if (ord.numero === 'asc') {
            ord.numero = 'desc';
            ord.prixTTC = '';
            ord.date = '';
            ord.firstname = '';
            ord.pays = '';
            ord.email = '';
           urlFilterOrder += "&order[id]=desc";
        } else {
            ord.numero = 'asc';
            ord.prixTTC = '';
            ord.date = '';
            ord.firstname = '';
            ord.pays = '';
            ord.email = '';
            urlFilterOrder += "&order[id]=asc";
        }
        break;
      case 'Prénom & Nom':
        if (ord.firstname === 'asc') {
            ord.firstname = 'desc';
            ord.prixTTC = '';
            ord.date = '';
            ord.numero = '';
            ord.pays = '';
            ord.email = '';
            urlFilterOrder += "&order[user.firstname]=desc";
        } else {
            ord.firstname  = 'asc';
            ord.prixTTC = '';
            ord.date = '';
            ord.numero = '';
            ord.pays = '';
            ord.email = '';
            urlFilterOrder += "&order[user.firstname]=asc";
        }
        break;
      case 'Date':
        if (ord.date === 'asc') {
            ord.date = 'desc';
            ord.prixTTC = '';
            ord.firstname = '';
            ord.numero = '';
            ord.pays = '';
            ord.email = '';
            urlFilterOrder += "&order[date]=desc";
        } else {
            ord.date = 'asc';
            ord.prixTTC = '';
            ord.firstname = '';
            ord.numero = '';
            ord.pays = '';
            ord.email = '';
            urlFilterOrder += "&order[date]=asc";
        }
        break;
      default:
        break;
    }
    setOrder(ord);
    getAll('receipts', urlFilterOrder)
  }

  if (waitingRequest > 0 /*|| connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
        <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
      </div>
    );
  }
  /*useEffect(() => {
        console.log(connected)
        if(connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN')
        {
            console.log('getTools')
            getTools(match.params.page);
        }
    }, []);
    console.log('datas id', datas['id'])*/

  /*if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    } else if(datas['@id'] != undefined && datas['@id'] != '/api/tools') {
        getTools(match.params.page);
   }*/

  return (
    <>
      <Header
        currentMenuItem={8}
        entries={boEntries}
        location={location}
        match={match}
      />
      <div
        className="page-wrapper"
        style={{ paddingLeft: "20%", paddingRight: "20%" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "100px 0% 0 0",
          }}
        >
          <h1 className="page-title">Commandes</h1>
        </div>
        <div
          className="fieldset-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: 25,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label className='mr-2'>Date :</label>
              <DatePicker
                className="button date-picker"
                placeholderText="De"
                selected={filter.fromDate}
                onChange={(date) => setFilter({ ...filter, fromDate: date })}
                locale="fr"
                dateFormat="dd/MM/yyyy"
              />
               &nbsp;- &nbsp;
              <DatePicker
                className="button date-picker"
                placeholderText="À"
                selected={filter.toDate}
                onChange={(date) => setFilter({ ...filter, toDate: date })}
                locale="fr"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <label className="half-row">Pays: </label>
              <input
                type="text"
                className="input-text"
                placeholder="Paris"
                value={filter.country}
                onChange={(e) =>
                  setFilter({ ...filter, country: e.target.value })
                }
              />
            </div>
            <div>
              <div
                className="button button-shadow"
                style={{
                  backgroundColor: "#AB3217",
                  color: "white",
                  padding: "10px 30px",
                  height: "fit-content",
                }}
                onClick={() =>
                  setFilter({
                    fromDate: "",
                    toDate: "",
                    country: "",
                    orders: "",
                  })
                }
              >
                Clair
              </div>
            </div>
          </div>
        </div>
        {datas && (
          <Listing
            type={'commandes'}
            dataType={'receipts'}
            match={match}
            datas={datas}
            orderFunction={reOrder}
            order={order}
            titles={['Date', 'commande N°', 'Prénom & Nom', 'pays', 'adresse', 'tel', 'email', 'title', 'Prix TTC', 'Commandes']}
            datasToDisplay={datas['hydra:member'].map((element) => ({
              id: element['id'],
              infos: [
                moment(element.date).format('DD/MM/YYYY'),
                element['id'] + element['stripeReceipt'],
                element['user']['lastname'] + ' ' + element['user']['firstname'],
                element['user']['address']['country'],
                element['user']['address']['address'],
                element['user']['phone'],
                element['user']['email'],
                element['newBalance'] - element['prevBalance'],
                element['baseCost'] / 100 + '€',
                // <a>https://dashboard.stripe.com/login</a>
                element['stripeReceipt'] ? (
                  <a href={`https://dashboard.stripe.com/payments/pi_xxxxxxx${element['stripeReceipt']}`} target="_blank" rel="noopener noreferrer">
                    Voir dans Stripe
                  </a>
                ) : (
                  ''
                ),
              ],
            }))}
          />
        )}
      </div>
    </>
  );
};

// == Export
export default Commandes;
