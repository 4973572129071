// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import CodesEdit from '../../screen/CodesEdit';

// Action Creators
import { patch, getOne, getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.onepromo_codes,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  challenges: state.challenges
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patch: (title, code, maxUses, value, type, status, challenges, limitDate, uniqueUse) => {
    dispatch(patch('promo_codes', ownProps.match.params.id, {title, code, maxUses, value, type, status, challenges, limitDate, remaningUses: maxUses,  hasUniqueUse: uniqueUse}, ownProps.history));
  },
  getOne: (id) => {
    dispatch(getOne('promo_codes', id));
  },
  getAll: ( type, page, params ) => {
    dispatch(getAll(type, page, params, ownProps.history));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CodesEdit);