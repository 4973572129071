import React, { useEffect, useState } from 'react';
import { boEntries } from '../../../utils/headerEntries';
import Header from '../../../containers/Header';
import './style.scss';

const TeamNumbers = ({ location, match, datas, waitingRequest, post, deleteEntity, connected, getAll, patch, receipts, getSeries, series }) => {
  const [receiptList, setReceiptList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [selectedSerie, setSelectedSerie] = useState('');
  const [orders, setOrders] = useState({ teamNumber: '', teamSerieOrd: '', teamSerie: '', id: '', name: '', color1: '', color2: '' });

  useEffect(() => {
    getReceipts();
    getSeries(1, '');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (series) {
      setSeriesList(series['hydra:member']);
    }
  }, [series]);

  useEffect(() => {
    if (receipts) {
      setReceiptList(receipts['hydra:member']);
    }
  }, [receipts]);
  const getReceipts = (id = '', teamNumber = '', teamSerieOrd = '', firstName = '', teamSerie = '') => {
    getAll(
      1,
      `type=number&groups[]=receipt:admin:listing&order[id]=${id}&order[user.team.number]=${teamNumber}&order[user.team.serie.id]=${teamSerieOrd}&order[user.firstname]=${firstName}&user.team.serie[]=${teamSerie}`
    );
  };

  const reOrder = (type, serieId = '') => {
    let ord = { ...orders };
    if (type === 'teamNumber') {
      if (ord.teamNumber === 'asc') {
        ord.teamNumber = 'desc';
      } else {
        ord.teamNumber = 'asc';
      }
    }
    if (type === 'teamSerie') {
      ord.teamSerie = serieId;
      setSelectedSerie(serieId);
    }
    if (type === 'teamSerieOrd') {
      if (ord.teamSerieOrd === 'asc') {
        ord.teamSerieOrd = 'desc';
      } else {
        ord.teamSerieOrd = 'asc';
      }
    }
    if (type === 'id') {
      if (ord.id === 'asc') {
        ord.id = 'desc';
      } else {
        ord.id = 'asc';
      }
    }
    if (type === 'name') {
      if (ord.name === 'asc') {
        ord.name = 'desc';
      } else {
        ord.name = 'asc';
      }
    }
    getReceipts(ord.id, ord.teamNumber, ord.teamSerieOrd, ord.name, ord.teamSerie);
    setOrders(ord);
  };

  return (
    <>
      <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
      <div className="page-wrapper" style={{ paddingLeft: '20%', paddingRight: '20%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '100px 0% 0 0' }}>
          <h1 className="page-title">Commandes des numéros d'équipage</h1>
        </div>
        <div className="form-wrapper row" style={{ marginBottom: 50 }}>
          <div className="col">
            <select type="text" className="input-text" value={selectedSerie} onChange={(e) => reOrder('teamSerie', e.target.value)}>
              <option value="">Sélectionner une série</option>
              {seriesList.map((serie, idx) => (
                <option key={idx} value={serie.id}>
                  {serie.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th className={'sortable ' + (orders.teamNumber ? (orders.teamNumber === 'asc' ? 'asc' : 'desc') : '')} onClick={() => reOrder('teamNumber')}>
                  <span className="sup-o">N</span>
                </th>
                <th>Série</th>
                <th className={'sortable ' + (orders.id ? (orders.id === 'asc' ? 'asc' : 'desc') : '')} onClick={() => reOrder('id')}>
                  <span className="sup-o">N</span> Commande
                </th>
                <th className={'sortable ' + (orders.name ? (orders.name === 'asc' ? 'asc' : 'desc') : '')} onClick={() => reOrder('name')}>
                  Client
                </th>
                <th>Fond</th>
                <th>Police</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {receiptList.map((receipt, idx) => (
                <tr key={idx}>
                  <td>{receipt.teamNumber}</td>
                  <td>{receipt.user.team.serie.name}</td>
                  <td>EQ-{receipt.id}</td>
                  <td>
                    {receipt.user.firstname} {receipt.user.lastname}
                  </td>
                  <td>
                    <input type="color" className="color" name="favcolor" value={receipt.user.team.serie.color1} />
                  </td>
                  <td>
                    <input type="color" className="color" name="favcolor" value={receipt.user.team.serie.color2} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TeamNumbers;
