// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import DotationsAjout from '../../screen/DotationsAjout';

// Action Creators
import { post, upload } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  post: (title='',image='',images = '', type = '',titleEn='', descriptionEn='', sponsor='', status='', quantity=0, description='', sponsorUrl, translationEn=[]) => {
    dispatch(post('rewards', {
      // title,
      // image,
      type,
      sponsor,
      status,
      quantity : parseInt(quantity),
      // description,
      sponsorUrl,
      translations: [
        {
          title,
          imageThumbnail: image,
          images,
          description,
          locale: 'fr'
        },
        {
          title: titleEn,
          imageThumbnail: image,
          images,
          description: descriptionEn,
          locale: 'en'
        }
      ]
    },
    (datas) => {ownProps.history.push(`/dotations/edit/${datas['@id'].split('/')[3]}`)}, ownProps.history));
  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DotationsAjout);
