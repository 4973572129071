// == Import : npm
import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { flag } from 'country-emoji';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

// == Import : local
import '../../style/style.scss';
import Pagination from '../../components/Pagination'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "antd";

// == Composant
const Listing = (
  { location,
    actionUpdate = "Modifier",
    commande = false,
    match,
    datas,
    orderFunction,
    order='',
    saveChallengePosition,
    saveRewardPosition,
    reloadFunction,
    deleteEntity,
    titles,
    datasToDisplay,
    type,
    dataType,
    allowDelete = true,
    isAction= true,
    isActiveLink= false,
  }) => {
  // console.log('listing datas : ', datas)
  const [visible, setVisible] = useState(false)
  const [displayDelete, setDisplayDelete] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState(null);
  const sortableDataType = {
    'users': ['email', 'prénom', 'nom', 'team', 'Stock de volants'],
    'participations': ['Date d\'inscription', 'Date de départ', 'Prénom & Nom', 'N° Parcours'],
    'rewards': ['titre', 'type', 'position'],
    'courses': ['Titre', 'Code', 'Distance'],
    'challenges': ['titre', 'debut', 'fin', 'position'],
    'receipts': ['Date', 'commande N°', 'Prénom & Nom', 'pays', 'email','Prix TTC'],
    'promo_codes': ['Titre', 'Date de validité'],
  }
  const [enteredPosition, setEnteredPosition] = useState("");
  const [selectedChallengeId, setSelectedChallengeId] = useState(0);
  const [selectedChallengePosition, setSelectedChallengePosition] = useState(0);
  const [selectedChallengeName, setSelectedChallengeName] = useState('');
  const [selectedRewardId, setSelectedRewardId] = useState(0);
  const [selectedRewardPosition, setSelectedRewardPosition] = useState(0);
  const [selectedRewardName, setSelectedRewardName] = useState('');

  const getClassNameOrder = (element) => {
    switch (element) {
      case 'Stock de volants':
        return order.balance;
      case 'prénom':
      case 'Prénom & Nom':
        return order.firstname;
      case 'email':
        return order.email;
      case 'nom':
        return order.lastname;
      case 'team':
        return order.teamNumber;
      case 'Date':
      case "Date d'inscription":
      case 'Date de validité':
        return order.date;
      case "Date de départ":
        return order.dateDepart;
      case 'N° Parcours':
        return order.parcoursNumber;
      case 'titre':
      case 'Titre':
        return order.titre;
      case 'type':
        return order.type;
      case 'Code':
        return order.code;
      case 'Distance':
        return order.distance;
      case 'debut':
        return order.debut;
      case 'fin':
        return order.fin;
      case 'commande N°':
        return order.numero;
      case 'pays':
        return order.pays;
      case 'Prix TTC':
        return order.prixTTC;
      case 'position':
        return order.position;
      default:
        break;
    }
  }

  function getDataToDisplayStyleConfiguration(dataToDisplay, index) {
    let bgLine = '#BBBBBB'
    let colorLine = ''
    if (dataToDisplay.colorLine) {
      bgLine = dataToDisplay.colorLine
      colorLine = 'white'
    } else if (index % 2 === 0) {
      bgLine = ''
    }

    let deleteLineHeight = 2.75
    if(allowDelete)
      deleteLineHeight = 'inherit'

    return {
      bgLine: bgLine,
      colorLine: colorLine,
      deleteLineHeight: deleteLineHeight
    }
  }

  return (
    <>
      <div className='table-wrapper' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '100%'}}>
        <table className={dataType}>
          <thead>
          <tr style={{borderBottom: '1px white solid'}}>
            {titles.map((element, index) => {
              if (sortableDataType[dataType] && sortableDataType[dataType].includes(element)) {
                return (
                  <th key={index} className={'sortable ' + (getClassNameOrder(element) + (element === 'prénom' ? ' firstname' : '')) } onClick={() => orderFunction(element)}>
                    {element}
                  </th>
                )
              } else {
                return (
                  <th key={index}>{element}</th>
                )
              }
            })}
            {isAction && <th>action</th>}
            {isActiveLink && <th style={{width: '20px', paddingRight: '10px'}}>Classements</th>}
          </tr>
          </thead>
          <tbody>
          { datasToDisplay.length > 0 ? datasToDisplay.map((dataToDisplay, index) => {
            const {bgLine, colorLine, deleteLineHeight} = getDataToDisplayStyleConfiguration(dataToDisplay, index);
            return (
              <tr key={index}
                  style={{
                    backgroundColor: bgLine,
                    color: colorLine
                  }}>
                {dataToDisplay.infos.map((element, idx) => {
                  if(dataType === 'classements' && idx === 1) {
                    return (
                      <td
                        key={idx}>
                        <div className='flag'>
                          {flag(element)}
                        </div>
                      </td>
                    )
                  } else if(dataType === 'classements' && idx === 2){
                    return (
                      <td key={idx} >
                        <div className='pays' style={{color: dataToDisplay.stylePays[0], backgroundColor: dataToDisplay.stylePays[1]}}>
                          {element}
                        </div>
                      </td>
                    )
                  }
                  return (
                    <td  key={idx} style={{width:`${100/dataToDisplay.infos.length}%`}}>{element}</td>
                  )
                })}
                {dataType === 'challenges' && (
                  <td style={{display:'flex', marginRight:10}}>
                      <input
                        style={{color:'rgb(169, 46, 12)', width:80}}
                        type='text'
                        defaultValue=""
                        className="input-text"
                        placeholder={dataToDisplay.position}
                        onClick={()=> {
                          setSelectedChallengeId(dataToDisplay.id);
                          setSelectedChallengePosition(dataToDisplay.position);
                          setSelectedChallengeName(dataToDisplay.infos[0]);
                          setVisible(true);
                        }}
                      />
                  </td>
                )}
                {dataType === 'rewards' && (
                  <td style={{display:'flex', marginRight:10}}>
                    <input
                      style={{color:'rgb(169, 46, 12)', width:80}}
                      type='text'
                      defaultValue=""
                      className="input-text"
                      placeholder={dataToDisplay.position}
                      onClick={()=> {
                        setSelectedRewardId(dataToDisplay.id);
                        setSelectedRewardPosition(dataToDisplay.position);
                        setSelectedRewardName(dataToDisplay.infos[0]);
                        setVisible(true);
                      }}
                    />
                  </td>
                )}

                {isAction  && (
                  <td>
                    <Link to={`/${type}/edit/${dataToDisplay.id}`} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', alignItems: "center", lineHeight: allowDelete ? 'inherit' : 2.75}}>
                      <p>{actionUpdate}</p>
                      <img alt="" style={{width:14, height:14, margin:0, marginRight:10}} src='/images/pen-icon.svg'/>
                    </Link>
                    {allowDelete && <div onClick={() => {setDisplayDelete(true);setEntityToDelete(dataToDisplay.id)}} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, cursor:'pointer'}}>
                      <p>Supprimer</p>
                      <img alt="" style={{width:14, height:14, margin:0, marginRight:10}} src='/images/cross-icon.svg'/>
                    </div>}
                    {commande === true ? <Link>Commande</Link> : ''}
                  </td>
                )}
                {isActiveLink && (
                  <td>
                    <Link to={`/classements/1/${dataToDisplay.id}`} style={{ margin:'0 auto', lineHeight: deleteLineHeight}}>
                      <img alt="" style={{width:14, height:14, margin:0, marginRight:10, marginLeft: 10}} src='/images/search-icon.svg'/>
                    </Link>
                  </td>
                )}

              </tr>
            )
          }) : <div className="font-weight-bold">Tableau Vide </div>}
          </tbody>
        </table>

        {displayDelete && <div style={{width:'100vw', height:'100vh', backgroundColor:'white', zIndex:999, display:"flex", justifyContent:'center', alignItems:'center', position:"absolute",top:0,left:0}}>
          <div style={{backgroundColor:'#BBBBBB', height:100, width:300, borderRadius:10, padding:20, display:"flex", flexDirection:'column', justifyContent:'space-around'}}>
            <div>Veuillez confirmer la suppression</div>
            <div>
              <buton style={{marginRight:10}} className={'button button-shadow'} onClick={() => {deleteEntity(dataType, entityToDelete)}}>Confirmer</buton>
              <buton onClick={() => {setDisplayDelete(true)}} style={{marginLeft:10}} className={'button button-shadow'}>Annuler</buton>
            </div>
          </div>
        </div>}
        <Modal
          visible={visible}
          footer={null}
          onOk={e => {setVisible(false)}}
          onCancel={e => {setVisible(false)}}
        >
          {
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <h3>
                Position(*) de : "{dataType === 'challenges' ? selectedChallengeName : selectedRewardName}
              }"
              </h3>
              <div style={{fontSize:'smaller', fontStyle:'italic', textAlign:'center', marginBottom:10}}>
                (*) Dans l'appli mobile, les {dataType === 'challenges' ? dataType : 'dotations'} sont classé{dataType === 'challenges' ? '' : 'e'}s de la position la plus élevée à la moins élévée
              </div>
              <input
                style={{color:'rgb(169, 46, 12)', width:100}}
                type='text'
                autoFocus={true}
                value={enteredPosition}
                className="input-text"
                placeholder={dataType === 'challenges' ? selectedChallengePosition : selectedRewardPosition}
                onChange={(elem) =>  setEnteredPosition(elem.target.value)}
              />
              {enteredPosition !== "" &&
                <div
                  style={{width: 100, display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: 20}}>
                  <FontAwesomeIcon
                    style={{cursor: 'pointer', color: 'rgb(169, 46, 12)', fontSize: 'x-large'}}
                    icon={faTimes}
                    onClick={() => {
                      setEnteredPosition('');
                      setVisible(false);
                    }}
                  />
                  <FontAwesomeIcon
                    style={{cursor: 'pointer', color: 'green', fontSize: 'x-large'}} icon={faCheck}
                    onClick={() => {
                      if (dataType === 'challenges') saveChallengePosition(selectedChallengeId, enteredPosition)
                        else if (dataType === 'rewards') saveRewardPosition(selectedRewardId, enteredPosition)
                      setTimeout(()=>{
                        reloadFunction();
                      }, 200)
                    }}
                  />
                </div>
              }
            </div>
          }
        </Modal>
      </div>
      { dataType !== 'rewards' && type !== 'classementNoPager' &&
        <div className='pagination' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '100%'}}>
          <Pagination datas={datas} url={`/${type}/`} match={match} />
        </div>
      }
    </>
  )
};

// == Export
export default Listing;
