// == Import : npm
import React from 'react';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';
import { useTranslation } from 'react-i18next';

// == Composant
const Home = ({ location, match, datas, waitingRequest, getTools, deleteEntity, connected }) => {

    const [ t ] = useTranslation();

    return (
    <>
    <Header currentMenuItem={0} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>{t('dashboard')}</h1>
        </div>
    </div>
    </>
    )
};

// == Export
export default Home;
