// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ImagePicker from '../../components/ImagePicker';

// Action Creators
import { streamLoading } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state['media_objects'],
  waitingRequest: state.waitingRequest,
  //connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  streamLoading: (page, params) => {
    dispatch(streamLoading('media_objects', page, params, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImagePicker);