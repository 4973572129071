// == Import : npm
import React from 'react'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading'

// == Import : local
import '../../style/style.scss'

// == Composant
const Reconnect = ({ waitingRequest, title = "Vous n'êtes plus connecté.", msg = "Vous avez été déconnecté du back office pour des raisons de sécurité, veuillez s'il vous plaît", btn = 'vous reconnecter.' }) => {
  if (waitingRequest > 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
        <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
      </div>
    )
  }

  return (
    <>
      {/* <Header currentMenuItem={6} entries={boEntries} location={location} match={match} /> */}
      <div className='page-wrapper' style={{ minHeight: 'calc(100vh - 300px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='grey-background' style={{ width: 'fit-content', margin: 'auto' }}>
          <h2 style={{ color: '#A92E0C' }}>{title}</h2>
          <div className='mt-4' style={{ fontWeight: 'bold', fontSize: '13px', marginTop: '13px' }}>
            {msg}
            <u>
              <Link className="" to={'/'} activeClassName="active">{btn}</Link>
            </u>
          </div>
        </div>
      </div>

    </>
  )
}

// == Export
export default Reconnect
