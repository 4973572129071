// == Import : npm
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Listing from '../../containers/Listing'
import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Faq = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected }) => {

    useEffect(() => {
        getAll(match.params.page)
    }, [match.params.page]) // eslint-disable-line react-hooks/exhaustive-deps

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }
    console.log('datas', datas)

    return (
    <>
    <Header currentMenuItem={14} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Faq</h1>
            <Link to='/faq/ajout' className='button button-shadow' style={{backgroundColor:'#AB3217', color:'white', padding:'10px 30px', height:'fit-content'}}>Créer</Link>
        </div>
        {datas && <Listing
            type={'faq'}
            dataType={'faqs'}
            match={match}
            datas={datas}
            //titles={['Code', 'Pays', 'Région', 'Département', 'Ville', 'Distance', 'Actif']}
            titles={['Question', 'Réponse']}
            datasToDisplay={
                datas['hydra:member'].map(element => ( {
                    id:element['@id'].split('/')[3],
                    infos :[
                    element.question,
                    `${element.answer && element.answer.replaceAll(/(<([^>]+)>)/gi, "").slice(0, 20)}...`,
                    ]
                })
            )}
        />}
    </div>
    </>
    )
};

// == Export
export default Faq;
