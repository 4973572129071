// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import UtilisateursAjout from '../../screen/UtilisateursAjout';

// Action Creators
import { getAll, post } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  cities: state.cities,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  post: (
    email = '',
    firstname = '',
    lastname = '',
    birthdate = '',
    country = '',
    city = '',
    zipCode = '',
    address = '',
    address2 = '',
    password = '',
    roles = '',
    balance = 0,
    status = '',
    civilTitle = '',
    nationality = '',
    phone = '',
    informationAgree=0,
    newsletter='false',
  ) => {
    dispatch(
      post(
        'users',
        {
          email,
          firstname,
          lastname,
          birthdate,
          address: {
            country,
            city,
            zipCode,
            address,
            address2,
          },
          password,
          roles: [roles],
          balance: parseInt(balance),
          status,
          civilTitle,
          nationality,
          phone,
          informationAgree,
          newsletter,
        },
        ownProps.history
      )
    );
  },
  getAll: (type, page, params) => {
    dispatch(getAll(type, page, params));
  },
});

// Container
export default connect(mapStateToProps, mapDispatchToProps)(UtilisateursAjout);
