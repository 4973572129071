// == Import : npm
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Listing from '../../containers/Listing'

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Produits = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected }) => {

    /*useEffect(() => {
        console.log(connected)
        if(connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN')
        {
            console.log('getTools')
            getTools(match.params.page);
        }
    }, []);
    console.log('datas id', datas['id'])*/

    /*if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    } else if(datas['@id'] != undefined && datas['@id'] != '/api/tools') {
        getTools(match.params.page);
   }*/
    useEffect(() => {
        getAll('products')
    }, [match.params.page]) // eslint-disable-line react-hooks/exhaustive-deps

    if(waitingRequest > 0 /*|| connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={7} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Produits</h1>
            <Link to='/produits/ajout' className='button button-shadow' style={{backgroundColor:'#AB3217', color:'white', padding:'10px 30px', height:'fit-content'}}>Créer</Link>
        </div>
        {datas && <Listing
            dataType={'products'}
            type={'produits'}
            match={match}
            datas={[]}
            titles={['titre', 'coût', 'volants']}
            datasToDisplay={
                datas['hydra:member'].map(element => ( {
                    id:element['@id'].split('/')[3],
                    infos :[
                    element.title,
                    (element.cost/100).toString().concat(' €'),
                    element.wheels,
                    ]
                })
            )}
        />}
    </div>
    </>
    )
};

// == Export
export default Produits;
