// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";

import { useHistory, Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';


// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';
import Axios from 'axios';
import {getCountryList} from "../../utils/countryList";

  registerLocale('fr', fr)


// == Composant
const UtilisateursEdit = ({ location, match, data, waitingRequest, patch, getOne, connected }) => {
    const history = useHistory()

    const [id, setId] = useState();
    const [email, setEmail] = useState(null)
    const [firstname, setFirstname] = useState(null)
    const [lastname, setLastname] = useState(null)
    const [birthdate, setBirthdate] = useState(new Date('01/01/1990'))
    const [country, setCountry] = useState(null)
    const [team, setTeam] = useState(null);
    const [city, setCity] = useState(null)
    const [zipCode, setZipCode] = useState(null)
    const [address, setAddress] = useState(null)
    const [address2, setAddress2] = useState(null)
    const [password, setPassword] = useState(null)
    const [roles, setRoles] = useState(null)
    const [balance, setBalance] = useState(null)
    const [status, setStatus] = useState(null)
    const [civilTitle, setCivilTitle] = useState('')
    const [countryList, setCountryList] = useState([])
    const [nationality, setNationality] = useState('')
    const [phone, setPhone] = useState('')
    const [statusParticipated, setStatusParticipated] = useState(null)
    const [statusBoughtWheels, setStatusBoughtWheels] = useState(null)
    const [newsletter, setNewsletter] = useState(false);
    const [agreeInformation, setAgreeInformation] = useState(false);
    const [totalCommandes, setTotalCommandes] = useState(0)

    useEffect(() => {
        getOne(match.params.id)
        setCountryList(getCountryList())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if(data) {
            console.log('Hi im your data', data)
            setId(data.id);
            //setId(data.id)
            setEmail(data.email)
            setFirstname(data.firstname)
            setLastname(data.lastname)
            setBirthdate(new Date(data.birthdate))
            setCountry(data.address.country)
            setCity(data.address.city)
            setZipCode(data.address.zipCode)
            setAddress(data.address.address)
            setAddress2(data.address.address2)
            setPassword(data.password)
            setRoles(data.roles[0])
            setBalance(data.balance)
            setStatus(data.status)
            setCivilTitle(data.civilTitle)
            setNationality(data.nationality)
            setPhone(data.phone)
            setStatusParticipated(data.statusParticipated)
            if(data.hasOwnProperty('statusBoughtWheels')) {
                setStatusBoughtWheels(data.statusBoughtWheels)
                getTotalCommandes();
            }
            if(data.hasOwnProperty('newsletter')) {
                setNewsletter(data.newsletter)
            }
            if(data.hasOwnProperty('informationAgree')) {
                setAgreeInformation(data.informationAgree)
            }
            if(data.hasOwnProperty('team')) {
                setTeam(data.team)
            }
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    const reconnect = (roles, id) => {
        if(connected !== (undefined || null)) {
            if(connected.hasOwnProperty("user")) {
                if(!connected.user.roles.includes(roles) && connected.user.id === id)
                    history.push('/reconnect');
            } else if(connected.hasOwnProperty("roles")) {
                /*if(!connected.roles.includes(roles))
                     history.push('/reconnect');*/
            }
        }
    }

    const getTotalCommandes = () => {
        Axios.get(`${process.env.REACT_APP_API_URL}/api/receipts?type=wheels&groups[]=receipt:admin:listing&user.id=${match.params.id}`, {withCredentials:true}).then(
             (response) => {
                let prix = 0;
                response.data['hydra:member'].forEach(element => {
                    prix += element['baseCost'] / 100;
                });
                setTotalCommandes(prix);
        });
    }

    if(waitingRequest > 0 /*|| connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={1} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Utilisateurs</h1>
        </div>
        <div className="form-wrapper">
                {team != null ?
                <div className='fieldset-wrapper form-row'>
                    <label className='mb-5'>Team :</label>
                    <div className='mr-5 mb-3 col-md-9'>
                        <div className='pays ml-5' style={{color:  team.serie.color2, backgroundColor: team.serie.color1}}>{team.number}</div>
                        <div className='ml-4 small'>{team.type === 'default' ? ('Sans achat du numéro') : team.type === 'paid' ? ('Achat du numéro') : ('')}</div>
                    </div>
                </div>
                : ''}

            <div className='fieldset-wrapper form-row'>
                <label className='half-row'>Email
                    <input value={email} onChange={(e) => {setEmail(e.target.value)}} type='text' className="input-text" placeholder='Email'/>
                </label>
                <label className='half-row'>Mot de passe
                    <input value={password} onChange={(e) => {setPassword(e.target.value)}} type='text' className="input-text" placeholder='Mot de passe'/>
                </label>
            </div>
            <div className='fieldset-wrapper form-row'>
                <div className='row'>
                    <label className='col'>Civilité
                        <select type='text' className="input-text" value={civilTitle} onChange={e => setCivilTitle(e.target.value)}>
                            <option value='' disabled>Select</option>
                            <option value='m'>M.</option>
                            <option value='mme'>Mme</option>
                            <option value='mle'>Mlle</option>
                        </select>
                    </label>
                    <label className='col'>Nom
                        <input value={lastname} onChange={(e) => {setLastname(e.target.value)}} type='text' className="input-text" placeholder='Nom'/>
                    </label>
                    <label className='col'>Prénom
                        <input value={firstname} onChange={(e) => {setFirstname(e.target.value)}} type='text' className="input-text" placeholder='Prénom'/>
                    </label>
                    <label className='col'>Rôle
                        <select type='text' className="input-text"value={roles} onChange={e => setRoles(e.target.value)}>
                            <option value='ROLE_USER'>Utilisateur</option>
                            <option value='ROLE_ADMIN'>Admin</option>
                            <option value='ROLE_PARCOURS'>Parcours</option>
                            <option value='ROLE_COMMUNICATION'>Communication</option>
                        </select>
                    </label>
                </div>
            </div>
            <div className='fieldset-wrapper row'>
                <div>
                    <label>Date de naissance</label>
                    <DatePicker
                        className='button date-picker'
                        selected={birthdate}
                        onChange={(e) => setBirthdate(e)}
                        locale="fr"
                        dateFormat='dd/MM/yyyy'
                    />
                </div>
                <label className='col'>Nationalité
                    <select type='text' className="input-text" value={nationality} onChange={e => setNationality(e.target.value)}>
                        <option value='' disabled>Select</option>
                        {countryList.map((item, idx)=> <option key={idx} value={item.key}>{item.label}</option>)}
                    </select>
                </label>
            </div>

            <div className='fieldset-wrapper form-row'>
                <label style={{width:'33.33%',marginRight:15}}>Pays
                    <select type='text' className="input-text mt-2" value={country} onChange={e => setCountry(e.target.value)}>
                        <option value='' disabled>Select</option>
                        {countryList.map((item, idx)=> <option key={idx} value={item.key}>{item.label}</option>)}
                    </select>
                </label>
                <label style={{width:'33.33%',marginRight:15}}>Ville
                    <input value={city} onChange={(e) => {setCity(e.target.value)}} type='text' className="mt-2 input-text" placeholder="Ville"/>
                </label>
                <label className="mt-4" style={{width:'33.33%',marginRight:15}}>Code postal
                    <input value={zipCode} onChange={(e) => {setZipCode(e.target.value)}} type='text' className="mt-2 input-text" placeholder="Code postal"/>
                </label>
            </div>
            <div className='fieldset-wrapper row'>
                <label className='col'>Addresse
                    <input value={address} onChange={(e) => {setAddress(e.target.value)}} type='text' className="input-text" placeholder="Addresse"/>
                </label>
                <label className='col'>Complément d'adresse
                    <input value={address2} onChange={(e) => {setAddress2(e.target.value)}} type='text' className="input-text" placeholder="Complément d'adresse"/>
                </label>
                <label className='col'>
                    Tel mobile
                    <InputMask value={phone} onChange={(e) => {setPhone(e.target.value)}} className="input-text" mask="(+99) 99 99 99 99" maskChar="_" placeholder="Tel mobile"/>
                </label>
            </div>
            <div className='fieldset-wrapper form-row'>
                <label className='half-row'>Volants
                    <input value={balance} onChange={(e) => {setBalance(e.target.value)}} type='number' step='1000' className="input-text" placeholder="Volants"/>
                </label>

            {statusBoughtWheels != null ?
                <label>Total des commandes <input type="textbox" className="form-control  mt-2" value={`${totalCommandes}€`} readOnly="readonly"/> </label>
            : ''}

                <label className='half-row'>Statut du compte
                <select type='text' className="input-text"value={status} onChange={e => setStatus(e.target.value)}>
                    <option value='valid'>Valide</option>
                    <option value='disabled'>Désactivé</option>
                    <option value='deleted'>Supprimé / Banni</option>
                    <option value='activationPending'>En attente d'activation</option>
                </select>
                </label>
            </div>
            <div className='fieldset-wrapper'>
                <div className="row mt-2">
                    <div>
                        <label className='col'>
                            Participation à un parcours
                        </label>
                    </div>
                    <div style={{color: 'black'}}>
                        {statusParticipated === 'yes' ? 'Oui' : 'Non'}
                    </div>
                    <div className="ml-5 mr-3">
                        <label className='col'>Statut d’achat de volants</label>
                    </div>
                    <div style={{color: 'black'}}>{statusBoughtWheels === 'yes' ? 'Oui' : 'Non'}</div>
                </div>
            </div>
            <div className='fieldset-wrapper'>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <Link className="ml-2 mt-5" to={"/classements/user/1/"+id}><button className="button button-shadow" type="button">Accéder à ses classements</button></Link>
                        <Link className="ml-5 mt-5" to={"/participations/1/"+id}><button className="button button-shadow" type="button">Accéder à ses participations</button></Link>
                    </div>

                </div>
            </div>
            <div className='fieldset-wrapper'>
                <div className="row mt-4">
                    <div className="form-check">
                        <input className="mt-4 form-check-input" id="agreeInformation" type="checkbox" onChange={e => {setAgreeInformation(e.currentTarget.checked); console.log(e.currentTarget.checked)}} defaultChecked={agreeInformation/* == 1 ? true : false-*/} />
                        <label className="mt-1 form-check-label" htmlFor="flexCheckChecked">
                            J'accepte que mes coordonnées soient transmises
                        </label>
                    </div>
                    <div className="ml-5 form-check">
                        <input className="mt-4 form-check-input" id='toystories' onChange={e => setNewsletter(e.currentTarget.checked)} type="checkbox"  defaultChecked={newsletter} />
                        <label className="mt-1 form-check-label" htmlFor="flexCheckDefault">
                            Toystories
                        </label>
                    </div>
                </div>
            </div>

            <div className='fieldset-wrapper form-row'>
                <button className='button button-shadow mt-2'
                onClick={() => {
                    console.log('agreeInformation',agreeInformation);
                    patch(email, firstname, lastname, birthdate, country, city, zipCode, address, address2, password, roles, balance, status, civilTitle, nationality, phone, agreeInformation, newsletter);
                    reconnect(roles, id);
                }
                }>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default UtilisateursEdit;
