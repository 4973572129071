// == Import : npm
import React, { useState } from 'react';

// == Import : local
import '../../style/style.scss';

// == Composant
const ListingSeries = ({ location, match, datas, waitingRequest, deleteEntity, titles, datasToDisplay, type, dataType, orderFunction, order, modifClick }) => {

    const [displayDelete, setDisplayDelete] = useState(false)
    const [entityToDelete, setEntityToDelete] = useState(null)


    return (
    <>
    <div className='table-wrapper'>
            <table>
                <thead>
                    <tr>
                        {titles.map((element, index) => {
                            if(dataType === "series" && element === "nom") {
                                return (
                                    <th key={index} className={'sortable ' + order.name} onClick={() => orderFunction(element)}>{element}</th>
                                    )
                            } else {
                                return(<th key={index}>{element}</th>)
                            }
                        }
                        )}
                        <th key="action">action</th>
                    </tr>
                </thead>
                <tbody>
                    {datasToDisplay.map((dataToDisplay, index) => {
                      return (
                        <tr key={index}>
                          {dataToDisplay.infos.map(element => (
                            <td style={{width:`${100/dataToDisplay.infos.length}%`}}>{element}</td>
                          ))}
                            <td>
                                <div onClick={() => {modifClick(dataToDisplay)}} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto'}}>
                                    <p>Modifier</p>
                                    <img alt="" style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg' />
                                </div>
                                <div onClick={() => {setDisplayDelete(true); setEntityToDelete(dataToDisplay.id);}} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, cursor:'pointer'}}>
                                    <p>Supprimer</p>
                                    <img alt="" style={{width:14, height:14, margin:0}} src='/images/cross-icon.svg' />
                                </div>
                            </td>
                        </tr>
                      )
                    })}
                </tbody>
            </table>

            {displayDelete && <div style={{width:'100vw', height:'100vh', backgroundColor:'white', zIndex:999, display:"flex", justifyContent:'center', alignItems:'center', position:"absolute",top:0,left:0}}>
                <div style={{backgroundColor:'#BBBBBB', height:100, width:300, borderRadius:10, padding:20, display:"flex", flexDirection:'column', justifyContent:'space-around'}}>
                    <div>Veuillez confirmer la suppression</div>
                    <div>
                        <buton style={{marginRight:10}} className={'button button-shadow'} onClick={() => {deleteEntity(dataType, entityToDelete); console.log(dataType, entityToDelete);}}>Confirmer</buton>
                        <buton onClick={() => {setDisplayDelete(false)}} style={{marginLeft:10}} className={'button button-shadow'}>Annuler</buton>
                    </div>
                </div>
            </div>}
        </div>
    </>
    )
};

// == Export
export default ListingSeries;
