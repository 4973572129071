// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Tooltips from '../../screen/Tooltips';

// Action Creators
import { getAll, deleteEntity } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.tooltips,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAll: (page) => {
    dispatch(getAll('tooltips', page, ownProps.history));
  },
  deleteEntity: (id) => {
    dispatch(deleteEntity('tooltips', id, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tooltips);