// == Import : npm
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { compose, withProps } from "recompose"

const GOOLE_KEY = 'AIzaSyD5uips6tDA-6IlaUjNGSq2hyv0DMl19eQ'

const MyMapComponent = compose(
    withProps({
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOLE_KEY}&v=3.exp&libraries=geometry,drawing,places`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `100vh` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
  <GoogleMap
    bootstrapURLKeys={{key:GOOLE_KEY}}
    defaultCenter={{ lat: 45, lng: 5 }}
    defaultZoom={6}
    //   onClick={event => handleClick(event, pointModif)}
  >
      {props.datas.map(element => (
          <Marker position={{ lat: parseFloat(element.latitude), lng: parseFloat(element.longitude) }} onClick={() => {window.open('/parcours/edit/' + element['@id'].split('/')[3], "_blank")}} title={element.code}/>
      ))}
  </GoogleMap>
)

// == Composant
const ParcoursMap = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected }) => {
    useEffect(() => {
        getAll(1)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    console.log(datas)


    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }
  //console.log('retourWaypoints', retourWaypoints)
    return (
    <div style={{height:'100vh', width:'100vw'}}>
        <MyMapComponent datas={datas ? datas['hydra:member'] : []} />
    </div>
    )
};

// == Export
export default ParcoursMap;
