// == Import : npm
import React from 'react';

// == Import : local
import '../../style/style.scss';

// == Composant
const FileUpload = ({accept, inputText, OnClickDo, buttonClass, buttonStyle }) => {

    let inputElement = null;

    return (
        <>
            <input ref={input => {inputElement = input}} style={{display:'none'}} type="file" name="file"
            accept={accept} onChange={(e) => {
                accept.split(',').forEach((element) => {
                    if(element === element.replace(/\s+/g, '')){
                        OnClickDo(e)
                    }
                })
            }} />
            <div className={buttonClass} style={buttonStyle} onClick={() => {inputElement.click()}}>{inputText}</div>
       </>
    );
};

// == Export
export default FileUpload;
