import countries from "i18n-iso-countries";

export function getCountryList() {
  countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
  const countriesObject = countries.getNames('fr', { select: "official" })

  let countriesArray = Object.entries(countriesObject)
  let finalArray = []
  countriesArray = countriesArray.map(element => ({
    key: element[0],
    label: `${element[0].toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))} ${element[1]}`,
    value: element[1] }))

  const favCountries = ["FR", "GB", "BR", "CA", "US", "DE", "AT", "BE", "BG", "CY", "HR", "DK", "EE", "ES", "FI", "GR",
    "HU", "IE", "IT", "LV", "LT", "LU", "MT", "MC", "NL", "PL", "PT", "RO", "SI", "SK", "SE", "CH", "EST"]

  countriesArray = countriesArray.filter(element => {
    if (favCountries.find(e => e === element.key) !== undefined) {
      return true
    }
    else {
      finalArray.push(element)
      return false
    }
  })
  countriesArray = [...countriesArray, ...finalArray]

  return countriesArray
}
