// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Parcours from '../../screen/Parcours';

// Action Creators
import { getAll, deleteEntity } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.courses,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAll: (page, params) => {
    dispatch(getAll('courses', page, params, ownProps.history));
  },
  deleteEntity: (id) => {
    dispatch(deleteEntity('courses', id, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Parcours);