// == Import : npm
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import Listing from '../../containers/Listing'
import { boEntries } from '../../utils/headerEntries.js';
import moment from 'moment';



// == Composant
const News = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected }) => {

    useEffect(() => {
        getAll('news')
    }, [match.params.page]) // eslint-disable-line

    if(waitingRequest > 0 ) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={15} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>News</h1>
            <Link to='/news/ajout' className='button button-shadow' style={{backgroundColor:'#AB3217', color:'white', padding:'10px 30px', height:'fit-content'}}>Créer</Link>
        </div>
        {datas && <Listing
            dataType={'news'}
            type={'news'}
            match={match}
            datas={datas}
            titles={['titre', 'date', 'content']}
            datasToDisplay={
                datas['hydra:member'].map(element => ( {
                    id:element['@id'].split('/')[3],
                    infos :[
                    element.title,
                    moment(element.publishedAt).format('DD/MM/YYYY'),
                    element.content.replace(/(<([^>]+)>)/gi, "").substring(0, 50).concat(' …')
                    ]
                })
            )}
        />}
    </div>
    </>
    )
};

// == Export
export default News;
