// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import Axios from 'axios';
// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Listing from '../../containers/Listing'
import { boEntries } from '../../utils/headerEntries.js';
import moment from 'moment';

// == Composant
const Classements = ({ location, match, datas, waitingRequest, getAll}) => {
	const [challenges, setChallenges] = useState([]);
	const [challenge, setChallenge] = useState("1");
	const [deduplicateData, setDeduplicateData] = useState([]);
	let dataToDisplay;
	let currentPage;
	if (datas !== undefined) {
		dataToDisplay = [...datas['hydra:member']];
		currentPage = datas['hydra:view']['@id'].split('page=')[1]
			? parseInt(datas['hydra:view']['@id'].split('page=')[1])
			: 1;
	}

	const getChallenges = () => {
		Axios.get(`${process.env.REACT_APP_API_URL}/api/challenges/list`, { withCredentials: true })
			.then((response) => {
				setChallenges(response.data);
				setChallenge(match.params?.id);
			});
	};

	let getChallengeGlobalRanking = () => {
		Axios.get(`${process.env.REACT_APP_API_URL}/api/score/global/challenge/${challenge}`, { withCredentials: true })
			.then((response) => {
				setDeduplicateData(response.data.globalRank);
			});
	}

	/**UseEffect d'initialisation*/
	useEffect(() => {
		getChallenges();
	},[]) //eslint-disable-line

	/**UseEffect de changement de challenge*/
	useEffect(() => {
		let url = `challenge=${challenge}`;
		if(match.params.user !== undefined) {
			url += `&user=${match.params.user}`;
		}
		getAll(1, url);
		match.params.page = 1;
		setDeduplicateData([]);
	}, [challenge]); //eslint-disable-line

	/**UseEffect de changement de page*/
	useEffect(() => {
		let url = `challenge=${challenge}`;
		if(match.params.user !== undefined) {
			url += `&user=${match.params.user}`;
		}
		getAll(match.params?.page, url);
	}, [match.params.page]); //eslint-disable-line

	if(waitingRequest > 0) {
		return (
			<div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
				<ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
			</div>
		)
	}

	return (
		<>
			<Header currentMenuItem={6} entries={boEntries} location={location} match={match} />
			<div
				className='page-wrapper'
				style={{
					paddingLeft:'20%',
					paddingRight:'20%'
				}}>
				<div
					style={{
						margin:'100px 0 0 0',
					}}>
					<h1 className='page-title'>Classements</h1>
				</div>
				<div
					style={{
						display:'flex',
						flexDirection:'row',
						justifyContent:'center',
						alignItems:'center',
						width:'100%'
					}}>
					<div
						style={{
							display:'flex',
							flex:1,
							flexDirection: 'column',
							alignItems:'center',
							justifyContent:'start',
							width:'50%',
							padding:4,
							margin:4
						}}>
						<label style={{margin:4}}>Filtrer sur ce challenge : </label>
						<select
							style={{margin:4}}
							type="text"
							className="input-text"
							value={challenge ? challenge : '--'}
							onChange={(e) => setChallenge(e.target.value)}
						>
							{challenges.map((challenge) => (
								<option
									key={challenge?.id}
									value={challenge?.id}
								>
									{challenge?.title}
								</option>
							))
							}
						</select>
						<div
							className="button button-shadow"
							style={{display:'flex', alignItems:'center', justifyContent:'center', margin:4}}
							onClick={() => {
								if (deduplicateData.length === 0) {
									match.params.page = 1;
									getChallengeGlobalRanking()
								} else {
									match.params.page = 1;
									setDeduplicateData([]);
								}
							}}
						>
							{deduplicateData.length === 0
								? "Afficher sans les doublons (mode APPLI)"
								: "Afficher avec les doublons (mode BO)"
							}
						</div>
					</div>
					<div
						style={{
							display:'flex',
							flexDirection: 'column',
							alignItems:'center',
							justifyContent:'center',
							width:'50%',
							padding:4,
							margin:4
						}}>
						<a
							className="button button-shadow"
							style={{display:'flex', alignItems:'center', justifyContent:'center'}}
							href={`${process.env.REACT_APP_API_URL}/api/scores/export/csv/${challenge}`}
						>Export CSV</a>
						<div
							style={{
								paddingTop:30,
								fontWeight:'bold'
							}}>
							URL ORGANISATEURS :
						</div>
						<div style={{paddingTop:10, fontSize:12}}>
							{process.env.REACT_APP_API_URL}/private/scores/{challenge}/{btoa(challenge+'_grantrofeo_CH-'+challenge)}
						</div>
					</div>
				</div>
				{datas &&
					<Listing
						type={deduplicateData.length === 0 ? 'classements' : 'classementNoPager'}
						dataType={'classements'}
						match={match}
						datas={datas}
						titles={deduplicateData.length === 0
							? [
								'rang',
								'nationalité',
								`n° equipage`,
								'equipage',
								'pilote',
								'email',
								'date de réalisation',
								'delta',
								'diff n-1',
								'diff winner'
							] : [
								'rang',
								'nationalité',
								`n° equipage`,
								'equipage',
								'date de réalisation',
								'delta',
								'diff n-1',
								'diff winner'
							]}
						isAction={false}
						datasToDisplay={
							deduplicateData.length === 0
								? dataToDisplay?.map((element, index) => ({
									id:element['@id'].split('/')[3],
									stylePays: element.user.hasOwnProperty('team') && element.user.team != null ?  [element.user.team.serie.color1, element.user.team.serie.color2] : ['white'],
									infos :[
										(index + 1) + 15*(currentPage - 1),
										element.user.nationality,
										element.user.hasOwnProperty('team') && element.user.team != null ? element.user.team.number : "--",
										element.hasOwnProperty('copilot') && element.copilot != null ? `${element?.user?.firstname ?? '--'}/${element?.copilot?.firstname}` : `${element?.user?.firstname ?? '--'}`,
										`${element.user.firstname} ${element.user.lastname}`,
										element.user.hasOwnProperty('email') ? element.user.email : '',
										element.hasOwnProperty('modifiedAt') ? moment(element.modifiedAt).format('DD/MM/Y HH:mm') : moment(element.createdAt).format('DD/MM/Y, HH:mm'),
										element.delta.toFixed(10),
										index !== 0 ? (element.delta - dataToDisplay[index-1].delta).toFixed(10) : ' - ',
										index !== 0 ? (element.delta - dataToDisplay[0].delta).toFixed(10) : ' - ',
									]
								})
							) : deduplicateData?.map((element, index) => ({
							id:element.scoreId,
							stylePays: element.hasOwnProperty('team') && element.team != null ? [element.team.serie.color1, element.team.serie.color2] : ['white'],
							infos :[
								(index + 1) + 15*(currentPage - 1),
								element.address.country,
								element.hasOwnProperty('team') && element.team != null ? element.team.number : "--",
								element.hasOwnProperty('copilot') && element.copilot != null
									? `${element?.pilot ?? '--'}/${element?.copilot}`
									: `${element?.pilot ?? '--'}`,
								element.hasOwnProperty('modifiedAt') ? moment(element.modifiedAt).format('DD/MM/Y HH:mm') : moment(element.createdAt).format('DD/MM/Y, HH:mm'),
								parseFloat(element.delta).toFixed(10),
								index !== 0 ? (parseFloat(element.delta) - deduplicateData[index-1].delta).toFixed(10) : ' - ',
								index !== 0 ? (parseFloat(element.delta) - deduplicateData[0].delta).toFixed(10) : ' - ',
							]
						})
					)}
					/>
				}
			</div>
		</>
	)
};

// == Export
export default Classements;
