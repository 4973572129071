// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Listing from '../../components/Listing';

// Action Creators
import { deleteEntity } from '../../store/reducer';
import { saveChallengePosition } from '../../store/reducer';
import { saveRewardPosition } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  // datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteEntity: (type, id) => {
    dispatch(deleteEntity(type, id, ownProps.history));
  },
  saveChallengePosition: function (type, id, position) {
    dispatch(saveChallengePosition(type, id, position))
  },
  saveRewardPosition: function (type, id, position) {
    dispatch(saveRewardPosition(type, id, position))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Listing);
