import React from 'react';
import ReactLoading from 'react-loading';
import '../../style/style.scss';
import Header from '../../containers/Header';
import { boEntries } from '../../utils/headerEntries.js';
import MobileTranslation from '../../components/MobileTranslation';

// == Composant
const Traduction = ({ location, match, waitingRequest, upload, getFeatLang, featLang }) => {
    if(waitingRequest > 0) {
        return (
        <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
            <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
        </div>
        )
    }

    return (
        <>
        <Header currentMenuItem={11} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
            <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
                <h1 className='page-title'>Traduction statique de l'application</h1>
            </div>
            <MobileTranslation upload={upload} getFeatLang={getFeatLang} datas={featLang} />
        </div>
        </>
    )
    };

// == Export
export default Traduction;
