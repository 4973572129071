// == Import : npm
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import { ChromePicker } from 'react-color'

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import ListingSeries from '../../containers/ListingSeries'

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Numeros = ({ location, match, datas, waitingRequest, post, deleteEntity, connected, getAll, patch }) => {

    const [colorFond, setColorFond] = useState('#FFF')
    const [selectFond, setSelectFond] = useState(false)
    const [colorText, setColorText] = useState('#FFF')
    const [selectText, setSelectText] = useState(false)
    const [name, setName] = useState(null)
    const [reservedRangeArray, setReservedRangeArray] = useState(['', '', ''])
    const [order, setOrder] = useState({name:''})

    const [edit, setEdit] = useState(null)


    useEffect(() => {
        getAll()
    }, [getAll])

    const reOrder = () => {
        let url = ''
        let ord = {...order}
        if(ord.name === 'asc') {
            ord.name = 'desc';
            url += '&order[name]=desc';
        } else {
            ord.name = 'asc';
            url += '&order[name]=asc';
        }
        setOrder(ord);
        getAll(match.params.page+url);
    }

    if(waitingRequest > 0 /*|| connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    console.log(reservedRangeArray)
    return (
    <>
    <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Numeros</h1>
        </div>
        <div style={{textAlign:'left', marginBottom: '20px'}}>
            <Link to='/numeros/team-numbers' className='button button-shadow mb-2' style={ {backgroundColor: '#AB3217', color: 'white'} }>Aller aux numéros d'équipe</Link>
        </div>
        <div className="form-wrapper" style={{marginBottom:50}}>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                {/*<input type='text' className="input-text" placeholder='Tarif' style={{width:'45%'}} ></input>*/}
                <input type='text' className="input-text" placeholder='Nom série' style={{width:'45%'}} value={name} onChange={(e) => {setName(e.target.value)}}/>
            </div>

            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <input type='text' className="input-text mr-2" placeholder='Numéro Min. *' style={{width:'20%'}} value={reservedRangeArray[0]} onChange={e => {const temp=reservedRangeArray.slice(); temp[0]=e.target.value; setReservedRangeArray(temp)}}/>
                <input type='text' className="input-text" placeholder='Numéro Max. *' style={{width:'20%'}} value={reservedRangeArray[1]} onChange={e => {const temp = reservedRangeArray.slice(); temp[1]=e.target.value; setReservedRangeArray(temp)}}/>
                <input type='text' className="input-text ml-2" placeholder='Numéros réservés' style={{width:'20%'}} value={reservedRangeArray[2]} onChange={e => {const temp = reservedRangeArray.slice(); temp[2]=e.target.value; setReservedRangeArray(temp)}}/>
                <label className='ml-3'>Couleur Texte</label>
                <div className="border" onClick={() => setSelectFond(!selectFond)} style={{height:40, width:50, borderRadius: 10, backgroundColor:colorFond}}/>
                {selectFond && <ChromePicker color={colorFond} onChange={e => {setColorFond(e.hex);}} />}
                <label className='ml-3'>Couleur Fond</label>
                <div className="border" onClick={() => setSelectText(!selectText)} style={{height:40, width:50, borderRadius: 10, backgroundColor:colorText}}/>
                {selectText && <ChromePicker color={colorText} onChange={e => {setColorText(e.hex);}} />}
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                {edit == null && <button className='button button-shadow' onClick={() => {post(name, reservedRangeArray.join(), colorFond, colorText)}}>Ajouter</button>}
                {edit != null && <button className='button button-shadow' onClick={() => {patch( edit, name, reservedRangeArray.join(), colorFond, colorText, () => {getAll(match.params.page); setEdit(null); setName(null); setReservedRangeArray(['', '', '']); setColorFond('#FFF'); setColorText('#FFF')})}}>Enregistrer</button>}
                {edit != null && <button className='button button-shadow' onClick={() => {setEdit(null); setName(null); setReservedRangeArray(['', '', '']); setColorFond('#FFF'); setColorText('#FFF')}}>Annuler</button>}
            </div>
        </div>

        {datas && <ListingSeries
            type={'series'}
            match={match}
            datas={datas}
            dataType={'series'}
            orderFunction={reOrder}
            order={order}
            modifClick={(e) => {setEdit(e.id); setName(e.infos[0]); setReservedRangeArray([e.infos[1].split(' - ')[0], e.infos[1].split(' - ')[1], e.infos[2]]); setColorFond(e.infos[3].props.style.backgroundColor); setColorText(e.infos[4].props.style.backgroundColor)}}
            titles={['nom', 'rangé', 'réservé', 'Couleur de police', 'Couleur de Fond', 'Attribué']}
            datasToDisplay={
                datas['hydra:member'].map(element => ( {
                    id:element.id,
                    infos :[
                    element.name,
                    (element.reservedRange.split(',')[1] !== undefined ? `${element.reservedRange.split(',')[0]} - ${element.reservedRange.split(',')[1]}` : element.reservedRange.split(',')[0]),
                    element.reservedRange.split(',')[2],
                    <canvas style={{height:15, width:15, backgroundColor:element.color1}}/>,
                    <canvas style={{height:15, width:15, backgroundColor:element.color2}}/>,
                    (element.reservedRange.split(',')[1] !== undefined ?  `${element.serialNumbers.length}/${element.reservedRange.split(',')[1]}` : element.serialNumbers.length)
                    ]
                })
            )}
        />}
    </div>
    </>
    )
};

// == Export
export default Numeros;
