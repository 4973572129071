import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import PlaceSearch from '../../components/PlaceSearch'
const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  backgroundColor: 'white',
  cursor: 'move',
}
export const Card = ({ id, text, index, moveCard, type, onClick, pointModif, changeCoordinates }) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: type,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: type, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div style={{display:'flex', flexDirection:'column', width:'90%' }}>
      <div ref={ref} style={{ ...style, opacity, minHeight:50, height:"content-fit"}} onClick={() => {if(pointModif == null)onClick({type, index})}}>
        {text}
      </div>
      <div style={{backgroundColor:'white', padding:20, borderRadius:10, display:(pointModif && pointModif.type === type && pointModif.index === index) ? '' :'none'}}>
          <PlaceSearch addCoordinates={(location)=>{console.log(type); changeCoordinates(location, index, type); }} />
          <button className='button button-shadow' style={{marginTop:20}} onClick={() => {onClick(null); console.log(pointModif)}}>Annuler</button>
      </div>
    </div>
  )
}
