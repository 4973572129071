// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Home from '../../screen/Home';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  /*Home: (mail, password) => {
    dispatch(Home(mail, password, ownProps.history));
  },*/
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
