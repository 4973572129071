// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Header from '../../components/Header';

// Action Creators
import { setMessageNotif } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  messageNotif: state.messageNotif,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearNotif: () => {
    dispatch(setMessageNotif('', false));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);