// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import SlidesEdit from '../../screen/SlidesEdit';

// Action Creators
import { patch, upload, getOne } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  data: state.oneslides,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patch: (titre='', image = '', content='', titleEn='', descriptionEn='') => {
    dispatch(patch('slides', ownProps.match.params.id, {image, translations:[
      {
        locale: 'fr',
        title: titre,
        content
      },
      {
        locale: 'en',
        title: titleEn,
        content: descriptionEn
      }
    ]}, ()=>{}))
  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
  getOne: (id) => {
    dispatch(getOne('slides', id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SlidesEdit);