// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import FaqAjout from '../../screen/FaqAjout';

// Action Creators
import { post, upload } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({

  post: (question='', answer='', questionEn='', answerEn='') => {
    dispatch(post('faqs', {translations: [
      {
        locale: 'fr',
        question,
        answer
      },
      {
        locale: 'en',
        question: questionEn,
        answer: answerEn
      }
    ]}, (datas) => {
      ownProps.history.push(`/faq/edit/${datas['@id'].split('/').reverse()[0]}`)
    }, ownProps.history));

  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FaqAjout);