import {compose} from "recompose";
import {DirectionsRenderer, GoogleMap, withGoogleMap} from "react-google-maps";
import Geocode from "react-geocode";
import React, {useEffect, useRef} from "react";

const google = window.google
const GOOGLE_KEY = 'AIzaSyD5uips6tDA-6IlaUjNGSq2hyv0DMl19eQ'

export const MapWithDestination = compose(
  withGoogleMap
)(props => {

  const {newPoint, retour, setRetour, waypoints, setWaypoint, setRetourWaypoint, retourWaypoints, displayRetour,
    pointModif, setPointModif, setTypesEtapes, setTypesEtapesRetour, typesEtapes } = props;

  const directionsService = new google.maps.DirectionsService();
  Geocode.setApiKey(GOOGLE_KEY);
  Geocode.setLanguage("fr");

  async function handleClick(event, pointModif)  {
    if(waypoints.filter(element => element.stopover === true).length < 7) {
      await Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
        response => {
          const address = response.results[0].formatted_address;
          let newWaypoints = [...waypoints]

          if(pointModif != null) {
            newWaypoints[pointModif.index] = {
              location:event.latLng,
              address: address,
              stopover:true
            }
            setPointModif(null)
          } else {
            newWaypoints.push({
              location:event.latLng,
              address: address,
              stopover:true
            })
          }
          setWaypoint(newWaypoints)
          let retourPoints = waypoints.reverse();
          setRetourWaypoint([{
            location:event.latLng,
            address: address,
            stopover:true
          }, ...retourPoints])
        },
        error => {
          console.error('handleClick error', error);
        }
      );
    }
  }

  function onDirectionChanges() {
    const directions = reference.current.getDirections();
    setRetour(reference.current.getDirections());
    const temp=[]
    temp.push({location: directions.request.origin.location || directions.request.origin, stopover:true})
    directions.request.waypoints.forEach(element => {
      temp.push({location: element.location.location || element.location, stopover:element.stopover})
    });
    temp.push({location: directions.request.destination.location || directions.request.destination, stopover:true})

    temp.forEach(element => {
      Geocode.fromLatLng(element.location.lat(), element.location.lng()).then(
        response => {
          element.address =  response.results[0].formatted_address;
          setRetourWaypoint(temp)
        },
        error => {
          console.error(error);
        }
      );
    })
    return temp
  }

  useEffect(() => {
    if(waypoints.length>0 && !waypoints[waypoints.length-1].stopover) {
      const temp = waypoints.slice()
      temp.splice(waypoints.length-1, 1)
      setWaypoint(temp)
    }
    if (newPoint != null && waypoints.length > 0) {
      const points = []
      for (let index = 1; index < waypoints.length-1; index++) {
        points.push({location: waypoints[index].location, stopover:waypoints[index].stopover})
      }

      directionsService.route(
        {
          origin :  {location: waypoints[0].location},
          destination: {location: waypoints[waypoints.length-1] != null ? waypoints[waypoints.length-1].location : waypoints[0].location},//destination != null ? destination : origin,//new google.maps.LatLng(41.756795, -78.954298), //destination,
          waypoints: points,
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            props.setDirections(result)
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
      if(waypoints.length>0 && waypoints.filter(element => element.stopover).length-1 > typesEtapes.length) {
        if (typesEtapes.length === 0) {
          setTypesEtapes([...typesEtapes, 'chrono'])
        } else if (typesEtapes.length === 1) {
          setTypesEtapes([...typesEtapes, 'tampon'])
        } else {
          setTypesEtapes([...typesEtapes, ''])
        }
      }
    }
  }, [waypoints]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(retourWaypoints.length>0 && !retourWaypoints[retourWaypoints.length-1].stopover) {
        const temp = retourWaypoints.slice()
        temp.splice(retourWaypoints.length-1, 1)
        setWaypoint(temp)
      }
    if(newPoint !=null && waypoints.length > 1) {
      const points = []
      for (let index = 1; index < retourWaypoints.length-1; index++) {
        points.push({location: retourWaypoints[index].location, stopover:retourWaypoints[index].stopover})
      }

      directionsService.route(
        {
          origin :  {location: retourWaypoints[0]?.location},
          destination:  {location: retourWaypoints[retourWaypoints.length-1]?.location},
          waypoints: points,
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setRetour(result)
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [waypoints, retourWaypoints]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTypesEtapesRetour(typesEtapes.slice())
  }, [waypoints]) // eslint-disable-line react-hooks/exhaustive-deps
  const reference = React.createRef()
  const mapRef = useRef()
  let bounds = new google.maps.LatLngBounds();
  if(props.directions)
    props.directions.routes[0].overview_path.forEach(element => bounds.extend(element))

  if (newPoint !== null && waypoints.length>0) {
    return (
      <>
        <input
          type='button'
          className='button button-shadow'
          value='Centrer la carte sur le parcours'
          style={{marginRight:20, position:'absolute', top:10, left:200}}
          onClick={() => {
            mapRef.current.fitBounds(bounds)
          }}
        />
        <GoogleMap
          ref={ref => mapRef.current = ref}
          bootstrapURLKeys={{key:GOOGLE_KEY}}
          defaultCenter={{ lat: 43.6207956, lng: 7.0589927 }}
          defaultZoom={10}
          onClick={event => handleClick(event, pointModif)}
        >
          {!displayRetour && <DirectionsRenderer
            ref={reference}
            onDirectionsChanged={() => {
              const temp = onDirectionChanges()
              setWaypoint(temp)
            }}
            directions={props.directions}
            options={{
              draggable:true,
              preserveViewport:true
            }}
            markerOptions={{
              draggable:false
            }}
          />
          }
          {displayRetour && <DirectionsRenderer
            ref={reference}
            directions={retour}
            onDirectionsChanged={() => {
              const temp = onDirectionChanges()
              setRetourWaypoint(temp)
            }}
            options={{
              polylineOptions: {
                strokeOpacity: 0.5,
                strokeColor: '#FF0000',
              },
              draggable:true,
              preserveViewport:true

            }}
            markerOptions={{
              draggable:false
            }}
          />}
        </GoogleMap>
      </>)
  } else {
    return(
      <>
        <input
          type='button'
          className='button button-shadow'
          value='Centrer la carte sur le parcours'
          style={{marginRight:20, position:'absolute', top:10, left:200}}
          onClick={() => {
            alert('Veuillez définir au moins une étape sur votre parcours')
          }}
        />
        <GoogleMap
          bootstrapURLKeys={{key:GOOGLE_KEY}}
          defaultCenter={{ lat: 43.6207956, lng: 7.0589927 }}
          defaultZoom={10}
          onClick={event => handleClick(event, pointModif)}
        >
        </GoogleMap>
      </>
    )
  }
});
