// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Participations from '../../screen/Participations';

// Action Creators
import { getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.participations,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAll: (page, params) => {
    dispatch(getAll('participations', page, params));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Participations);