// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import DotationsEdit from '../../screen/DotationsEdit';

// Action Creators
import { patch, upload, getOne } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  data: state.onerewards,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patch: (title='',image='',images='', type = '', titleEn='', descriptionEn='', sponsor='', status='', quantity=0, description='', sponsorUrl) => {
    dispatch(patch('rewards', ownProps.match.params.id, {
      // title,
      // image,
      type,
      sponsor,
      status,
      quantity : parseInt(quantity),
      // description,
      sponsorUrl,
      translations: [
        {
          title,
          imageThumbnail: image,
          images,
          description,
          locale: 'fr'
        },
        {
          title: titleEn,
          imageThumbnail: image,
          images,
          description: descriptionEn,
          locale: 'en'
        }
      ]
    }, ownProps.history));
  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
  getOne: (id) => {
    dispatch(getOne('rewards', id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DotationsEdit);
