// == Import : npm
import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import { boEntries } from '../../utils/headerEntries.js';
import ImageSelector from '../../containers/ImageSelector';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

// == Composant
const DotationsAjout = ({ location, match, datas, waitingRequest, connected, post }) => {

    const [pickImage, setPickImage] = useState(false)
    const [imageSelected, setImageSelected] = useState('')
    const [imagesCarousel, setImagesCarousel] = useState([null])
    const [titre, setTitre] = useState(null)
    const [type, setType] = useState('lot')
    const [desc, setDesc] = useState(null)
    const [sponsor, setSponsor] = useState(null)
    const [link, setLink] = useState(null)
    const [quantity, setQuantity] = useState(null)
    const win = 'toWin'
    const rang = null

    // state translate languege
    const [isLanguageFr, setIsLanguageFr] = useState(true);
    const [titleEn, setTitleEn] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');

    //console.log(ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName ))


    if(waitingRequest > 0 && !pickImage ) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={5} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Dotations</h1>
        </div>
        <div className="form-wrapper">
            <div style={{display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end'}}>
               <input type='button' className='button button-shadow' value='Francais' style={{backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white'}} onClick={() => setIsLanguageFr(true)}/>
               <input type='button' className='button button-shadow' value='Anglais' style={{backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white'}} onClick={() => setIsLanguageFr(false)}/>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <label  style={{width:'47%'}}>
                    {isLanguageFr && <input value={titre} onChange={(e) => {setTitre(e.target.value)}} type='text' className="input-text" placeholder='Titre(FR) *'/>}
                    {!isLanguageFr && <input value={titleEn} onChange={(e) => {setTitleEn(e.target.value)}} type='text' className="input-text" placeholder='Title(EN) *'/>}
                </label>
                <label  style={{width:'47%'}}>
                    <select type='text' className="input-text"value={type} onChange={e => setType(e.target.value)}
                    style={{width: type === 'volants' ? '47%' :  '100%'}}>
                        <option value='lot'>Lot</option>
                        <option value='volants'>Volants</option>
                    </select>
                    {type === 'volants' ?
                        <input value={quantity} onChange={(e) => {setQuantity(e.target.value)}} type='number' className="input-text" placeholder='Quantité *' style={{marginLeft: 15, width:'43%', display:"inline"}}/> : ''
                    }
                </label>
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px'}}>
                {isLanguageFr && (
                    <>
                        <p style={{marginBottom:10}}>Description(FR)</p>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={desc}
                            config={{ removePlugins: [ 'ImageUpload', 'MediaEmbed', 'Table' ],}}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setDesc(data);
                                console.log( { event, editor } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </>
                )}
                 {!isLanguageFr && (
                    <>
                        <p style={{marginBottom:10}}>Description(EN)</p>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={descriptionEn}
                            config={{ removePlugins: [ 'ImageUpload', 'MediaEmbed', 'Table' ],}}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setDescriptionEn(data);
                                console.log( { event, editor } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </>
                )}
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'column', justifyContent:"space-between"}}>
              <p>Visuel Dotation : </p>
              <ImageSelector imageUrl={imageSelected} setImageUrl={setImageSelected} deactivateWaitingRequest={setPickImage} />
            </div>

            <div className="fieldset-wrapper" style={{display:'flex', flexDirection:'column', justifyContent:"space-between"}}>
                <p>Visuels Carousel : </p>
                <div>
                {imagesCarousel.map((image, index) => {
                return (
                    <div style={{display:'flex', flexDirection:"row", alignItems:'center'}}>
                        <div style={{width:'70%'}}>(FR)
                            <ImageSelector imageUrl={image} setImageUrl={(e) => {const temp = imagesCarousel.slice(); temp[index] = e; setImagesCarousel(temp)}} deactivateWaitingRequest={setPickImage} />
                        </div>
                        <FontAwesomeIcon  onClick={() => {setImagesCarousel(imagesCarousel.filter((e, i) => i!==index))}} size='2x' icon={faTimesCircle}  className='fa-button plus' style={{}}/>
                    </div>
                )
                })}
                <FontAwesomeIcon  onClick={() => {setImagesCarousel([...imagesCarousel, null])}} size='2x' icon={faPlusCircle}  className='fa-button plus' style={{marginTop: 30}}/>
                </div>
            </div>

            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <input value={sponsor} onChange={(e) => {setSponsor(e.target.value)}} type='text' className="input-text" placeholder='Sponsor' style={{width:'45%'}}/>
                <input value={link} onChange={(e) => {setLink(e.target.value)}} type='text' className="input-text" placeholder='lien' style={{width:'45%'}}/>
            </div>
            <div className='fieldset-wrapper'>
                <button className='button button-shadow' onClick={() => {post(titre, imageSelected,imagesCarousel, type, titleEn, descriptionEn ,sponsor, win, quantity, desc, link, rang)}}>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default DotationsAjout;
