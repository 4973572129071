// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Challenges from '../../screen/Challenges';

// Action Creators
import { getList } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.challenges,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getList: (page) => {
    dispatch(getList('challenges', page));
  },
  // getAll: ( page) => {
  //   dispatch(getAll('challenges', page, ownProps.history));
  // },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Challenges);
