// == Import : npm
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Listing from '../../containers/Listing'

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Dotations = ({location, match, datas, waitingRequest, getList}) => {
	const [order, setOrder] = useState({
		titre :'', type:'', position:''
	})
	const [reload, setReload] = useState(false);

	useEffect(() => {
		setOrder({
			titre :'', type:'', position:''
		});
		getList(match.params.page);
	}, [match.params.page, reload]) // eslint-disable-line react-hooks/exhaustive-deps

	function reloadFunction () {
		setReload(!reload);
	}

	const reOrder = (type) => {
		let ord = { ...order };
		let urlFilterOrder = "";
		switch (type) {
			case 'titre':
				if (ord.titre === 'asc') {
					ord.titre = 'desc';
					ord.type = '';
					ord.position = '';
					urlFilterOrder += "&order[title]=desc";
				} else {
					ord.titre = 'asc';
					ord.type = '';
					ord.position = '';
					urlFilterOrder += "&order[title]=asc";
				}
				break;
			case 'type':
				if (ord.type === 'asc') {
					ord.type = 'desc';
					ord.titre = '';
					ord.position = '';
					urlFilterOrder += "&order[type]=desc";
				} else {
					ord.type = 'asc';
					ord.titre = '';
					ord.position = '';
					urlFilterOrder += "&order[type]=asc";
				}
				break;
			case 'position':
				if (ord.position === 'asc') {
					ord.type = '';
					ord.titre = '';
					ord.position = 'desc';
					urlFilterOrder += "&order[position]=desc";
				} else {
					ord.type = '';
					ord.titre = '';
					ord.position = 'asc';
					urlFilterOrder += "&order[position]=asc";
				}
				break;
			default:
				break;
		}
		setOrder(ord);
		getList(match.params.page+urlFilterOrder);
	}

	if(waitingRequest > 0) {
		return (
			<div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
				<ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
			</div>
		)
	}

	return (
		<>
			<Header currentMenuItem={5} entries={boEntries} location={location} match={match} />
			<div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
				<div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
					<h1 className='page-title'>Dotations</h1>
					<Link to='/dotations/ajout' className='button button-shadow' style={{backgroundColor:'#AB3217', color:'white', padding:'10px 30px', height:'fit-content'}}>Créer</Link>
				</div>
				{datas && <Listing
					type={'dotations'}
					dataType={'rewards'}
					match={match}
					datas={datas}
					orderFunction={reOrder}
					reloadFunction={reloadFunction}
					order={order}
					titles={['titre', 'type', 'quantity', 'position']}
					datasToDisplay={
						datas.map(element => ({
							id:element.id,
							position:element.position,
							infos :[
								element.title,
								element.type,
								element.quantity
							]
						})
					)}
				/>}
			</div>
		</>
	)
};

// == Export
export default Dotations;
