// == Import : npm
import React from 'react';
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

// == Composant
const Page404 = ({ location, match, history, waitingRequest }) => {

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
            <div className='page-wrapper' style={{ minHeight:'calc(100vh - 300px)', display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <div className='grey-background' style={{ width:'fit-content', margin:'auto' }}>
                        <h1 style={{ color:"#A92E0C" }}>Erreur 404 : page introuvable</h1>
                    </div>
            </div>

        </>
    )
};

// == Export
export default Page404;
