// == Import : npm
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Listing from '../../containers/Listing'

import { boEntries } from '../../utils/headerEntries.js';
import moment from 'moment';

// == Composant
const Challenges = ({ location, match, datas, waitingRequest, getList, deleteEntity, connected }) => {
	const [order,setOrder] = useState({
		titre:'', debut:'',fin:'', position:''
	})
	const [reload, setReload] = useState(false);

	useEffect(() => {
		setOrder({
			titre:'', debut:'',fin:'', position:''
		})
		// getAll(match.params.page);
		getList(match.params.page);
	}, [match.params.page, reload]) //eslint-disable-line react-hooks/exhaustive-deps

	const reOrder = (type) => {
		let ord = {...order}
		let urlFilterOrder = ""; //eslint-disable-line
		switch (type) {
			case 'debut':
				if (ord.debut === 'asc') {
					ord.debut = 'desc';
					ord.titre = '';
					ord.fin = '';
					ord.position = '';
					urlFilterOrder += "&order[startAt]=desc";
				} else {
					ord.debut = 'asc';
					ord.titre = '';
					ord.fin = '';
					ord.position = '';
					urlFilterOrder += "&order[startAt]=asc";
				}
				break;
			case 'fin':
				if (ord.fin === 'asc') {
					ord.fin = 'desc';
					ord.titre = '';
					ord.debut = '';
					ord.position = '';
					urlFilterOrder += "&order[endAt]=desc";
				} else {
					ord.fin = 'asc';
					ord.titre = '';
					ord.debut = '';
					ord.position = '';
					urlFilterOrder += "&order[endAt]=asc";
				}
				break;
			case 'titre':
				if (ord.titre === 'asc') {
					ord.titre = 'desc';
					ord.fin = '';
					ord.debut = '';
					ord.position = '';
					urlFilterOrder += "&order[title]=desc";
				} else {
					ord.titre  = 'asc';
					ord.fin = '';
					ord.debut = '';
					ord.position = '';
					urlFilterOrder += "&order[title]=asc";
				}
				break;
			case 'position':
				if (ord.position === 'asc') {
					ord.titre = '';
					ord.fin = '';
					ord.debut = '';
					ord.position = 'desc';
					urlFilterOrder += "&order[position]=desc";
				} else {
					ord.titre  = '';
					ord.fin = '';
					ord.debut = '';
					ord.position = 'asc';
					urlFilterOrder += "&order[position]=asc";
				}
				break;
			default:
				break;
		}
		setOrder(ord);
		// getAll(match.params.page+urlFilterOrder);
		getList(match.params.page+urlFilterOrder);
	}

	function reloadFunction () {
		setReload(!reload);
	}

	if(waitingRequest > 0/* || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
		return (
			<div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
				<ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
			</div>
		)
	}

// console.log('match : ', match)
	return (
		<>
			<Header currentMenuItem={3} entries={boEntries} location={location} match={match} />
			<div className='challenges-page-wrapper' style={{ paddingLeft: '5%', paddingRight: '5%', width:'100vw'}}>
				<div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
					<h1 className='page-title'>Challenges</h1>
					<Link to='/challenges/ajout' className='button button-shadow' style={{backgroundColor:'#AB3217', color:'white', padding:'10px 30px', height:'fit-content'}}>Créer</Link>
				</div>
				{datas && <Listing
					type={'challenges'}
					dataType={'challenges'}
					match={match}
					datas={datas}
					orderFunction={reOrder}
					order={order}
					titles={['titre', 'debut', 'fin', 'Place disponible', 'position']}
					isActiveLink={true}
					reloadFunction={reloadFunction}
					datasToDisplay={
						datas.map(element => ({
							id:element.id,
							position:element.position,
							infos :[
								element.title,
								moment(element.startAt).format('DD/MM/YYYY'),
								moment(element.endAt).format('DD/MM/YYYY'),
								element.remainingSlots != null ? element.remainingSlots : 'Nombre illimités',
							]
						})
					)}
				/>}
			</div>
		</>
	)
};

// == Export
export default Challenges;
