// == Import : npm
import React from 'react';
import { Link } from "react-router-dom";

// == Import : local
import '../../style/style.scss';

// == Composant


const pagination = ({ datas, url, match }) => {
	let number_of_page = 1;
	if (datas['hydra:view'] && datas['hydra:view']['hydra:last']) {
		number_of_page = datas['hydra:view']['hydra:last'].split('=')[datas['hydra:view']['hydra:last'].split('=').length-1];
	}

	let toDisplay = [];

	for (let index = 1; index <= number_of_page; index++) {
		toDisplay[index] =
			<Link
				className='page-number'
				key={index}
				style={match.params.page == index ? {color: 'white', textDecoration: 'underline', backgroundColor: 'gray', padding: 4, border: 'solid', borderColor: '#A92E0C', borderWidth: 2, borderRadius: 5, boxShadow: '5px 5px 5px gray'} : {textDecoration:'none'}} //eslint-disable-line
				to={`${url}${index}${match.params.user ? '/' + match.params.user : ''}`}
			>
				{index}
			</Link>
	}

	return (
		<>
			<Link to={`${url}${match.params.page === 1 ? 1 : parseInt(match.params.page) - 1}${match.params.user? '/'+match.params.user : ''}`} className='prev-next'>{'<'}</Link>
			<>{toDisplay}</>
			<Link to={`${url}${match.params.page === number_of_page ? number_of_page : parseInt(match.params.page) + 1}${match.params.user? '/'+match.params.user : ''}`} className='prev-next'>{'>'}</Link>
		</>
	);
}

// == Export
export default pagination;
