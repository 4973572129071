import axios from 'axios';

import {
  addWaitingRequest,
  removeWaitingRequest,
  setDatas,
  LOGIN,
  LOGOUT,
  UPLOAD,
  DELETE,
  setMessageNotif,
  setConnected,
  ME,
  POST,
  setLastUploadImage,
  GET_ALL,
  GET_ONE,
  PATCH,
  STREAM_LOADING,
  addDatas,
  GET_FILE,
  GET_LIST,
  SAVE_CHALLENGE_POSITION,
  SAVE_REWARD_POSITION
} from './reducer';


const logMiddleware = store => next => (action) => {
  /*if () {
    action.pageResetFilre = 1;
  }*/
  // console.log('Je suis le middleware, et je laisse passer cette action: ', action);
  next(action);

  const instance = axios.create({
    /*httpsAgent: httpsAgent({
      rejectUnauthorized: false,
    }),*/
    //withCredentials:true,
  });


  switch (action.type) {
    case POST:
      store.dispatch(addWaitingRequest());
      // console.log('POST action.datas : ', action.datas)
      instance(
        {
          method: 'POST',
          url:`${process.env.REACT_APP_API_URL}/api/${action.dataType}`,
          headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
            'Content-Type' : 'application/json',
          },
          withCredentials: true,
          data: {
            ...action.datas
          }
        },
      )
      .then(response => {
        // console.log('response.data : ', response.data)
        store.dispatch(setMessageNotif('Contenu ajouté avec succès.', true))
        store.dispatch(setDatas(response.data))
        //setCookie('connected', response.data, {path: '/'});
        store.dispatch(removeWaitingRequest());
        if(typeof action.willDo == 'function')
          action.willDo(response.data);
        /*if(response.data.roles.find(element => element === "ROLE_ADMIN" || element === "ROLE_EDITOR")) {
          redirect('/tableau-de-bord')
        }*/
      })
      .catch((error) => {
        // console.log(error)
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      })
      break;
    case PATCH:
      store.dispatch(addWaitingRequest());
      // console.log('PATCH action.datas : ', action.datas)
      instance(
        {
          method: 'PATCH',
          url:`${process.env.REACT_APP_API_URL}/api/${action.dataType}/${action.id}`,
          headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
            'Content-Type' : 'application/merge-patch+json',
          },
          withCredentials: true,
          data: {
            ...action.datas
          }
        },
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        // console.log(response.data)
        store.dispatch(setMessageNotif('Contenu modifié avec succès.', true))
        store.dispatch(setDatas(response.data))
        //setCookie('connected', response.data, {path: '/'});
        store.dispatch(removeWaitingRequest());
        if(typeof action.willDo == 'function')
          action.willDo(response.data);
        /*if(response.data.roles.find(element => element === "ROLE_ADMIN" || element === "ROLE_EDITOR")) {
          redirect('/tableau-de-bord')
        }*/
      })
      .catch((error) => {
        // console.log(error)
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      })
      break;
    case GET_ALL:
      store.dispatch(addWaitingRequest());
      // console.log('GET_ALL',`${process.env.REACT_APP_API_URL}/api/${action.dataType}?page=${action.page}&${action.params}`);
      instance(
        {
          method: 'GET',
          url:`${process.env.REACT_APP_API_URL}/api/${action.dataType}?page=${action.page}&${action.params}`,
          headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
            'Content-Type' : 'application/json',
          },
          withCredentials: true,
        },
      )
      .then(response => {

        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        // console.log('response.data',response.data)

        //Edit hydra:member with rank

        store.dispatch(setDatas(response.data, response.data['@id'].split('/')[2]))
        //setCookie('connected', response.data, {path: '/'});
        store.dispatch(removeWaitingRequest());
        /*if(response.data.roles.find(element => element === "ROLE_ADMIN" || element === "ROLE_EDITOR")) {
          redirect('/tableau-de-bord')
        }*/
      })
      .catch((error) => {
        // console.log(error)
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      })
      break;
    case STREAM_LOADING:
      store.dispatch(addWaitingRequest());
      instance(
        {
          method: 'GET',
          url:`${process.env.REACT_APP_API_URL}/api/${action.datasType}?page=${action.page}&itemsPerPage=${10}&${action.params}`,
          headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
            'Content-Type' : 'application/json',
          },
          withCredentials: true,
        },
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        // console.log(response.data)
        store.dispatch(removeWaitingRequest());
        return store.dispatch(addDatas(response.data, action.page, response.data['@id'].split('/')[2]))
        //setCookie('connected', response.data, {path: '/'});
        /*if(response.data.roles.find(element => element === "ROLE_ADMIN" || element === "ROLE_EDITOR")) {
          redirect('/tableau-de-bord')
        }*/
      })
      .catch((error) => {
        // console.log(error)
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      })
      break;
    case GET_ONE:
      store.dispatch(addWaitingRequest());
      instance(
        {
          method: 'GET',
          url:`${process.env.REACT_APP_API_URL}/api/${action.dataType}/${action.id}`,
          headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
            'Content-Type' : 'application/json'
          },
          withCredentials: true,
        },
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        // console.log(response.data)
        store.dispatch(setDatas(response.data, `one${response.data['@id'].split('/')[2]}`))
        //setCookie('connected', response.data, {path: '/'});
        store.dispatch(removeWaitingRequest());
        /*if(response.data.roles.find(element => element === "ROLE_ADMIN" || element === "ROLE_EDITOR")) {
          redirect('/tableau-de-bord')
        }*/
      })
      .catch((error) => {
        // console.log(error)
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      })
      break;
    case DELETE:
      store.dispatch(addWaitingRequest());
      instance.delete(`${process.env.REACT_APP_API_URL}/api/${action.entityType}/${action.id}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
        },
        withCredentials:true
      }
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        store.dispatch(setMessageNotif('Contenu supprimé avec succès.', true))
        store.dispatch(removeWaitingRequest());
        document.location.reload(true)
        //return redirect(Window.location);
      })
      .catch((error) => {
        // console.log(error)
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      })
      break;
    case LOGIN:
      store.dispatch(addWaitingRequest());
      // console.log(`${process.env.REACT_APP_API_URL}/login`)
      instance(
        {
          method: 'POST',
          url:`${process.env.REACT_APP_API_URL}/login`,
          headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
            'Content-Type' : 'application/json',
          },
          withCredentials: true,
          data: {
            email:action.mail,
            password:action.password,
          }
        },
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        // console.log('test login', response.data)
        store.dispatch(setConnected(response.data))
        //setCookie('connected', response.data, {path: '/'});
        store.dispatch(removeWaitingRequest());
        // if(response.data.roles.find(element => element === "ROLE_ADMIN" || element === "ROLE_EDITOR")) {
        //   redirect('/tableau-de-bord')
        // }
      })
      .catch((error) => {
        if(error)
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data.error}`, false))
        // if(error.response) {
        //   store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
        //   console.log('ERREUR ', error.response.data['hydra:description'])
        // } else {
        //   store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        // }
        store.dispatch(removeWaitingRequest())
      })
      break;
    case LOGOUT:
      store.dispatch(addWaitingRequest());
      instance(
        {
          method: 'POST',
          url:`${process.env.REACT_APP_API_URL}/logout`,
          headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
            'Content-Type' : 'application/json',
          },
          withCredentials: true,
        },
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        // console.log(response.data)
        store.dispatch(setConnected(null))
        //setCookie('connected', response.data, {path: '/'});
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        // console.log(error)
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      })
      break;
    case UPLOAD:
      store.dispatch(addWaitingRequest());
      instance.post(`${process.env.REACT_APP_API_URL}/api/${action.fileType}`, action.data, {
        headers: {
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      })
      .then((resp) => {
        store.dispatch(setMessageNotif('Contenu ajouté avec succès.', true));
        store.dispatch(setLastUploadImage(resp.data.contentUrl));

        if(action.willDo){action.willDo(resp.data.contentUrl)};
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      });
      break;
    case GET_FILE:
      store.dispatch(addWaitingRequest());
      instance.get(`${process.env.REACT_APP_API_URL}/api/file/${action.code}.json`, {
        headers: {
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: 'include',
      })
      .then((resp) => {
        store.dispatch(setDatas(resp.data, 'featLang'));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      });
      break;
    case GET_LIST:
      store.dispatch(addWaitingRequest());
      instance.get(`${process.env.REACT_APP_API_URL}/api/${action.dataType}/list?page=${action.page}`, {
        headers: {
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      })
      .then((resp) => {
        store.dispatch(setDatas(resp.data, action.dataType));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      });
      break;
    case ME:
      //store.dispatch(addWaitingRequest());
      instance.get(`${process.env.REACT_APP_API_URL}/me`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        withCredentials:true,
      })
      .then((response) => {
        store.dispatch(setDatas(response.data));
        store.dispatch(setConnected(response.data));
        store.dispatch(removeWaitingRequest());
        // console.log(response)
      })
      .catch((error) => {
        // console.log(error.message)
        if(error.response) {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
          console.log('ERREUR ', error.response.data['hydra:description'])
        } else {
          store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
        }
        store.dispatch(removeWaitingRequest())
      });
      break;
    case SAVE_CHALLENGE_POSITION:
      store.dispatch(addWaitingRequest());
      instance(
        {
          method: 'PATCH',
          url:`${process.env.REACT_APP_API_URL}/api/challenge/${action.challengeId}/position/${action.challengePosition}`,
          headers: {
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
          'Content-Type': 'multipart/form-data',
          },
        withCredentials: true,
      })
        .then((resp) => {
          // store.dispatch(setDatas(resp.data, action.dataType));
          store.dispatch(setMessageNotif("Position du challenge mise à jour !", true))
          store.dispatch(removeWaitingRequest());
        })
        .catch((error) => {
          if(error.response) {
            store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
            console.log('ERREUR ', error.response.data['hydra:description'])
          } else {
            store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
          }
          store.dispatch(removeWaitingRequest())
        });
      break;
    case SAVE_REWARD_POSITION:
      store.dispatch(addWaitingRequest());
      instance(
        {
          method: 'PATCH',
          url:`${process.env.REACT_APP_API_URL}/api/reward/${action.rewardId}/position/${action.rewardPosition}`,
          headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then((resp) => {
          // store.dispatch(setDatas(resp.data, action.dataType));
          store.dispatch(setMessageNotif("Position de la dotation mise à jour !", true))
          store.dispatch(removeWaitingRequest());
        })
        .catch((error) => {
          if(error.response) {
            store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.response.data['hydra:description']}`, false))
            console.log('ERREUR ', error.response.data['hydra:description'])
          } else {
            store.dispatch(setMessageNotif(`Une erreur est survenue : ${'\n'} ${error.message}`, false))
          }
          store.dispatch(removeWaitingRequest())
        });
      break;
    default:
      next(action);
  }
};

export default logMiddleware;
