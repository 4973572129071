// == Initial State
const initialState = {
  messageNotif:{
    good: false,
    message:'',
  },
  waitingRequest:0,
  connected: null
};

// == Types
const SET_CUREENT_MENU_ITEM = 'SET_CUREENT_MENU_ITEM';
const SET_MESSAGE_NOTIF = 'SET_MESSAGE_NOTIF';
const ADD_WAITING_REQUEST = 'ADD_WAITING_REQUEST';
const REMOVE_WAITING_REQUEST = 'REMOVE_WAITING_REQUEST';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPLOAD = 'UPLOAD';
export const DELETE = 'DELETE';
export const SAVE_CHALLENGE_POSITION = 'SAVE_CHALLENGE_POSITION';
export const SAVE_REWARD_POSITION = 'SAVE_REWARD_POSITION';
export const SET_CONNECTED = 'SET_CONNECTED';
export const ME = 'ME';
export const SET_DATAS = 'SET_DATAS';
export const POST = 'POST';
export const SET_LAST_UPLOAD_IMAGE = 'SET_LAST_UPLOAD_IMAGE';
export const GET_ALL = 'GET_ALL';
export const GET_LIST = 'GET_LIST';
export const GET_ONE = 'GET_ONE';
export const PATCH = 'PATCH';
export const STREAM_LOADING = 'STREAM_LOADING';
export const ADD_DATAS = 'ADD_DATAS';
export const GET_FILE = 'GET_FILE';


// == Reducer
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CUREENT_MENU_ITEM:
      return {
        ...state,
        currentMenuItem: action.item,
      };
    case SET_MESSAGE_NOTIF:
      return {
        ...state,
        messageNotif: {message: action.message, good: action.good},
      };
    case ADD_WAITING_REQUEST:
      return {
        ...state,
        waitingRequest: state.waitingRequest + 1,
      };
    case REMOVE_WAITING_REQUEST:
      return {
        ...state,
        waitingRequest: state.waitingRequest - 1,
      };
    case SET_CONNECTED:
      return {
        ...state,
        connected: action.connected,
      };
    case SET_LAST_UPLOAD_IMAGE:
      return {
        ...state,
        lastUploadedImage: action.url,
      };
    case SET_DATAS:
      return {
        ...state,
        [action.stateTitle]: action.datas,
      };
    case ADD_DATAS:
      console.log('-----', action.datas['hydra:member'])
      if(action.page !== 1) {
        console.log('add')
        action.type=''
        return {
          ...state,
          [action.stateTitle]: {
            ...state[action.stateTitle],
            // eslint-disable-next-line no-useless-computed-key
            ['hydra:member'] : [
              ...state[action.stateTitle]['hydra:member'],
              ...action.datas['hydra:member']
            ],
            // eslint-disable-next-line no-useless-computed-key
            ['hydra:view']: action.datas['hydra:view']
          },
        };
      } else {
        console.log('create')
        return {
          ...state,
          [action.stateTitle]: action.datas,
        };
      }
    default:
      return state;
  }
};

// == Action Creators
export const setCurrentMenuItem = item => ({
  type: SET_CUREENT_MENU_ITEM,
  item,
});
export const setMessageNotif = (message, good) => ({
  type: SET_MESSAGE_NOTIF,
  message,
  good,
});
export const addWaitingRequest = () => ({
  type: ADD_WAITING_REQUEST,
});
export const removeWaitingRequest = () => ({
  type: REMOVE_WAITING_REQUEST,
});
export const setDatas = (datas, stateTitle) => ({
  type: SET_DATAS,
  datas,
  stateTitle
});
export const login = ( mail, password, history) => ({
  type: LOGIN,
  mail,
  password,
  history
});
export const upload = ( data, fileType, willDo ) => ({
  type: UPLOAD,
  data,
  fileType,
  willDo
});
export const getFeatLang = ( code ) => ({
  type: GET_FILE,
  code
});
export const deleteEntity = ( entityType, id ) => ({
  type: DELETE,
  entityType,
  id,
});
export const saveChallengePosition = ( challengeId, challengePosition ) => ({
  type: SAVE_CHALLENGE_POSITION,
  challengeId,
  challengePosition,
});
export const saveRewardPosition = ( rewardId, rewardPosition ) => ({
  type: SAVE_REWARD_POSITION,
  rewardId,
  rewardPosition,
});
export const setConnected = ( connected ) => ({
  type: SET_CONNECTED,
  connected,
});
export const me = (  ) => ({
  type: ME,
});
export const logout = (  ) => ({
  type: LOGOUT,
});
export const post = ( dataType, datas, willDo ) => ({
  type: POST,
  dataType,
  datas,
  willDo
});
export const patch = ( dataType, id, datas, willDo ) => ({
  type: PATCH,
  dataType,
  id,
  datas,
  willDo
});
export const setLastUploadImage = ( url ) => ({
  type: SET_LAST_UPLOAD_IMAGE,
  url,
});
export const getAll = ( dataType, page, params, willDo ) => ({
  type: GET_ALL,
  dataType,
  page,
  params,
  willDo
});
export const getOne = ( dataType, id) => ({
  type: GET_ONE,
  dataType,
  id
});
export const addDatas = ( datas, page, stateTitle ) => ({
  type: ADD_DATAS,
  datas,
  page,
  stateTitle
});
export const streamLoading = ( datasType, page, params ) => ({
  type: STREAM_LOADING,
  datasType,
  page,
  params
});

export const getList = ( dataType, page, params, willDo ) => ({
  type: GET_LIST,
  dataType,
  page,
  params,
  willDo
});

  // == Export
  export default reducer;
