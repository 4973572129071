// == Import : npm
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Listing from '../../containers/Listing'

import { boEntries } from '../../utils/headerEntries.js';
import moment from 'moment';

// == Composant
const Codes = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected }) => {

    const [order,setOrder] = useState({
        titre:'',date:''
    });
    useEffect(() => {
        getAll(match.params.page)
    }, [match.params.page]) // eslint-disable-line react-hooks/exhaustive-deps

    const reOrder = (type) => {
        let ord = { ...order };
        let urlFilterOrder = "";
        switch (type) {
            case 'Titre':
            if (ord.titre === 'asc') {
                ord.titre = 'desc';
                ord.date = '';
                urlFilterOrder += '&order[title]=desc';
            } else {
                ord.titre = 'asc';
                ord.date = '';
                urlFilterOrder += '&order[title]=asc';
            }
            break;
            case 'Date de validité':
                if (ord.date === 'asc') {
                    ord.date = 'desc';
                    ord.titre = '';
                    urlFilterOrder += '&order[limitDate]=desc';
                } else {
                    ord.date = 'asc';
                    ord.titre = '';
                    urlFilterOrder += '&order[limitDate]=asc';
                }
                break;
            default:
                break;
        }
        setOrder(ord);
        getAll(match.params.page+urlFilterOrder);
    }

    if(waitingRequest > 0 /*|| connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={9} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Codes</h1>
            <Link to='/codes/ajout' className='button button-shadow' style={{backgroundColor:'#AB3217', color:'white', padding:'10px 30px', height:'fit-content'}}>Créer</Link>
        </div>
        {datas && <Listing
            type={'codes'}
            dataType={'promo_codes'}
            match={match}
            datas={datas}
            orderFunction={reOrder}
            order={order}
            titles={['Titre', 'Code', 'Date de validité', 'nombre utilisation', 'challenge']}
            datasToDisplay={
                datas['hydra:member'].map(element => ( {
                    id:element['@id'].split('/')[3],
                    infos :[
                     element.title,
                     element.code,
                     element.hasOwnProperty('limitDate') ? moment(element.limitDate).format('DD/MM/YYYY') : '-',
                     element.hasOwnProperty('maxUses') && element.hasOwnProperty('remaningUses')? `${element.maxUses - element.remaningUses} / ${element.maxUses}` : '-',
                     element.challenge ? element.challenge.title : '-',
                    ]
                })
            )}
        />}
    </div>
    </>
    )
};

// == Export
export default Codes;
