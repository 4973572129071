// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const TooltipsEdit = ({ location, match, datas, waitingRequest, getOne, patch, connected }) => {

    const [titre, setTitre] = useState(null)
    const [content, setContent] = useState(null)
    const [screenshotUrl, setScreenshotUrl] = useState(null)

    // state translate languege
    const [isLanguageFr, setIsLanguageFr] = useState(true);
    const [titleEn, setTitleEn] = useState('');
    const [contentEn, setContentEn] = useState('');

    useEffect(() => {
        getOne(`${match.params.id}?groups[]=translations`)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(datas) {
            setTitre(datas.title)
            setContent(datas.content)
            setScreenshotUrl(datas.screenshotUrl)

            if(datas.translations.en) {
                setTitleEn(datas.translations.en.title)
                setContentEn(datas.translations.en.content)
            }
        }
    }, [datas])

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={13} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Tooltips Edit</h1>
        </div>
        <div className="form-wrapper">
            <div style={{display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end'}}>
               <input type='button' className='button button-shadow' value='Francais' style={{backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white'}} onClick={() => {
                   setIsLanguageFr(true);
               }}/>
               <input type='button' className='button button-shadow' value='Anglais' style={{backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white'}} onClick={() => {
                   setIsLanguageFr(false);
               }}/>
            </div>
            <div className='column-container'>
                <div className='form-column' style={{minWidth:'66%'}}>
                    <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row'}}>
                        {isLanguageFr && (
                            <label className='half-row' style={{width:'100%'}}>Titre(Fr)
                                <input value={titre} onChange={(e) => {setTitre(e.target.value)}} type='text' className="input-text" placeholder='Titre'/>
                            </label>
                        )}
                        {!isLanguageFr && (
                            <label className='half-row' style={{width:'100%'}}>Title(En)
                                <input value={titleEn} onChange={(e) => {setTitleEn(e.target.value)}} type='text' className="input-text" placeholder='Titre'/>
                            </label>
                        )}
                    </div>
                    <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                        {isLanguageFr && (
                            <>
                                <label style={{marginBottom:'1em'}}>Contenu(Fr)</label>
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data={content}
                                    config={{ removePlugins: [ 'ImageUpload', 'MediaEmbed', 'Table' ],}}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setContent(data);
                                        console.log( { event, editor } );
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        console.log( 'Focus.', editor );
                                    } }
                                />
                            </>
                        )}
                        {!isLanguageFr && (
                            <>
                                <label style={{marginBottom:'1em'}}>Contenu(En)</label>
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data={contentEn}
                                    config={{ removePlugins: [ 'ImageUpload', 'MediaEmbed', 'Table' ],}}
                                    onInit={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        setContentEn(data);
                                        console.log( { event, editor } );
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        console.log( 'Focus.', editor );
                                    } }
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className='form-column screenshot-container' style={{width: '33%'}}>
                    <img alt="" src={`${process.env.REACT_APP_API_URL}/${screenshotUrl}`} style={{width: 'unset', height: 'unset'}}/>
                </div>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', margin: '30px 0'}}>
                <button className='button button-shadow' onClick={() => {patch(titre, content, titleEn, contentEn)}}>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default TooltipsEdit;
