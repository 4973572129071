// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import CodesAjout from '../../screen/CodesAjout';

// Action Creators
import { post, getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  challenges: state.challenges,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  post: (title, code, maxUses, value, type, status, challenges, limitDate, uniqueUse) => {
    dispatch(post('promo_codes', {title, code, maxUses, value, type, status, challenges, limitDate, remaningUses: maxUses, hasUniqueUse: uniqueUse}, (datas) => {ownProps.history.push(`/codes/edit/${datas.id}`)}, ownProps.history));
  },
  getAll: ( type, page, params ) => {
    dispatch(getAll(type, page, params, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CodesAjout);