// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ListingSeries from '../../components/ListingSeries';

// Action Creators
import { deleteEntity } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteEntity: (type, id) => {
    dispatch(deleteEntity(type, id, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingSeries);