// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import SlidesAjout from '../../screen/SlidesAjout';

// Action Creators
import { post, upload } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  lastUploadedImage: state.lastUploadedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  post: (title, image, content, titleEn, contentEn) => {
    dispatch(post('slides', { image, translations: [
      {
        title,
        content,
        locale: 'fr',
      },
      {
        title: titleEn,
        content: contentEn,
        locale: 'fr',
      }
    ]}, (datas) => {ownProps.history.push(`/slides/edit/${datas.id}`)} ));
  },
  upload: (data) => {
    dispatch(upload(data, 'media_objects'));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SlidesAjout);