// == Import : npm
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import ReactLoading from 'react-loading';
import Axios from 'axios';
  // == Import : local
  import '../../style/style.scss';

  import Header from '../../containers/Header';
  import Listing from '../../containers/Listing'

  import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Parcours = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected }) => {

    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState("");
    const [codeCourse, setCodeCourse] = useState(null);
    const [averageTimeMin, setAverageTimeMin] = useState(null);
    const [averageTimeMax, setAverageTimeMax] = useState(null);
    const [distanceMin, setDistanceMin] = useState(null);
    const [distanceMax, setDistanceMax] = useState(null);
    const [urlFilterOrder, setUrlFilterOrder] = useState(null);
    const [order, setOrder] = useState({
        code:'', titre:'',distance:'', url:''
    });

    const history = useHistory();

    useEffect(() => {
        let filtreUrl = 'itemsPerPage=10';
        if(codeCourse != null) {
            filtreUrl += `&code=${codeCourse}`;
        }
        if(country != null) {
            filtreUrl += `&startName=${country}`;
        }
        if(averageTimeMin != null) {
            filtreUrl += `&averageTime[gte]=${averageTimeMin}`;
        }
        if(averageTimeMax != null) {
            filtreUrl += `&averageTime[lte]=${averageTimeMax}`;
        }
        if(distanceMin != null) {
            filtreUrl += `&distance[gte]=${distanceMin}`;
        }
        if(distanceMax != null) {
            filtreUrl += `&distance[lte]=${distanceMax}`;
        }
        if(urlFilterOrder != null) {
            filtreUrl += `${urlFilterOrder}`;
        }

        getAll(match.params.page, filtreUrl)
    }, [match.params.page,urlFilterOrder]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getCountryList();
    }, [datas]);

    const getCourseFilter = () => {
        let filtreUrl = 'itemsPerPage=10';
        if(codeCourse != null) {
            filtreUrl += `&code=${codeCourse}`;
        }
        if(country != null) {
            const countrySelect = document.getElementById("country");
            filtreUrl += `&startName=${countrySelect.value}`;
        }
        if(averageTimeMin != null) {
            filtreUrl += `&averageTime[gte]=${averageTimeMin}`;
        }
        if(averageTimeMax != null) {
            filtreUrl += `&averageTime[lte]=${averageTimeMax}`;
        }
        if(distanceMin != null) {
            filtreUrl += `&distance[gte]=${distanceMin}`;
        }
        if(distanceMax != null) {
            filtreUrl += `&distance[lte]=${distanceMax}`;
        }
        if(urlFilterOrder != null) {
            filtreUrl += `${urlFilterOrder}`;
        }
        history.replace({ pathname: `/parcours/1`});
        getAll(1, filtreUrl)
    }

    const reset = () => {
        window.location = '/parcours/1';
    }

    const reOrder = (type) => {
        let ord ={ ...order}
        switch (type) {
            case 'Titre':
                if (ord.titre === 'asc') {
                    ord.titre = 'desc';
                    ord.code = '';
                    ord.distance = '';
                    ord.url = "&order[title]=desc";
                    } else {
                    ord.titre = 'asc';
                    ord.code = '';
                    ord.distance = '';
                    ord.url = "&order[title]=asc";
                    }
                    break;
            case 'Code':
                if (ord.code === 'asc') {
                    ord.code = 'desc';
                    ord.titre = '';
                    ord.distance = '';
                    ord.url = "&order[id]=desc";
                  } else {
                    ord.code = 'asc';
                    ord.titre = '';
                    ord.distance = '';
                    ord.url = "&order[id]=asc";
                  }
                break;
            case 'Distance':
                if (ord.distance === 'asc') {
                    ord.distance = 'desc';
                    ord.titre = '';
                    ord.code = '';
                    ord.url = "&order[distance]=desc";
                  } else {
                    ord.distance = 'asc';
                    ord.titre = '';
                    ord.code = '';
                    ord.url = "&order[distance]=asc";
                  }
                break;
            default:
                break;
        }
        setUrlFilterOrder(ord.url);
        setOrder(ord);
    }

    /*
    const getCountryList = () => {
        Axios.get(`${process.env.REACT_APP_API_URL}/api/locations/countries?sigle=0`, { withCredentials: true }).then((response) => {
            setCountryList(response.data);
        });
    };
    */

    const getCountryList = () => {
        Axios.get(`${process.env.REACT_APP_API_URL}/api/courses?pagination=false`, { withCredentials: true }).then((response) => {
            const parcours = response.data["hydra:member"];
            console.log('parcours',parcours);
            const countryList = [];
            parcours.map((parcour) => {
                let country = parcour.startName.split(',');
                country = country[country.length-1]
                if(!countryList.includes(country))
                    countryList.push(country);

                return null
            });
            setCountryList(countryList);
        });
    };


    if(waitingRequest > 0/* || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }


    return (
    <>
    <Header currentMenuItem={2} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft: '5%', paddingRight: '5%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='mr-5 page-title'>Parcours</h1>
            <div className='row mr-4'>
                <div className='col'>
                    <select id="country" type="text" value={country} className="input-text" onChange={(e) => { setCountry(e.target.value);}}>
                        <option value="">
                            Tous
                        </option>
                        {countryList.map((country, idx) => (
                        <option key={idx} value={country}>
                        {country}
                        </option>
                        ))}
                    </select>
                </div>
                <div className='col'>
                    <input onChange={(e) => {setCodeCourse(e.target.value)}} type='text' value={codeCourse} className="input-text" placeholder='Code Course'/>
                </div>
                <div className='col mb-3'>
                    <input onChange={(e) => {setDistanceMin(e.target.value)}}  type='number' value={distanceMin} style={{width:180}} range='10' min='1' className="input-text" placeholder='Distance parcours min'/>
                    <input onChange={(e) => {setDistanceMax(e.target.value)}}  type='number' value={distanceMax} style={{width:180}} range='10' max='780'  className="input-text" placeholder='Distance parcours max'/>
                </div>
                <div className='col col mb-3'>
                    <input onChange={(e) => {setAverageTimeMin(e.target.value)}}  type='number' style={{width:180}} range='10' min='1' value={averageTimeMin} className="input-text" placeholder='Temps parcours min'/>
                    <input onChange={(e) => {setAverageTimeMax(e.target.value)}}  type='number' style={{width:180}} range='10' max='780'  value={averageTimeMax} className="input-text" placeholder='Temps parcours max'/>
                </div>
            </div>
            <div className='row mr-3'>
                <Link to='/parcours/ajout' className='button button-shadow' style={{backgroundColor:'#AB3217', color:'white', padding:'10px 30px', height:'fit-content'}}>Créer</Link>
            </div>
            <div className='row'>
                <Link target="_blank" to='/parcours/carte' className='button button-shadow' style={{backgroundColor:'#AB3217', color:'white', width:190, padding:'10px 30px', height:'fit-content'}}>Parcours carte</Link>
            </div>
        </div>
        <div className='col-md-10 mb-3 ml-5'>
            <div className='row ml-5'>
                <button className='button button-shadow ml-3' style={{backgroundColor:'#AB3217', color:'white', padding:'10px 30px', height:'fit-content'}} onClick={() => {getCourseFilter()}}>Filtrer</button>
                <button className=' button button-shadow ml-1' style={{backgroundColor:'#AB3217', color:'white', width:190}} onClick={() => {reset()}}>Réinitialiser</button>
            </div>
        </div>
        {datas && <Listing
                type={'parcours'}
                dataType={'courses'}
                match={match}
                datas={datas}
                orderFunction={reOrder}
                order={order}
                titles={['Titre', 'Code', 'Départ', 'Arrivée', 'Distance', 'Actif']}
                datasToDisplay={
                    datas['hydra:member'].map(element => ( {
                        id:element['@id'].split('/')[3],
                        infos :[
                            element.title,
                            element.code,
                            element.startName ? element.startName : '',
                            element.endName ? element.endName : '',
                            element.distance,
                            element.status,
                        ]
                    })
                )}
            />}


    </div>
    </>
    )
};

// == Export
export default Parcours;
