// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Utilisateurs from '../../screen/Utilisateurs';

// Action Creators
import { getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.users,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, page) => ({
  getAll: (page, params, password) => {
    dispatch(getAll('users', page, params));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Utilisateurs);