// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle, faArrowUp, faArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons'


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';
import ImageSelector from '../../containers/ImageSelector';
import moment from 'moment';
import Axios from 'axios';
registerLocale('fr', fr)

// == Composant
const ChallengesEdit = ({ location, match, datas, getOne, waitingRequest, lastUploadedImage, patch, connected, getAll, rewards, setMessageNotif }) => {

	const [imageUrl, setImageUrl] = useState(null);
	const [id,setId] = useState(null);
	const [title, setTitle] = useState(null)
	const [code, setCode] = useState('')
	const [date, setDate] = useState(null)
	const [dateFin, setDateFin] = useState(null)
	const [dateInscription, setDateInscription] = useState(null)
	const [dateShowStart, setDateShowStart] = useState(null)
	const [dateShowEnd, setDateShowEnd] = useState(null)
	const [description, setDescription] = useState(null)
	const [placeMax, setPlaceMax] = useState(null)
	const [parcours, setParcours] = useState()
	const [remainingSlot, setRemainingSlot] = useState(null)
	const [isShow, setIsShow] = useState(true);
	const [partenaires, setPartenaires] = useState([
		{name: null, url: null, image: null},
		{name: null, url: null, image: null},
		{name: null, url: null, image: null},
	])
	const [displayConfirm, setDisplayConfirm] = useState(false);


	const [dotations, setDotations] = useState([{title: null, min:'', max: '', iri: null}])
	const [challengesDotations, setChallengesDotations] = useState([]);

	const [isDirty, setIsDirty] = useState(false);
	const [tarifsParcours, setTarifsParcours] = useState('')
	const [tarifsAgain, setTarifsAgain] = useState('')
	const [tarifsDuel, setTarifsDuel] = useState('')
	const [tarifsGT, setTarifsGT] = useState('')
	const [tourismCost, setTourismCost] = useState(0)

	const [pickImage, setPickImage] = useState(false)

	// state translate languege
	const [isLanguageFr, setIsLanguageFr] = useState(true);
	const [titleEn, setTitleEn] = useState('');
	const [descriptionEn, setDescriptionEn] = useState('');
	const [visualUrlEn, setVisualUrlEn] = useState('');

	const updatePartner = (value, index, type) =>
	{
		let temp = partenaires.slice();
		switch (type) {
			case 'name':
				temp[index].name = value; setPartenaires(temp);
				break;
			case 'url':
				temp[index].url = value; setPartenaires(temp);
				break;
			case 'image':
				temp[index].image = value; setPartenaires(temp);
				break;
			default:
				break;
		}
		setIsDirty(true)
	}

	useEffect(() => {
		getOne(`${match.params.id}?groups[]=translationsChallenge`)
		getAll(1, 'pagination=false')
	}, []) //eslint-disable-line

	const dotationsIsVolants = (challengeDotations) => {
		let isVolants = false;
		challengeDotations.forEach((dotation, index) => {
			if(dotation?.reward?.type === "volants") {
				isVolants = true;
			}
		});
		return isVolants;
	}

	useEffect(() => {
		if(datas) {
			setId(datas.id)
			setTitle(datas.title)
			setCode(datas.code)
			setDescription(datas.description)
			setDate(new Date(datas.startAt))
			setDateFin(new Date(datas.endAt))
			setDateInscription(new Date(datas.registryLimitDate))

			if(datas.hasOwnProperty('dateShowStart')) {
				setDateShowStart(new Date(datas.dateShowStart))
			}
			if(datas.hasOwnProperty('dateShowEnd')) {
				setDateShowEnd(new Date(datas.dateShowEnd))
			}
			if(datas.hasOwnProperty('remainingSlots')) {
				setRemainingSlot(datas.remainingSlots)
			}
			if(datas.hasOwnProperty('isShow')) {
				setIsShow(datas.isShow);
			}

			setPlaceMax(datas.maxSlots)
			setImageUrl(datas.image)
			setTarifsParcours(datas.normalCost)
			setTarifsAgain(datas.tryAgainCost)
			setTarifsGT(datas.gtCost)
			setTarifsDuel(datas.duelCost)
			setTourismCost(datas.tourismCost)
			setParcours(datas.courses.map(element => element.code.split('-')[2]))

			if(datas.translations.en) {
				setTitleEn(datas.translations.en.title)
				setDescriptionEn(datas.translations.en.description)
				setVisualUrlEn(datas.translations.en.image)
			}

			while (datas.partners.length < 3) {
				datas.partners.push({name: null, url: null, image: null});
			}
			setPartenaires(datas.partners.map(element => ({name: element.name, url: element.url, image:element.image})))

			if(datas.challengeDotations.length > 0) {
				setChallengesDotations(datas.challengeDotations);
				setDotations(datas.challengeDotations.map(element => ({iri: element.reward['@id'], min: element.minRank, max: element.maxRank})));
			}
		}
	}, [datas])
	if(waitingRequest > 0 && !pickImage) {
		return (
			<div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
				<ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
			</div>
		)
	}

	const defineDotations = (iri, min, max, index) => {
		let temp = dotations.slice();
		temp[index] = {iri, min, max};
		console.log('temp', temp)
		setDotations(temp)
	}

	const reverseDotations = (current, previous, index) => {
		let temp = dotations.slice();
		temp[index] = {iri: previous.iri, min: current.min, max: current.max};
		temp[index-1] = {iri: current.iri, min: previous.min, max: previous.max};
		setDotations(temp)
	}
	const reverseDotationsDown = (current, next, index) => {
		let temp = dotations.slice();
		temp[index] = {iri: next.iri, min: current.min, max: current.max};
		temp[index+1] = {iri: current.iri, min: next.min, max: next.max};
		setDotations(temp)
	}

	const creditWheels = (idChallenge) => {
		Axios({
			method: 'post',
			url:`${process.env.REACT_APP_API_URL}/api/credit/wheels/${idChallenge}`,
			headers: {
				'Access-Control-Allow-Credentials': true,
				'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
				'Content-Type' : 'application/json',
			},
			withCredentials: true,
		})
			.then(response => {
				console.log(response.data);
				setMessageNotif(response.data.message);
			})
			.catch((e) => {
				console.log('error',e.response);
				const message = e.response?.data?.message;
				setMessageNotif(message);
				// throw handleError(e);
			});
	}

	return (
		<>
			<Header currentMenuItem={3} entries={boEntries} location={location} match={match} />
			<div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
				<div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
					<h1 className='page-title'>Challenges edit</h1>
				</div>
				<div className="form-wrapper">
					<div style={{display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end'}}>
						<input type='button' className='button button-shadow' value='Francais' style={{backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white'}} onClick={() => {
							setIsLanguageFr(true);
						}}/>
						<input type='button' className='button button-shadow' value='Anglais' style={{backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white'}} onClick={() => {
							setIsLanguageFr(false);
						}}/>
					</div>
					{/* Add substract for moment to have the exact hours because of utc time zone */}
					{( moment(dateFin).subtract(2,'hours').format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm') && dotationsIsVolants(challengesDotations) ) &&
						<div>
							<input type='button' className='btn-success btn-lg' value='Attribution des volants' onClick={() => setDisplayConfirm(true)}/>
						</div>
					}
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
						{isLanguageFr && <input value={title} onChange={e => setTitle(e.target.value)} type='text' className="input-text" placeholder='Titre du challenge(FR) *' style={{width:'45%'}}/>}
						{!isLanguageFr && <input value={titleEn} onChange={e => setTitleEn(e.target.value)} type='text' className="input-text" placeholder='Title for challenge(EN) *' style={{width:'45%'}}/>}
						<input value={code} setCode={e => setCode(e.target.value)} type='text' className="input-text" placeholder='Code du challenge *' style={{width:'45%'}}/>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
						<span style={{marginRight:15, fontSize:15}}>Période du * : </span>
						<DatePicker
							className='button date-picker'
							selected={date}
							onChange={(e) => setDate(e)}
							locale="fr"
							showTimeSelect
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
						<span style={{marginRight:15, marginLeft:15, fontSize:15, width:'fit-content'}}> au * : </span>
						<DatePicker
							className='button date-picker'
							selected={dateFin}
							onChange={(e) => {setDateFin(e); console.log(e); } }
							showTimeSelect
							locale="fr"
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
						<span style={{marginRight:15, fontSize:15}}> Date limite d'inscription * </span>
						<DatePicker
							className='button date-picker'
							selected={dateInscription}
							onChange={(e) => {setDateInscription(e); }}
							showTimeSelect
							locale="fr"
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
						<span style={{fontSize:12, color:'gray'}}>Date de debut d'affichage (ne fonctionne pas dans l'app)</span>
						<DatePicker
							className='button date-picker bg-gray'
							selected={dateShowStart}
							// onChange={(e) => setDateShowStart(e)}
							onChange={() => alert("Ne fonctionne pas dans l'app, le seul contrôle étant actuellement sur la date de fin du challenge.")}
							locale="fr"
							showTimeSelect
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
						<span style={{marginLeft:15, fontSize:12, color:'gray'}}>Date de fin d'affichage (ne fonctionne pas dans l'app)</span>
						<DatePicker
							className='button date-picker bg-gray'
							selected={dateShowEnd}
							// onChange={(e) => setDateShowEnd(e)}
							onChange={() => alert("Ne fonctionne pas dans l'app, le seul contrôle étant actuellement sur la date de fin du challenge.")}
							locale="fr"
							showTimeSelect
							dateFormat='dd/MM/yyyy h:mm aa'
						/>
					</div>
					<div>
						{isLanguageFr && (
							<>
								<label style={{marginBottom:10}}>Description(FR) *</label>
								<CKEditor
									editor={ ClassicEditor }
									data={description}
									config={{ removePlugins: [ 'ImageUpload', 'MediaEmbed', 'Table' ],}}
									onInit={ editor => {
										// You can store the "editor" and use when it is needed.
										console.log( 'Editor is ready to use!', editor );
									} }
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										setDescription(data);
										console.log( { event, editor, data } );
									} }
									onBlur={ ( event, editor ) => {
										console.log( 'Blur.', editor );
									} }
									onFocus={ ( event, editor ) => {
										console.log( 'Focus.', editor );
									} }
								/>
							</>
						)}
						{!isLanguageFr && (
							<>
								<label style={{marginBottom:10}}>Description(EN) *</label>
								<CKEditor
									editor={ ClassicEditor }
									data={descriptionEn}
									config={{ removePlugins: [ 'ImageUpload', 'MediaEmbed', 'Table' ],}}
									onInit={ editor => {
										// You can store the "editor" and use when it is needed.
										console.log( 'Editor is ready to use!', editor );
									} }
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										setDescriptionEn(data);
										console.log( { event, editor, data } );
									} }
									onBlur={ ( event, editor ) => {
										console.log( 'Blur.', editor );
									} }
									onFocus={ ( event, editor ) => {
										console.log( 'Focus.', editor );
									} }
								/>
							</>
						)}
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:"space-between", alignItems:'center'}}>
						<label className='half-row'>Places maximum
							<input onChange={(e) => {setPlaceMax(e.target.value)}} min="50" value={placeMax} type='number' step='50' className="input-text" placeholder='Places max'/>
							{ remainingSlot != null &&
								<p className="mt-4">Places restantes : {remainingSlot}</p>
							}


						</label>
						<label className='half-row'>Codes de Parcours
							<button className='button' style={{marginLeft:20, marginBottom:5}} onClick={() => {
								Axios({
									method:'GET',
									url:`${process.env.REACT_APP_API_URL}/api/courses?pagination=false`,
								}).then(response=> {
									setParcours(response.data['hydra:member'].map(element => {
										return(element['@id'].split('/')[3])
									}))
								})
							}}>Tous</button>
							<textarea className="input-text" style={{flex:1}} value={parcours !== null && parcours !== undefined ? parcours.join(',') : ''} onChange={e => setParcours(e.target.value.replace(' ', '').split(','))}/>
						</label>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'column', justifyContent:"space-between"}}>
						{isLanguageFr && (
							<>
								<p>Visuel Challenge(Fr) * : </p>
								<ImageSelector imageUrl={imageUrl} setImageUrl={setImageUrl} deactivateWaitingRequest={setPickImage} />
							</>
						)}
						{!isLanguageFr && (
							<>
								<p>Visuel Challenge(En) * : </p>
								<ImageSelector imageUrl={visualUrlEn} setImageUrl={setVisualUrlEn} deactivateWaitingRequest={setPickImage} />
							</>
						)}
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:"space-between", alignItems:"center"}}>
						<input value={partenaires[0].name}  onChange={e => updatePartner(e.target.value, 0, 'name')} type='text' className="input-text" placeholder='Partenaire' style={{width:'30%'}}/>
						<input value={partenaires[0].url}  onChange={e => updatePartner(e.target.value, 0, 'url')} type='text' className="input-text" placeholder='Lien web' style={{width:'30%'}}/>
						<div className='partner-image-container'>
							<p>Visuel partenaire : </p>
							<ImageSelector imageUrl={partenaires[0].image} setImageUrl={ (e) => {updatePartner(e, 0, 'image');setIsDirty(true)}} deactivateWaitingRequest={setPickImage} />
						</div>
						<div className=''>
							<FontAwesomeIcon key="partenaires[0]" size='lg' icon={faTrash} style={{cursor: 'pointer'}} onClick={() => {
								// setPartenaires() partenaires[0] = {name: null, url: null, image: null}
								updatePartner('',0,'name');
								updatePartner('',0,'url');
								updatePartner(null,0,'image');
							}}/>
						</div>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:"space-between", alignItems:"center"}}>
						<input value={partenaires[1].name} onChange={e => updatePartner(e.target.value, 1, 'name')} type='text' className="input-text" placeholder='Partenaire' style={{width:'30%'}}/>
						<input value={partenaires[1].url} onChange={e => updatePartner(e.target.value, 1, 'url')} type='text' className="input-text" placeholder='Lien web' style={{width:'30%'}}/>
						<div className='partner-image-container'>
							<p>Visuel partenaire : </p>
							<ImageSelector imageUrl={partenaires[1].image} setImageUrl={ (e) => {updatePartner(e, 1, 'image');setIsDirty(true)}} deactivateWaitingRequest={setPickImage} />
						</div>
						<div className=''>
							<FontAwesomeIcon key="partenaires[1]" size='lg' icon={faTrash} style={{cursor: 'pointer'}} onClick={() => {
								// setPartenaires() partenaires[0] = {name: null, url: null, image: null}
								updatePartner('',1,'name');
								updatePartner('',1,'url');
								updatePartner(null,1,'image');
							}}/>
						</div>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:"space-between", alignItems:"center"}}>
						<input value={partenaires[2].name} onChange={e => updatePartner(e.target.value, 2, 'name')} type='text' className="input-text" placeholder='Partenaire' style={{width:'30%'}}/>
						<input value={partenaires[2].url} onChange={e => updatePartner(e.target.value, 2, 'url')} type='text' className="input-text" placeholder='Lien web' style={{width:'30%'}}/>
						<div className='partner-image-container'>
							<p>Visuel partenaire : </p>
							<ImageSelector imageUrl={partenaires[2].image} setImageUrl={ (e) => {updatePartner(e, 2, 'image');setIsDirty(true)}} deactivateWaitingRequest={setPickImage} />
						</div>
						<div className=''>
							<FontAwesomeIcon key="partenaires[2]" size='lg' icon={faTrash} style={{cursor: 'pointer'}} onClick={() => {

								updatePartner('',2,'name');
								updatePartner('',2,'url');
								updatePartner(null,2,'image');
							}}/>
						</div>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
						<label className='third-row'>Dotation 1er :

							<select type='text' className="input-text" value={dotations[0]?.iri || ''} onChange={(e) => {defineDotations(e.target.value, 1, 1, 0)}}>

								<option value=''>Dotation 1er</option>
								{rewards && rewards['hydra:member'].map(element => {
									return (
										<option value={element['@id']}>{element.title}</option>
									)
								})}
							</select>
						</label>

					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'column'}}>
						{dotations.map((element, index) => {
							if(index > 0)
								return (
									<div style={{display:'flex', flexDirection:'row', justifyContent:"space-between", marginBottom:10}}>
										<FontAwesomeIcon  onClick={() => {
											if(index > 3) {
												const previous = dotations[index-1]
												const current = dotations[index]
												console.log(previous, current)
												reverseDotations(current, previous, index)
												// defineDotations(current.iri, previous.min, previous.max, index-1)
												// defineDotations(previous.iri, current.min, current.max, index)
											}
										}} size='2x' icon={faArrowUp}  className='fa-button plus'/>
										<FontAwesomeIcon  onClick={() => {
											if(index < dotations.length - 1) {
												const next = dotations[index+1]
												const current = dotations[index]
												reverseDotationsDown(current, next, index)
												// defineDotations(current.iri, previous.min, previous.max, index-1)
												// defineDotations(previous.iri, current.min, current.max, index)
											}
										}} size='2x' icon={faArrowDown}  className='fa-button plus'/>
										<label className='text-center third-row'>{index+1}e Dotation </label>
										<select type='text' className="input-text mr-1" style={{width:'30%'}} value={dotations[index].iri || ''} onChange={(e) => {defineDotations(e.target.value, element.min, element.max, index)}}>
											<option value=''>Dotation</option>
											{rewards && rewards['hydra:member'].map(element => {
												return (
													<option value={element['@id']}>{element.title}</option>
												)
											})}
										</select>

										<input onChange={(e) => {
											console.log(!isNaN(e.target.value))
											if(!isNaN(e.target.value)) {
												const temp = dotations.slice();
												temp.splice(index, 1, {iri: temp[index].iri, min:parseInt(e.target.value), max: temp[index].max < e.target.value ? e.target.value : temp[index].max})
												setDotations(temp)
											}
										}} value={element.min} type='number' className="input-text mr-1" placeholder='Place min' style={{width:'30%'}} />
										<input onChange={(e) => {
											if(!isNaN(e.target.value)) {
												const temp = dotations.slice();
												temp.splice(index, 1, {iri: temp[index].iri, min:temp[index].min, max: parseInt(e.target.value)})
												setDotations(temp)
											}

										}} value={element.max} type='number' className="input-text mr-1" placeholder='Place max' style={{width:'30%'}} />

										<FontAwesomeIcon  onClick={() => {setDotations(dotations.filter((e, i) => i!==index))}} size='2x' icon={faMinusCircle}  className='fa-button plus'/>
									</div>
								)

							return null
						})}
						<FontAwesomeIcon  onClick={() => {setDotations([...dotations, {iri: null, min: null, max: null}])}} size='2x' icon={faPlusCircle}  className='fa-button plus'/>
					</div>
					<div className='fieldset-wrapper' style={{marginTop: 10, display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
						<label className='quarter-row setting-number'>Challenge normal *
							<input onChange={e => {if(!isNaN(e.target.value)) {setTarifsParcours(e.target.value)}}} value={tarifsParcours} type='number' step='100' className="input-text" placeholder='Tarif Parcours'/>
						</label>
						<label className='quarter-row setting-number'>Coût Try again *
							<input onChange={e => {if(!isNaN(e.target.value)) {setTarifsAgain(e.target.value)}}} value={tarifsAgain} type='number' step='100' className="input-text" placeholder='Tarif Try Again'/>
						</label>
						<label className='quarter-row setting-number'>Coût duel *
							<input onChange={e => {if(!isNaN(e.target.value)) {setTarifsDuel(e.target.value)}}} value={tarifsDuel} type='number' step='100' className="input-text" placeholder='Tarif Duel'/>
						</label>
						<label className='quarter-row setting-number'>Coût Gran Trofeo *
							<input onChange={e => {if(!isNaN(e.target.value)) {setTarifsGT(e.target.value)}}} value={tarifsGT} type='number' step='100' className="input-text" placeholder='Tarif GranTrofeo'/>
						</label>
						<label className='quarter-row setting-number'>Coût Tourisme *
							<input onChange={e => {if(!isNaN(e.target.value)) {setTourismCost(e.target.value)}}} value={tourismCost} type='number' step='100' className="input-text" placeholder='Tarif GranTrofeo'/>
						</label>
					</div>
					<div style={{display:'inline'}}>
						<label><input className="mr-2" onChange={e => {setIsShow(!(e.target.checked)); console.log(isShow);}}  type='checkbox' checked={!isShow} />
							Ne pas afficher le challenge dans l'appli</label>
					</div>
					<div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row'}}>
						<button className="button button-shadow" style={{minWidth:'fit-content', height:'fit-content', marginLeft:0}}>Remettre le classement à 0</button>
						{title && date && dateFin && dateInscription && description && imageUrl && tarifsAgain && tarifsDuel && tarifsGT && tarifsParcours ?
							<button className='button button-shadow ml-3' style={{marginLeft:20}}
								onClick={() => {
									patch(
										match.params.id,
										parcours.filter(element => element!=null && element!=='' && element!==undefined).map(element => `/api/courses/${element}`),
										dotations.filter(element => element.iri!=null && element.iri!=='' && element.iri!==undefined),
										isDirty ? partenaires.filter(item => item.name !== null && item.name !== "") : undefined,
										isShow,
										code,
										[
											{title : title, description: description, locale: 'fr',image: imageUrl },
											{title : titleEn, description: descriptionEn, locale: 'en',image: visualUrlEn }
										],
										date,
										dateFin,
										dateInscription,
										dateShowStart,
										dateShowEnd,
										parseInt(placeMax),
										parseInt(tarifsParcours),
										parseInt(tarifsAgain),
										parseInt(tarifsGT),
										parseInt(tarifsDuel),
										parseInt(tourismCost),
									)
								}}>Valider</button>
							:
							<button className='button button-shadow' style={{height:'fit-content'}}>Certaines conditions ne sont pas remplies pour pouvoir enregistrer ce challenge</button>
						}
					</div>
				</div>
				{displayConfirm && <div style={{width:'100vw', height:'100vh', backgroundColor:'white', zIndex:999, display:"flex", justifyContent:'center', alignItems:'center', position:"absolute",top:0,left:0}}>
					<div style={{backgroundColor:'#BBBBBB', height:100, width:300, borderRadius:10, padding:20, display:"flex", flexDirection:'column', justifyContent:'space-around'}}>
						<div>Veuillez confirmer l'attribution des volants </div>
						<div className='mt-3'>
							<buton style={{marginRight:10}} className={'button button-shadow'} onClick={() => {creditWheels(id)}}>Confirmer</buton>
							<buton onClick={() => {setDisplayConfirm(false)}} style={{marginLeft:10}} className={'button button-shadow'}>Annuler</buton>
						</div>
					</div>
				</div>}
			</div>
		</>
	)
};

export default ChallengesEdit;
