export const userRoles = ["ROLE_ADMIN", "ROLE_PARCOURS", "ROLE_COMMUNICATION"];


export function getUserRoles(connected) {
  if(connected !== undefined && connected != null ) {
    if (connected.hasOwnProperty("user") && connected.user.roles !== undefined) {
      return connected.user.roles[0]
    } else if (connected.hasOwnProperty("roles") && connected.roles !== undefined) {
      return connected.roles[0]
    }
  }
  return null
}
