export const boEntries = [
    {
        title:'dashboard',
        link:'/tableau-de-bord',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'Utilisateurs',
        link:'/utilisateurs/1',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'Parcours',
        link:'/parcours/1',
        class:'',
        roles: ["ROLE_ADMIN","ROLE_PARCOURS"]
    },
    {
        title:'challenges',
        link:'/challenges/1',
        class:'',
        roles: ["ROLE_ADMIN","ROLE_COMMUNICATION"]
    },
    {
        title:'numeros',
        link:'/numeros',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'dotations',
        link:'/dotations/1',
        class:'',
        roles: ["ROLE_ADMIN","ROLE_COMMUNICATION"]
    },
    {
        title:'classements',
        link:'/classements/1/1',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'produits',
        link:'/produits/1',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'Commandes',
        link:'/commandes/1',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'codes',
        link:'/codes/1',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'configuration',
        link:'/configuration',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'Traduction',
        link:'/traduction',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'media',
        link:'/media',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'tooltips',
        link:'/tooltips/1',
        class:'',
        roles: ["ROLE_ADMIN", "ROLE_COMMUNICATION"]
    },
    {
        title:'faq',
        link:'/faq/1',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'News',
        link:'/news/1',
        class:'',
        roles: ["ROLE_ADMIN","ROLE_COMMUNICATION"]
    },
    {
        title: 'participations',
        link: '/participations/1',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'Pages editoriale',
        link:'/pages/1',
        class:'',
        roles: ["ROLE_ADMIN"]
    },
    {
        title:'slides',
        link:'/slides/1',
        class:'',
        roles: ["ROLE_ADMIN", "ROLE_COMMUNICATION"]
    },
    {
        title:'deconnexion',
        link:'/deconnexion',
        class:'',
        roles: ["ROLE_ADMIN","ROLE_PARCOURS","ROLE_COMMUNICATION","ROLE_USER"]
    }
];