// == Import : npm
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import Listing from '../../containers/Listing'
import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Pages = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected }) => {

    useEffect(() => {
        getAll('content_pages')
    }, [match.params.page]) //eslint-disable-line

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={17} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Pages</h1>
        </div>
        {datas && <Listing
            type={'pages'}
            allowDelete={false}
            match={match}
            datas={[datas]}
            titles={['Emplacement', 'Titre', 'Contenu']}
            datasToDisplay={
                datas['hydra:member'].map(element => ( {
                    id:element['@id'].split('/')[3],
                    infos :[
                    element.slug,
                    element.title,
                    element.content && element.content.replace(/(<([^>]+)>)/gi, "").substring(0, 50).concat(' …')
                    ]
                })
            )}
        />}
    </div>
    </>
    )
};

// == Export
export default Pages;
