// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import PagesEdit from '../../screen/PagesEdit';

// Action Creators
import { patch, getOne } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  data: state.onecontent_pages,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  challengesFind: state.challenges,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patch: (title='', content='', titleEn='', contentEn ='') => {
    dispatch(patch('content_pages', ownProps.match.params.id, {
      translations: [
        {
          locale:'fr',
          title,
          content,
        },
        {
          locale:'en',
          title: titleEn,
          content: contentEn,
        },
      ]
    }, ownProps.history));
  },
  getOne: (id) => {
    dispatch(getOne('content_pages', id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PagesEdit);