// == Import : npm
import React, {useState} from 'react';
import { Link } from "react-router-dom";

// == Import : local
import '../../style/style.scss';
import './header.scss';
import {getUserRoles} from "../../utils/userRoles";

// == Composant
const Header = ({entries, etablissement, location, match, currentMenuItem, deco, etablissementUnderLine, messageNotif, clearNotif, connected}) => {

    let rolesUser = getUserRoles(connected);

    if(messageNotif.message !== '') {
        window.scrollTo(0, 0);
    }

    const [displayMenu, setDisplayMenu] = useState(false);

    const displayEntries = () => {
        let allEntries = [];
        for (let index = 0; index < entries.length; index++) {
            // I compare user role and entries[index].roles to check if he can access to the Link
            const hasRole = entries[index].roles.includes(rolesUser);
            let currentMenuItemClass = ''

            if (entries[index].class !== "") {
                currentMenuItemClass += entries[index].class;
            }

            if (currentMenuItem === index) {
                currentMenuItemClass += ' underline'
            }

            if(hasRole !== false) {
                allEntries[index] =
                  <Link
                    key={index}
                    style={{maxWidth:`${80/(entries.length)}vw`}}
                    to={entries[index].link}
                    className={currentMenuItemClass}>
                  {entries[index].title}
                </Link>;
            }
        }
        return (<>{allEntries}</>);

    };

    if(messageNotif.message !== '') {
        setTimeout(() => {
        clearNotif()
        console.log('clear')
        }, 10000);
    }

    let messageNotifClass = messageNotif.good ? 'good-notif' : 'bad-notif'

    return (
        <>
        <div className="header-wrapper" style={{overflow:'hidden', position:'relative'}}>
            <img alt="Logo Gran Trofeo" src="/images/GRANTROFEO_LOGO_WHITE.png" style={{marginBottom:0, marginTop:15, zIndex:500}}/>

        </div>
        <div>
        {messageNotif.message !== "" ? <div className={messageNotifClass} style={{ position:"relative" }}>
            {messageNotif.message}
            <img alt="" src='/images/cross-icon.svg' style={{height:20, width:20, position:"absolute", top:10, right:10}} onClick={() => clearNotif()} />
        </div> : <></>}
        </div>
        {<div className="menu-wrapper-burger" style={{ left:!displayMenu ? '-310px' : 0, height:'100%', display:"flex",flexDirection:'column', justifyContent:'center'}}>
                <div className='burger-icon-wrapper' style={{position: "absolute", top:30, left:displayMenu ? 282 : 350, border:'2px solid #C9C9CB', borderRadius:60, padding:5, backgroundColor:'#DFE1E3'}}>
                    <div style={{ height:20, width:22, position:'relative' }} onClick={() => setDisplayMenu(!displayMenu)}>
                        <div style={{}} className={displayMenu ? 'burger burger-cross' : 'burger'}/>
                        <div style={{}} className={displayMenu ? 'burger burger-cross' : 'burger'}/>
                        <div style={{}} className={displayMenu ? 'burger burger-cross' : 'burger'}/>
                    </div>
                </div>
            <div style={{height:'100vh', overflow:'auto',  paddingTop:20, paddingBottom:20}}>
                <div style={{height:'fit-content'}}>
                    {displayEntries()}
                    {deco ? <Link to='/deconnexion'>
                        <div>
                            <div>deconnexion</div>
                        </div>
                    </Link> : <></>}
                </div>
            </div>
        </div>}
        </>
    );
};

// == Export
export default Header;
