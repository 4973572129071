/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

// == Import : npm
import React from 'react';
import ReactLoading from 'react-loading';
import {useHistory} from 'react-router-dom'

// == Import : local
import '../../style/style.scss';

// == Composant
const Deconnexion = ({ location, match, waitingRequest, setConnected, logout }) => {
  const history = useHistory();

  if(waitingRequest > 0) {
      return (
          <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
              <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
          </div>
      )
  }

  return (
      <>
          <div className='page-wrapper' style={{ minHeight:'calc(100vh - 100px)', display:'flex', justifyContent:'center', alignItems:'center' }}>
                  <div className='grey-background' style={{ width:'fit-content', margin:'auto' }}>
                      <h1 style={{ color:"#A92E0C" }}>CONFIRMEZ VOTRE DECONNEXION</h1>
                      <div style={{ marginRight:'auto', marginLeft:'auto' }}>
                          <button
                            className='button'
                            onClick={() => {
                              setConnected(null)
                              history.push('/')
                              logout()
                            }}
                          >
                            Je confirme et me reconnecte >>
                          </button>
                          {/*<button className='button' onClick={() => {history.goBack()}}>{t('no')}</button>*/}
                      </div>
                  </div>
          </div>
      </>
  )
};

// == Export
export default Deconnexion;
