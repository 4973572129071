// == Import : npm
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import Listing from '../../containers/Listing'
import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Tooltips = ({ location, match, datas, waitingRequest, getAll, deleteEntity, connected }) => {

    useEffect(() => {
        getAll(match.params.page)
    }, [match.params.page]) // eslint-disable-line react-hooks/exhaustive-deps

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={13} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Tooltips</h1>
        </div>
        {datas && <Listing
            type={'tooltips'}
            allowDelete={false}
            match={match}
            datas={[]}
            titles={['titre', 'content', 'screenshot']}
            datasToDisplay={
                datas['hydra:member'].map(element => ( {
                    id:element['@id'].split('/')[3],
                    infos :[
                    element.title,
                    `${element.content && element.content.replaceAll(/(<([^>]+)>)/gi, "").slice(0, 20)}...`,
                    element.hasOwnProperty('screenshotUrl')? <img alt="" className="screenshot-thumbnail" src={process.env.REACT_APP_API_URL + '/' + element.screenshotUrl}/> : '-',
                    ]
                })
            )}
        />}
    </div>
    </>
    )
};

// == Export
export default Tooltips;
