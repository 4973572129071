// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Codes from '../../screen/Codes';

// Action Creators
import { getAll } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.promo_codes,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAll: ( page ) => {
    dispatch(getAll('promo_codes', page, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Codes);