
// == Import : npm
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react'

// == Import : local
import App from './containers/App';

//import store from './store';
import configureStore from './store';

const { store, persistor } = configureStore();

// == Render
const rootComponent = (
  <CookiesProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </CookiesProvider>
);

render(rootComponent, document.getElementById('root'));
