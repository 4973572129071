// == Import : npm
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';
import {getCountryList} from "../../utils/countryList";

  registerLocale('fr', fr)


// == Composant
const UtilisateursAjout = ({ location, match, data, waitingRequest, post, connected, getAll, cities }) => {

    const [email, setEmail] = useState(null)
    const [firstname, setFirstname] = useState(null)
    const [lastname, setLastname] = useState(null)
    const [birthdate, setBirthdate] = useState(new Date('01/01/1990'))
    const [country, setCountry] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [password, setPassword] = useState(null)
    const [roles, setRoles] = useState('ROLE_USER')
    const [civilTitle, setCivilTitle] = useState('')
    const [balance, setBalance] = useState(0)
    const [status, setStatus] = useState('valid')
    const [nationality, setNationality] = useState('')
    const [phone, setPhone] = useState('')
    const [informationAgree, setInformationAgree] = useState(false);
    const [newsletter, setNewsletter] = useState(false);

    const [countryList, setCountryList] = useState([]);

    useEffect(()=>{
        setCountryList(getCountryList());
    },[])


    return (
    <>
    <Header currentMenuItem={1} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Utilisateurs</h1>
        </div>
        <div className="form-wrapper">
            <div className='fieldset-wrapper form-row'>
                <label className='half-row'>Email
                    <input value={email} onChange={(e) => {setEmail(e.target.value)}} type='text' className="input-text" placeholder='Email'/>
                </label>
                <label className='half-row'>Mot de passe
                    <input value={password} onChange={(e) => {setPassword(e.target.value)}} type='text' className="input-text" placeholder='Mot de passe'/>
                </label>
            </div>
            <div className='fieldset-wrapper form-row'>
                <div className='row'>
                    <label className='col'>Civilité
                        <select type='text' className="input-text" value={civilTitle} onChange={e => setCivilTitle(e.target.value)}>
                            <option value='' disabled>Select</option>
                            <option value='m'>M.</option>
                            <option value='mme'>Mme</option>
                            <option value='mle'>Mlle</option>
                        </select>
                    </label>
                    <label className='col'>Nom
                        <input value={lastname} onChange={(e) => {setLastname(e.target.value)}} type='text' className="input-text" placeholder='Nom'/>
                    </label>
                    <label className='col'>Prénom
                        <input value={firstname} onChange={(e) => {setFirstname(e.target.value)}} type='text' className="input-text" placeholder='Prénom'/>
                    </label>
                    <label className='col'>Rôle
                        <select type='text' className="input-text"value={roles} onChange={e => setRoles(e.target.value)}>
                            <option value='ROLE_USER'>Utilisateur</option>
                            <option value='ROLE_ADMIN'>Administrateur</option>
                            <option value='ROLE_PARCOURS'>Parcours</option>
                            <option value='ROLE_COMMUNICATION'>Communication</option>
                        </select>
                    </label>
                </div>
            </div>
            <div className='fieldset-wrapper row'>
                <div>
                    <label>Date de naissance</label>
                    <DatePicker
                        className='button date-picker'
                        selected={birthdate}
                        onChange={(e) => setBirthdate(e)}
                        locale="fr"
                        dateFormat='dd/MM/yyyy'
                    />
                </div>
                <label className='col'>Nationalité
                    <select type='text' className="input-text" value={nationality} onChange={e => setNationality(e.target.value)}>
                        <option value=''>Select</option>
                        {countryList.map((item, idx)=> <option key={idx} value={item.key}>{item.label}</option>)}
                        {/* <option value='m'>M.</option>
                        <option value='mme'>Mme</option>
                        <option value='mle'>Mlle</option> */}
                    </select>
                </label>
            </div>
            <div className='fieldset-wrapper form-row'>
                <label style={{width:'33.33%',marginRight:15}}>Pays
                    {/* <input value={country} onChange={(e) => {setCountry(e.target.value)}} type='text' className="input-text" placeholder="Pays"></input> */}
                    <select type='text' className="input-text mt-2" value={country} onChange={e => setCountry(e.target.value)}>
                        <option value=''>Select</option>
                        {countryList.map((item, idx)=> <option key={idx} value={item.key}>{item.label}</option>)}
                    </select>
                </label>
                <label style={{width:'33.33%',marginRight:15}}>Ville
                    <input value={city} onChange={(e) => {setCity(e.target.value)}} type='text' className="input-text mt-2" placeholder="Ville"/>
                </label>
                <label className="mt-4" style={{width:'33.33%',marginRight:15}}>Code postal
                    <input value={zipCode} onChange={(e) => {setZipCode(e.target.value)}} type='text' className="input-text mt-2" placeholder="Code postal"/>
                </label>
            </div>
            <div className='fieldset-wrapper row'>
                <label className='col'>Addresse
                    <input value={address} onChange={(e) => {setAddress(e.target.value)}} type='text' className="input-text" placeholder="Addresse"/>
                </label>
                <label className='col'>Complément d'adresse
                    <input value={address2} onChange={(e) => {setAddress2(e.target.value)}} type='text' className="input-text" placeholder="Complément d'adresse"/>
                </label>
                <label className='col'>
                    Tel mobile
                    <InputMask value={phone} onChange={(e) => {setPhone(e.target.value)}} className="input-text" mask="(+99) 99 99 99 99" maskChar="_" placeholder="Tel mobile"/>
                </label>
            </div>
            <div className='fieldset-wrapper form-row'>
                <label className='half-row'>Volants
                    <input value={balance} onChange={(e) => {setBalance(e.target.value)}} type='number' step='1000' className="input-text" placeholder="Volants"/>
                </label>
                <label className='half-row'>Statut du compte
                <select type='text' className="input-text"value={status} onChange={e => setStatus(e.target.value)}>
                    <option value='valid'>Valide</option>
                    <option value='disabled'>Désactivé</option>
                    <option value='deleted'>Supprimé / Banni</option>
                    <option value='activationPending'>En attente d'activation</option>
                </select>
                </label>
            </div>
            <div className="fieldset-wrapper form-row">
                <div className="form-check">
                    <input className="mt-4 form-check-input" type="checkbox" value="" id="flexCheckChecked" onChange={(e) => {setInformationAgree(e.currentTarget.checked)}}/>
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        J'accepte que mes coordonnées soient transmises
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input mt-4" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => {setNewsletter(e.currentTarget.checked)}}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Toystories
                    </label>
                </div>
            </div>
            <div className='fieldset-wrapper form-row'>
                <button className='button button-shadow mt-2' onClick={() => {
                    post(email, firstname, lastname, birthdate, country, city, zipCode, address, address2, password, roles, balance, status, civilTitle, nationality, phone, informationAgree, newsletter)
                    }
                }>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default UtilisateursAjout;
