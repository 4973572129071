import React from 'react';
import './style.scss';
import fr from '../../language/fr.json';
import TranslateComp from './translateComp';

const MobileTranslation = ({ upload, getFeatLang, datas }) => {
  const lastCode = 'lang_en';
  const _auth = fr.auth;
  const _boutique = fr.boutique;
  const _challenge = fr.challenge;
  const _dotation = fr.dotation;
  const _faq = fr.faq;
  const _navigation = fr.navigation;
  const _news = fr.news;
  const _parcours = fr.parcours;
  const _participations = fr.participations;
  const _profil = fr.profil;
  const _optionsMenu = fr.optionsMenu;
  const _config = fr.config;

  const [auth, setAuth] = React.useState({});
  const [boutique, setBoutique] = React.useState({});
  const [challenge, setChallenge] = React.useState({});
  const [dotation, setDotation] = React.useState({});
  const [faq, setFaq] = React.useState({});
  const [navigation, setNavigation] = React.useState({});
  const [news, setNews] = React.useState({});
  const [parcours, setParcours] = React.useState({});
  const [participations, setParticipations] = React.useState({});
  const [profil, setProfil] = React.useState({});
  const [optionsMenu, setOptionMenu] = React.useState({});
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    setAuth(datas ? datas.auth : cloneObject( fr.auth));
    setBoutique(datas ? datas.boutique : cloneObject(fr.boutique));
    setChallenge(datas ? datas.challenge : cloneObject(fr.challenge));
    setDotation(datas ? datas.dotation : cloneObject(fr.dotation));
    setFaq(datas ? datas.faq : cloneObject(fr.faq));
    setNavigation(datas ? datas.navigation : cloneObject(fr.navigation));
    setNews(datas ? datas.news : cloneObject(fr.news));
    setParcours(datas ? datas.parcours : cloneObject(fr.parcours));
    setParticipations(datas ? datas.participations : cloneObject(fr.participations));
    setProfil(datas ? datas.profil : cloneObject(fr.profil));
    setOptionMenu(datas?.optionsMenu ? datas.optionsMenu : cloneObject(fr.optionsMenu))
    setConfig(datas?.config ? datas.config : cloneObject(fr.config))
  }, [datas]);

  React.useEffect(() => {
    getFeatLang(lastCode);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (evt, type, key) => {
    const value = evt.target.value;
    switch (type) {
      case 'auth':
        return setAuth({...auth, [key]: value});
      case 'boutique':
        return setBoutique({...boutique, [key]: value});
      case 'challenge':
        return setChallenge({...challenge, [key]: value});
      case 'dotation':
        return setDotation({...dotation, [key]: value});
      case 'faq':
        return setFaq({...faq, [key]: value});
      case 'navigation':
        return setNavigation({...navigation, [key]: value});
      case 'news':
        return setNews({...news, [key]: value});
      case 'parcours':
        return setParcours({...parcours, [key]: value});
      case 'participations':
        return setParticipations({...participations, [key]: value});
      case 'profil':
        return setProfil({...profil, [key]: value});
      case 'optionsMenu':
        return setOptionMenu({...optionsMenu, [key]: value});
      case 'config':
        return setConfig({...config, [key]: value});
      default:
        return;
    }
  }

  const cloneObject = (obj) => {
    let clone = {};
    for(var i in obj) {
      clone[i] = "";
    }
    return clone;
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const directions = { auth, boutique, challenge, dotation, faq, navigation, news, parcours, participations, profil, optionsMenu,config };
    const formData = new FormData();
    formData.append("file", new Blob([JSON.stringify(directions)], {type: 'text/json'}), `${lastCode}.json`);
    upload(formData);
  };

  return (
    <article>
      <section className='table_container'>
        <TranslateComp
          data={_auth}
          name='auth'
          stateValue={auth}
          handleChange={handleChange}
        />
        <TranslateComp
          data={_boutique}
          name='boutique'
          stateValue={boutique}
          handleChange={handleChange}
        />
        <TranslateComp
          data={_challenge}
          name='challenge'
          stateValue={challenge}
          handleChange={handleChange}
        />
        <TranslateComp
          data={_dotation}
          name='dotation'
          stateValue={dotation}
          handleChange={handleChange}
        />
        <TranslateComp
          data={_faq}
          name='faq'
          stateValue={faq}
          handleChange={handleChange}
        />
        <TranslateComp
          data={_navigation}
          name='navigation'
          stateValue={navigation}
          handleChange={handleChange}
        />
        <TranslateComp
          data={_news}
          name='news'
          stateValue={news}
          handleChange={handleChange}
        />
        <TranslateComp
          data={_parcours}
          name='parcours'
          stateValue={parcours}
          handleChange={handleChange}
        />
        <TranslateComp
          data={_participations}
          name='participations'
          stateValue={participations}
          handleChange={handleChange}
        />
        <TranslateComp
          data={_profil}
          name='profil'
          stateValue={profil}
          handleChange={handleChange}
        />
         <TranslateComp
          data={_optionsMenu}
          name='optionsMenu'
          stateValue={optionsMenu}
          handleChange={handleChange}
        />
         <TranslateComp
          data={_config}
          name='config'
          stateValue={config}
          handleChange={handleChange}
        />
      </section>
      <div className='fieldset-wrapper text-center'>
        <button style={ {backgroundColor: '#AB3217', color: 'white', height: 'fit-content'} } className='mt-3 button button-shadow mt-2' onClick={handleSubmit}>Enregistrer</button>
      </div>
    </article>
  )
}

export default MobileTranslation
