// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';


// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import { boEntries } from '../../utils/headerEntries.js';

registerLocale('fr', fr)


// == Composant
const PagesEdit = ({ location, match, data, getOne, waitingRequest, patch, connected }) => {

    const [slug, setSlug] = useState(null)
    const [titre, setTitre] = useState(null)
    const [content, setContent] = useState(null)

    // state translate languege
    const [isLanguageFr, setIsLanguageFr] = useState(true);
    const [titleEn, setTitleEn] = useState('');
    const [contentEn, setContentEn] = useState('');

    useEffect(() => {
        getOne(`${match.params.id}?groups[]=translations`);
    }, []) // eslint-disable-line

    useEffect(() => {
        if(data) {
            setTitre(data.title)
            setSlug(data.slug)
            setContent(data.content)
            if(data.translations.en) {
                setTitleEn(data.translations.en.title)
                setContentEn(data.translations.en.content)
            }
        }
    }, [data])

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={17} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Pages</h1>
        </div>
        <div className="form-wrapper">
            <div style={{display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end'}}>
               <input type='button' className='button button-shadow' value='Francais' style={{backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white'}} onClick={() => {
                    setIsLanguageFr(true);

               }}/>
               <input type='button' className='button button-shadow' value='Anglais' style={{backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white'}} onClick={() => {
                   setIsLanguageFr(false);
               }}/>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>

                <label className='half-row'>Emplacement ( slug ) :
                    <input value={slug} type='text' className="input-text" placeholder='Titre *' disabled />
                </label>
                {isLanguageFr && (
                    <label className='half-row'>Titre(Fr) :
                        <input value={titre} onChange={(e) => {setTitre(e.target.value)}} type='text' className="input-text" placeholder='Titre(Fr) *' />
                    </label>
                )}
                {!isLanguageFr && (
                    <label className='half-row'>Titre(En) :
                        <input value={titleEn} onChange={(e) => {setTitleEn(e.target.value)}} type='text' className="input-text" placeholder='Titre(En) *' />
                    </label>
                )}
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px'}}>
                {isLanguageFr && (
                    <>
                        <span style={{marginBottom: '5px'}}>Contenu(Fr) :</span>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={content}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setContent(data);
                                console.log( { event, editor } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </>
                )}
                {!isLanguageFr && (
                    <>
                        <span style={{marginBottom: '5px'}}>Contenu(En) :</span>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={contentEn}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setContentEn(data);
                                console.log( { event, editor } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </>
                )}
            </div>
            <button className='button button-shadow' onClick={() => {patch(titre, content, titleEn, contentEn)}}>Valider</button>
        </div>
    </div>
    </>
    )
};

// == Export
export default PagesEdit;
