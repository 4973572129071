// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import { boEntries } from '../../utils/headerEntries.js';

registerLocale('fr', fr)

// == Composant
const ProduitsEdit = ({ location, match, data, settings, waitingRequest, patch, getOne, connected }) => {

    const [title, setTitle] = useState(null)
    const [cost, setCost] = useState(null)
    const [wheels, setWheels] = useState(null)
    // const [description, setDescription] = useState(null)
    const [volantEuro, setVolantEuro] = useState(null)


    useEffect(() => {
        getOne(match.params.id)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(data) {
            setTitle(data.title)
            setCost(data.cost/100)
            setWheels(data.wheels)
            // setDescription(data.description)
        }
    }, [data])

    useEffect(() => {
        if(settings) {
            setVolantEuro(settings.wheelsPerEuro)
        }
    }, [settings])

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }
    return (
    <>
    <Header currentMenuItem={7} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Produits</h1>
        </div>
        <div className="form-wrapper">
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <input value={title} onChange={(e) => {setTitle(e.target.value)}} type='text' className="input-text" placeholder='Titre *' style={{width:'100%'}}/>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <input value={cost} onChange={(e) => {setCost(e.target.value); setWheels(Math.floor(e.target.value * volantEuro));  setTitle(`Pack de ${e.target.value * volantEuro} Volants`)}} type='number' min='0' className="input-text" placeholder='Coût *' style={{width:'35%'}}/>
                <span className='devise'>€</span>
                <input value={wheels} onChange={(e) => {setWheels(e.target.value); setTitle(`Pack de ${e.target.value} Volants`)}} type='number' step='100' min='0' className="input-text" placeholder='Volants *' style={{width:'45%'}}/>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', margin: '0 15%'}}>
                <button className='button button-shadow' onClick={() => {patch(title, 'null_description', cost*100, wheels)}}>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default ProduitsEdit;
