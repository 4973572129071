// == Import : npm
import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// == Import : local
import '../../style/style.scss';
import Header from '../../containers/Header';
import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const FaqAjout = ({ location, match, datas, waitingRequest, connected, post }) => {

    const [titre, setTitre] = useState(null)
    const [desc, setDesc] = useState(null)

    // state translate languege
    const [isLanguageFr, setIsLanguageFr] = useState(true);
    const [titleEn, setTitleEn] = useState('');
    const [contentEn, setContentEn] = useState('');

    if(waitingRequest > 0 ) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={14} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>FAQ</h1>
        </div>
        <div className="form-wrapper">
            <div style={{display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end'}}>
               <input type='button' className='button button-shadow' value='Francais' style={{backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white'}} onClick={() => setIsLanguageFr(true)}/>
               <input type='button' className='button button-shadow' value='Anglais' style={{backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white'}} onClick={() => setIsLanguageFr(false)}/>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                {isLanguageFr && <input value={titre} onChange={(e) => {setTitre(e.target.value)}} type='text' className="input-text" placeholder='Question(Fr) *' style={{width:'80%'}}/>}
                {!isLanguageFr && <input value={titleEn} onChange={(e) => {setTitleEn(e.target.value)}} type='text' className="input-text" placeholder='Question(En) *' style={{width:'80%'}}/>}
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px'}}>
                {isLanguageFr && (
                    <>
                        <p style={{marginBottom:10}}>Réponse(Fr)</p>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={desc}
                            config={{ removePlugins: [ 'ImageUpload', 'MediaEmbed', 'Table' ],}}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setDesc(data);
                                console.log( { event, editor } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </>
                )}
                {!isLanguageFr && (
                    <>
                        <p style={{marginBottom:10}}>Réponse(En)</p>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={contentEn}
                            config={{ removePlugins: [ 'ImageUpload', 'MediaEmbed', 'Table' ],}}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setContentEn(data);
                                console.log( { event, editor } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </>
                )}

            </div>
            <div className='fieldset-wrapper'>
                <button className='button button-shadow' onClick={() => {post(titre, desc, titleEn, contentEn)}}>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default FaqAjout;
