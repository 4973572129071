// == Import : npm
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "react-datepicker/dist/react-datepicker.css";

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';
import ImageSelector from '../../containers/ImageSelector';

// == Composant
const SlidesEdit = ({ location, match, data, waitingRequest, connected, patch, upload, lastUploadedImage, getOne }) => {

    const [imageUrl, setImageUrl] = useState([null]);

    const [titre, setTitre] = useState(null)
    const [content, setContent] = useState(null)
    const [pickImage, setPickImage] = useState(false)

    // state translate languege
    const [isLanguageFr, setIsLanguageFr] = useState(true);
    const [titleEn, setTitleEn] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');

    useEffect(() => {
        getOne(`${match.params.id}?groups[]=translations`)
    }, [match.params.id, getOne])

    useEffect(() => {
        if(data) {
            setTitre(data.title)
            setImageUrl(data.image)
            setContent(data.content)

            if(data.translations.en) {
                setTitleEn(data.translations.en.title)
                setDescriptionEn(data.translations.en.content)
            }
        }
    }, [data, isLanguageFr])

    if(waitingRequest > 0 && !pickImage /*|| connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')*/) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#A92E0C'} height={'10%'} width={'10%'} />
            </div>
        )
    }

  console.log('url', lastUploadedImage)
    return (
    <>
    <Header currentMenuItem={18} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Slides</h1>
        </div>
        <div className="form-wrapper">
            <div style={{display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'flex-end'}}>
               <input type='button' className='button button-shadow' value='Francais' style={{backgroundColor: isLanguageFr && '#A92E0C', color: isLanguageFr && 'white'}} onClick={() => {
                   setIsLanguageFr(true);
               }}/>
               <input type='button' className='button button-shadow' value='Anglais' style={{backgroundColor: !isLanguageFr && '#A92E0C', color: !isLanguageFr && 'white'}} onClick={() => {
                   setIsLanguageFr(false);
               }}/>
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                {isLanguageFr && <input value={titre} onChange={(e) => {setTitre(e.target.value)}} type='text' className="input-text" placeholder='Titre(En) *' style={{width:'45%'}}/>}
                {!isLanguageFr && <input value={titleEn} onChange={(e) => {setTitleEn(e.target.value)}} type='text' className="input-text" placeholder='Title(En) *' style={{width:'45%'}}/>}
            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'column', justifyContent:"space-between"}}>
              <p>Visuel de la slide : </p>
              <ImageSelector imageUrl={imageUrl} setImageUrl={setImageUrl} deactivateWaitingRequest={setPickImage} />
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px'}}>
                {isLanguageFr && (
                    <>
                        <span style={{marginBottom: '5px'}}>Content(Fr)</span>
                        <CKEditor
                        editor={ ClassicEditor }
                        data={content}
                        onInit={ editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setContent(data);
                            console.log( { event, editor } );
                        } }
                        onBlur={ ( event, editor ) => {
                            console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            console.log( 'Focus.', editor );
                        } }
                        />
                    </>
                )}
                {!isLanguageFr && (
                    <>
                        <span style={{marginBottom: '5px'}}>Content(Fr)</span>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={descriptionEn}
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setDescriptionEn(data);
                                console.log( { event, editor } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                    </>
                )}

            </div>
            <div className='fieldset-wrapper' style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', margin: '0 50px'}}>
                <button className='button button-shadow' onClick={() => {patch(titre, imageUrl, content, titleEn, descriptionEn)}}>Valider</button>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default SlidesEdit;
